import React, { Ref, useCallback, useMemo, useState } from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { useTheme } from '@mui/styles';
import { ChartReportProps } from 'c-reports/Types';
import { generateBrush } from 'c-reports/Generators';

import { Box } from '@mui/material';
import PannableSlider from 'c-components/PannableSlider';

type Props = Omit<ChartReportProps<any>, 'onDataUpdated'>;

const chooseColour = (defaultColor: string, override?: string) => {
    if (typeof override === 'string' && override.trim().length > 0) {
        return override;
    }
    return defaultColor;
};

const ReportBarChart = (
    {
        data,
        chartProps,
        brushProps,
        parts,
        xAxisDataKey,
        yAxisDataKey,
        renderTooltipLabel,
        yAxisTickFormatter,
        hideLegend,
        hideTooltip,
        hideBrush,
        xAxisProps,
        yAxisProps,
        chartChildren,
    }: Props,
    ref: Ref<any>,
) => {
    const CustomTooltip = useCallback(
        (tooltipProps: TooltipProps<any, any>) => {
            if (
                renderTooltipLabel &&
                tooltipProps.active &&
                tooltipProps.payload &&
                tooltipProps.payload.length
            ) {
                return renderTooltipLabel(tooltipProps);
            }

            return null;
        },
        [renderTooltipLabel],
    );

    const theme = useTheme();
    const allBars = useMemo(
        () =>
            parts.map(p => (
                <Bar key={p.dataKey} dataKey={p.dataKey} {...(p.barProps as any)}>
                    {data.map(entry => (
                        <Cell
                            key={entry[p.dataKey]}
                            fill={chooseColour(
                                p.barProps?.fill,
                                (p.barProps?.customColors ?? {})?.[entry[xAxisDataKey]] ?? null,
                            )}
                        />
                    ))}
                </Bar>
            )),
        [parts, data, xAxisDataKey],
    );

    const maxVisibleLabels = 15;
    const maxCharsPerLabel = 10;

    const adjustedEndIndex = useMemo(() => {
        if (!data || data.length === 0) return 0;

        const avgLabelLength =
            data.reduce((sum, item) => sum + String(item[xAxisDataKey] ?? '').length, 0) /
            data.length;

        if (avgLabelLength <= maxCharsPerLabel && data.length <= 24) {
            return data.length - 1;
        }

        return Math.min(data.length - 1, maxVisibleLabels);
    }, [data, xAxisDataKey]);
    const [brushRange, setBrushRange] = useState<[number, number]>([0, adjustedEndIndex]);

    return (
        <>
            <ResponsiveContainer width="100%" height="94%">
                <BarChart
                    ref={ref}
                    data={data}
                    margin={{
                        top: 25,
                        right: 50,
                        left: 40,
                        bottom: 5,
                    }}
                    {...chartProps}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={xAxisDataKey}
                        tick={props => {
                            const { x, y, payload } = props;
                            const words = payload.value.split(' ');
                            const maxCharsPerLine = 10;

                            const lines = [];
                            let currentLine = '';

                            words.forEach(word => {
                                if ((currentLine + word).length > maxCharsPerLine) {
                                    lines.push(currentLine);
                                    currentLine = word;
                                } else {
                                    currentLine += (currentLine ? ' ' : '') + word;
                                }
                            });
                            lines.push(currentLine);
                            const filteredLines = lines.filter(Boolean);

                            return (
                                <text x={x} y={y} textAnchor="middle" fontSize={14} fill="#666">
                                    {filteredLines.map((line, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <tspan key={index} x={x} dy={index === 0 ? 0 : 16}>
                                            {line}
                                        </tspan>
                                    ))}
                                </text>
                            );
                        }}
                        interval={0}
                        tickMargin={10}
                        {...xAxisProps}
                    />

                    <YAxis
                        width={20}
                        dataKey={yAxisDataKey}
                        tickFormatter={yAxisTickFormatter}
                        {...yAxisProps}
                    />
                    {!hideTooltip && (
                        <Tooltip content={renderTooltipLabel ? <CustomTooltip /> : undefined} />
                    )}
                    {!hideLegend && (
                        <Legend verticalAlign="top" align="right" wrapperStyle={{ top: 5 }} />
                    )}
                    {allBars}
                    {!hideBrush &&
                        generateBrush({
                            brushProps: {
                                display: 'hidden',
                            },
                            startIndex: Math.floor(brushRange[0]),
                            endIndex: Math.ceil(brushRange[1]),
                            dataKey: xAxisDataKey,
                            theme,
                            height: 0,
                        })}
                    {[...(chartChildren ?? [])]}
                </BarChart>
            </ResponsiveContainer>
            <Box pl={9} width="95%" mt={-1}>
                <PannableSlider
                    value={brushRange}
                    onChange={setBrushRange}
                    min={0}
                    max={data.length - 1}
                    getLabelForValue={i => data[i]?.[xAxisDataKey] ?? i}
                />
            </Box>
        </>
    );
};

export default React.forwardRef(ReportBarChart);
