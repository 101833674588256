import React, { Ref, useCallback, useMemo, useState } from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { ChartReportProps } from 'c-reports/Types';
import { ChartDataKeyDateFormat, generateBrush } from 'c-reports/Generators';
import { useTheme } from '@mui/styles';
import { isValid, parse } from 'date-fns';
import { useDateUtils } from 'c-hooks';
import { Box } from '@mui/material';
import PannableSlider from 'c-components/PannableSlider/PannableSlider';

type Props = Omit<ChartReportProps<any>, 'onDataUpdated'>;

const ReportLineChart = (
    {
        data,
        chartProps,
        parts,
        xAxisDataKey,
        yAxisDataKey,
        renderTooltipLabel,
        yAxisTickFormatter,
        hideLegend,
        hideTooltip,
        lineChartProps,
        xAxisProps,
        yAxisProps,
        brushProps,
        hideBrush,
        chartChildren,
    }: Props,
    ref: Ref<any>,
) => {
    const [hoveredLegendKey, setHoveredLegendKey] = useState(null);
    const onLegendMouseEnter = useCallback<Legend['props']['onMouseEnter']>(
        payload => {
            if (payload.dataKey != null) {
                setHoveredLegendKey(payload.dataKey);
            }
        },
        [setHoveredLegendKey],
    );
    const onLegendMouseLeave = useCallback<Legend['props']['onMouseLeave']>(() => {
        setHoveredLegendKey(null);
    }, [setHoveredLegendKey]);

    const { accumulative } = useMemo<ChartReportProps<any>['lineChartProps']>(
        () => ({ accumulative: false, ...lineChartProps }),
        [lineChartProps],
    );

    const CustomTooltip = useCallback(
        (tooltipProps: TooltipProps<any, any>) => {
            if (
                renderTooltipLabel &&
                tooltipProps.active &&
                tooltipProps.payload &&
                tooltipProps.payload.length
            ) {
                return renderTooltipLabel(tooltipProps);
            }

            return null;
        },
        [renderTooltipLabel],
    );

    const allPartDataKeys = useMemo(() => parts.map(p => p.dataKey), [parts]);
    const { formatDateString } = useDateUtils();

    const chartData = useMemo(() => {
        let theData = data;
        if (isValid(parse(data?.[0]?.[xAxisDataKey], ChartDataKeyDateFormat, new Date()))) {
            theData = data.map(d => ({
                ...d,
                [xAxisDataKey]: formatDateString({
                    date: parse(d[xAxisDataKey], ChartDataKeyDateFormat, new Date()),
                }),
            }));
        }

        if (accumulative === true && Array.isArray(data)) {
            // keep reference to prev values so they can be added to next value
            const prev: Record<string, any> = {};

            return (theData as any[]).reduce((acc, currentLineData, idx) => {
                const newLineData = { ...currentLineData };
                allPartDataKeys.forEach(part => {
                    // add current value + prev value
                    const newVal = currentLineData[part] + (prev[part] ?? 0);
                    newLineData[part] = newVal;
                    // set prev value for next iteration
                    prev[part] = newVal;
                });

                acc[idx] = newLineData;
                return acc;
            }, []);
        }

        return theData;
    }, [data, xAxisDataKey, accumulative, formatDateString, allPartDataKeys]);

    const theme = useTheme();
    const [brushRange, setBrushRange] = useState<[number, number]>([0, data.length - 1]);
    return (
        <>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    ref={ref}
                    data={chartData}
                    margin={{
                        top: 25,
                        right: 50,
                        left: 40,
                        bottom: 5,
                    }}
                    {...chartProps}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={xAxisDataKey}
                        {...xAxisProps}
                        tickMargin={10}
                        tick={props => {
                            const { x, y, payload } = props;
                            const text = payload.value.trim();
                            const maxCharsPerLine = 10;
                            let lines = [];

                            const dateRegex = /^\d{2}-[A-Za-z]{3}-\d{4}$/;

                            if (dateRegex.test(text)) {
                                const [day, month, year] = text.split('-');
                                lines = [`${day}-${month}`, year];
                            } else {
                                const words = text.split(' ');
                                let currentLine = '';

                                words.forEach(word => {
                                    if ((currentLine + word).length > maxCharsPerLine) {
                                        lines.push(currentLine);
                                        currentLine = word;
                                    } else {
                                        currentLine += (currentLine ? ' ' : '') + word;
                                    }
                                });
                                lines.push(currentLine);
                            }

                            return (
                                <text x={x} y={y} textAnchor="middle" fontSize={14} fill="#666">
                                    {lines.map((line, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <tspan key={index} x={x} dy={index === 0 ? 0 : 16}>
                                            {line}
                                        </tspan>
                                    ))}
                                </text>
                            );
                        }}
                    />
                    <YAxis
                        width={20}
                        dataKey={yAxisDataKey}
                        tickFormatter={yAxisTickFormatter}
                        {...yAxisProps}
                    />
                    {!hideTooltip && (
                        <Tooltip content={renderTooltipLabel ? <CustomTooltip /> : undefined} />
                    )}
                    {!hideLegend && (
                        <Legend
                            verticalAlign="top"
                            align="right"
                            wrapperStyle={{ top: 5 }}
                            onMouseEnter={onLegendMouseEnter}
                            onMouseLeave={onLegendMouseLeave}
                        />
                    )}
                    {parts.map(p => (
                        <Line
                            key={p.dataKey}
                            type="monotone"
                            dataKey={p.dataKey}
                            strokeWidth={hoveredLegendKey === p.dataKey ? 8 : 4}
                            activeDot={{ r: hoveredLegendKey === p.dataKey ? 16 : 8 }}
                            {...(p.lineProps as any)}
                        />
                    ))}
                    {!hideBrush &&
                        generateBrush({
                            brushProps,
                            startIndex: Math.floor(brushRange[0]),
                            endIndex: Math.ceil(brushRange[1]),
                            dataKey: xAxisDataKey,
                            theme,
                            height: 0,
                        })}
                    {[...(chartChildren ?? [])]}
                </LineChart>
            </ResponsiveContainer>
            <Box pl={9} width="95%" mt={-4}>
                <PannableSlider
                    value={brushRange}
                    onChange={setBrushRange}
                    min={0}
                    max={data.length - 1}
                    getLabelForValue={i => data[i]?.[xAxisDataKey] ?? i}
                />
            </Box>
        </>
    );
};

export default React.forwardRef(ReportLineChart);
