import React, { useCallback, useEffect, useState } from 'react';
import { UseBoolean, usePrevious } from 'react-hanger';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { Box } from '@mui/material';
import { Button, DateRangeField, DialogV2, FlashMessage } from 'c-components';
import { Creative } from 'c-sdk';
import { NetworkRequestState } from 'c-data-layer';

type Props = {
    dialogState: UseBoolean;
    selectedCreatives: number[];

    title: TranslationPath;
    description: TranslationPath;

    updateCreatives: (creatives: Partial<Creative>[]) => void;
    updateCreativesState: NetworkRequestState;
    updateCreativesError?: string;
};

const UpdateDateDialog: React.FC<Props> = ({
    dialogState: { setFalse: CloseDialog, value: DialogOpen },
    selectedCreatives,
    title,
    description,
    updateCreatives,
    updateCreativesState,
    updateCreativesError,
}) => {
    const t = useCommonTranslation();
    const [dateRange, setDateRange] = useState<[string | null, string | null]>([null, null]);

    useEffect(() => {
        setDateRange([null, null]);
    }, [DialogOpen]);

    const onSave = useCallback(() => {
        const [liveDate, endDate] = dateRange;
        updateCreatives(
            selectedCreatives.map(id => ({ id, live_date: liveDate, end_date: endDate })),
        );
    }, [dateRange, selectedCreatives, updateCreatives]);

    const prevLoadingState = usePrevious(updateCreativesState);
    useEffect(() => {
        if (
            updateCreativesState === NetworkRequestState.Success &&
            prevLoadingState === NetworkRequestState.InProgress
        ) {
            CloseDialog();
        }
    }, [CloseDialog, prevLoadingState, updateCreativesState]);

    return (
        <DialogV2
            onClose={CloseDialog}
            open={DialogOpen}
            title={title}
            description={description}
            actions={
                <>
                    <Button
                        disabled={updateCreativesState === NetworkRequestState.InProgress}
                        onClick={onSave}
                    >
                        {t('Modules.Main.CreativeManagement.creatives.controls.dates.submitLabel')}
                    </Button>
                </>
            }
        >
            <Box textAlign="center">
                <Box>
                    <DateRangeField
                        value={dateRange}
                        onChange={setDateRange}
                        label={t(
                            'Modules.Main.CreativeManagement.creatives.controls.dates.dateRangeLabel',
                        )}
                    />
                </Box>
                {updateCreativesState === NetworkRequestState.Error && (
                    <Box mt={2} textAlign="center">
                        <FlashMessage status="error">{String(updateCreativesError)}</FlashMessage>
                    </Box>
                )}
            </Box>
        </DialogV2>
    );
};

export default UpdateDateDialog;
