import { EndpointMap } from './Types';

enum ModelNamespace {
    Auth = 'auth/',
    Portal = 'portal/',
    Displays = 'displays/',
    InternalAdmin = 'internaladmin/',
    PopDisplays = 'popdisplays/',
    Reporting = 'reporting/',
    AI = 'ai/',
}

const createGeneric = (base: string, namespace: ModelNamespace) => ({
    list: `${namespace}${base}/list`,
    cursor: `${namespace}${base}/cursor`,
    create: `${namespace}${base}/create`,
    get: `${namespace}${base}/:id`,
    update: `${namespace}${base}/:id`,
    delete: `${namespace}${base}/:id`,
});

export const DEFAULT_ENDPOINT_MAP: EndpointMap = {
    Entities: {
        Agency: createGeneric('agency', ModelNamespace.Portal),
        Alert: {
            ...createGeneric('alert', ModelNamespace.Portal),
            list: `${ModelNamespace.Portal}alerts`,
        },
        Buyer: {
            ...createGeneric('buyer', ModelNamespace.Portal),
            attachAudienceVersion: `${ModelNamespace.Portal}buyer/:id/attach-audience-source`,
        },
        BuyerCost: createGeneric('buyercost', ModelNamespace.Portal),
        Campaign: {
            ...createGeneric('campaign', ModelNamespace.Portal),
            lineItems: `${ModelNamespace.Portal}campaign/:id/line-items`,
            lineItemSchedulables: `${ModelNamespace.Portal}campaign/:id/line-items/pop`,
            download: `${ModelNamespace.Portal}campaign/:id/download`,
            shareUrl: `${ModelNamespace.Portal}campaign/:id/share`,
            syncLineItems: `${ModelNamespace.Portal}campaign/:id/line-items/sync`,
            overrideBudgetSummary: `${ModelNamespace.Portal}campaign/:id/summary/override`,
            setNotices: `${ModelNamespace.Portal}campaign/:id/notices`,
            resetNotices: `${ModelNamespace.Portal}campaign/:id/reset-data-status`,
            downloadPCA: `${ModelNamespace.Portal}campaign/:id/pca-download`,
            archive: `${ModelNamespace.Portal}campaign/:id/archive`,
            creatives: `${ModelNamespace.Portal}campaign/:id/creatives`,
            autoGenerateCreativeGroups: `${ModelNamespace.Portal}campaign/:id/validate-creative-groups`,
            createAutoGeneratedGroups: `${ModelNamespace.Portal}campaign/:id/assign-creative-groups`,
            forceAssignCreativesToLineItems: `${ModelNamespace.Portal}campaign/:id/force-auto-assign`,
            forceUnAssignCreativesFromLineItems: `${ModelNamespace.Portal}campaign/:id/unassign`,
            autoAssignCreativesToLineItems: `${ModelNamespace.Portal}campaign/:id/auto-assign`,
            massUnAssignCreativesFromLineItems: `${ModelNamespace.Portal}campaign/:id/mass-unassign`,
            pcaExport: `${ModelNamespace.Portal}campaign/:id/pca-raw-export`,
            pcaImport: `${ModelNamespace.Portal}campaign/:id/pca-raw-import`,

            displayIssues: `${ModelNamespace.Portal}campaign/:id/issues`,
            errors: `${ModelNamespace.Portal}campaign/:id/errors`,
            validate: `${ModelNamespace.Portal}campaign/:id/validate`,
            uploadBookingSummary: `${ModelNamespace.Portal}campaign/:id/booking-summary/upload`,
            deleteBookingSummary: `${ModelNamespace.Portal}campaign/:id/booking-summary/:bookingSummary`,
            deleteLegacyBookingSummary: `${ModelNamespace.Portal}campaign/:id/old-booking-summary`,
            syncContacts: `${ModelNamespace.Portal}campaign/:id/additional-contacts/sync`,
            syncMedia: `${ModelNamespace.Portal}campaign/:id/campaign-media/sync`,
            updatePopSettings: `${ModelNamespace.Portal}campaign/:id/pop-settings`,
            popSettingsMediaTable: `${ModelNamespace.Portal}campaign/:id/campaign-media/list`,
            mediaTableParameter: `${ModelNamespace.Portal}campaign/:id/campaign-media/parameter`,
            deleteMediaTableParameter: `${ModelNamespace.Portal}campaign/:campaignId/campaign-media/parameter/:id`,
            removeIdsFromMediaGroup: `${ModelNamespace.Portal}campaign/:id/campaign-media/group/remove`,
            editGroup: `${ModelNamespace.Portal}campaign/:id/campaign-media/group`,
            auditEvent: `${ModelNamespace.Portal}campaign/:id/audit`,
            scheduleLineItems: `${ModelNamespace.Portal}campaign/:id/schedule/line-items`,
            screenFromSchedule: `${ModelNamespace.Portal}campaign/:id/schedule/line-items/:lineItemId/displays`,
            schedules: `${ModelNamespace.Portal}campaign/:id/schedule`,
            scheduleRules: `${ModelNamespace.Portal}campaign/:id/schedule/:scheduleId/rules`,
            attachSchedules: `${ModelNamespace.Portal}campaign/:id/schedule/attach`,
            detachSchedules: `${ModelNamespace.Portal}campaign/:id/schedule/detach`,
            deleteSchedules: `${ModelNamespace.Portal}campaign/:id/schedule/:scheduleId`,
            listTargets: `${ModelNamespace.Portal}campaign/:id/targets/list`,
            updateTargets: `${ModelNamespace.Portal}campaign/:id/targets/:targetId`,
            createTargets: `${ModelNamespace.Portal}campaign/:id/targets`,
            deleteTargets: `${ModelNamespace.Portal}campaign/:id/targets/:targetId`,
            attachTargetToSchedules: `${ModelNamespace.Portal}campaign/:id/targets/:targetId/attach`,
            detachTargetFromSchedules: `${ModelNamespace.Portal}campaign/:id/targets/:targetId/detach`,
            campaignPowerpoint: `${ModelNamespace.Portal}campaign/:id/powerpoint`,
            channelFrame: `${ModelNamespace.Portal}campaign/:id/channel-frame/queue-report`,
            addDisplay: `${ModelNamespace.Portal}campaign/:id/schedule/displays/add`,
            removeDisplay: `${ModelNamespace.Portal}campaign/:id/schedule/displays/remove`,
            removeUnknownDisplays: `${ModelNamespace.Portal}campaign/:id/schedule/displays/remove-unknown`,
            listDisplays: `${ModelNamespace.Portal}campaign/:id/schedule/displays`,
            displaysDownload: `${ModelNamespace.Portal}campaign/:id/schedule/displays/download`,
            listUnknownDisplays: `${ModelNamespace.Portal}campaign/:id/schedule/unknown-displays`,
            reImportUnknownDisplays: `${ModelNamespace.Portal}campaign/:id/schedule/reimport-unknown-displays`,
        },
        Pca: createGeneric('pca', ModelNamespace.Displays),
        CampaignDisplay: createGeneric('campaigndisplay', ModelNamespace.Portal),
        CampaignInstruction: createGeneric('campaigninstruction', ModelNamespace.Portal),
        CampaignOwnerContactedDate: createGeneric('campaignownercontacted', ModelNamespace.Portal),
        CampaignPopShot: {
            ...createGeneric('campaignpopshot', ModelNamespace.Portal),
            update: `${ModelNamespace.Portal}campaignpopshot`,
        },
        Creative: {
            ...createGeneric('creative', ModelNamespace.Portal),
            download: `${ModelNamespace.Portal}creative/download`,
            update: `${ModelNamespace.Portal}creative`,
            delete: `${ModelNamespace.Portal}creative`,
        },
        CreativeGroup: {
            ...createGeneric('creativegroup', ModelNamespace.Portal),
            delete: `${ModelNamespace.Portal}creativegroup`,
        },
        Client: createGeneric('client', ModelNamespace.Portal),
        Date: createGeneric('date', ModelNamespace.Portal),
        Display: createGeneric('display', ModelNamespace.Portal),
        File: createGeneric('file', ModelNamespace.Portal),
        Market: createGeneric('market', ModelNamespace.Portal),
        MissingDisplay: createGeneric('missingdisplay', ModelNamespace.InternalAdmin),
        Note: createGeneric('note', ModelNamespace.Portal),
        Owner: createGeneric('owner', ModelNamespace.Portal),
        Pack: createGeneric('pack', ModelNamespace.Portal),
        Permission: createGeneric('permission', ModelNamespace.Auth),
        Post: createGeneric('post', ModelNamespace.Portal),
        Request: createGeneric('request', ModelNamespace.Portal),
        Resolution: createGeneric('resolution', ModelNamespace.Portal),
        Role: {
            ...createGeneric('role', ModelNamespace.Auth),
            updatePermissions: `${ModelNamespace.Auth}role/:id/permissions`,
        },
        Rule: createGeneric('rule', ModelNamespace.Portal),
        Schedule: {
            ...createGeneric('schedule', ModelNamespace.Portal),
            createInCampaign: `${ModelNamespace.Portal}campaign/:campaign/schedule`,
            updateInCampaign: `${ModelNamespace.Portal}campaign/:campaign/schedule/:id`,

            delete: `${ModelNamespace.Portal}schedule`,

            campaignSchedules: `${ModelNamespace.Portal}campaign/:campaign/schedule/list`,
            deleteRules: `${ModelNamespace.Portal}campaign/schedule/:id/rules`,

            updateSchedulable: `${ModelNamespace.Portal}campaign/:campaign/schedulable`,
            uploadSiteList: `${ModelNamespace.Portal}campaign/:campaign/schedule/upload`,
            downloadSite: `${ModelNamespace.Portal}campaign/:campaign/schedule/:id/download`,
            revalidateSite: `${ModelNamespace.Portal}campaign/:campaign/schedule/:id/revalidate`,
        },
        Skin: {
            ...createGeneric('skin', ModelNamespace.Portal),
            uploadLargeLogo: `${ModelNamespace.Portal}skin/:id/logo`,
            uploadSmallLogo: `${ModelNamespace.Portal}skin/:id/logosmall`,
            attach: `${ModelNamespace.Portal}skin/:id/attach`,
        },
        SpotlightRecentResource: {},
        Stream: createGeneric('stream', ModelNamespace.Portal),
        User: {
            ...createGeneric('user', ModelNamespace.Portal),
            changeMyPassword: `${ModelNamespace.Portal}user/me/change-password`,
            me: `${ModelNamespace.Portal}user/me`,
            updateMe: `${ModelNamespace.Portal}user/me`,
            resendInvitation: `${ModelNamespace.Portal}user/:id/resend-invitation`,
        },

        Displays_AnalogueScreen: {
            ...createGeneric('analoguescreen', ModelNamespace.Displays),
            brochure: `${ModelNamespace.Displays}analoguescreen/:id/brochure`,
        },
        Displays_AudienceSource: createGeneric('audiencesource', ModelNamespace.Displays),
        Displays_AudienceFallback: createGeneric('audience-fallback', ModelNamespace.Displays),
        Displays_AudienceUpload: {
            ...createGeneric('audience', ModelNamespace.Displays),
            uploadManualAudience: `${ModelNamespace.Displays}audience/upload`,
        },
        Displays_AudienceVersion: createGeneric('audienceversion', ModelNamespace.Displays),
        Displays_Tag: {
            ...createGeneric('tag', ModelNamespace.Displays),
            createOperationalTimes: `${ModelNamespace.Displays}tag/:id/operational-times`,
            destroyOperationalTime: `${ModelNamespace.Displays}tag/:id/operational-times/:oid`,
        },
        Displays_Country: createGeneric('country', ModelNamespace.Displays),
        Displays_Currency: createGeneric('currency', ModelNamespace.Displays),
        Displays_DisplayIssues: createGeneric('issues', ModelNamespace.Displays),
        Displays_FileType: createGeneric('filetype', ModelNamespace.Displays),
        Displays_FrameType: createGeneric('frametype', ModelNamespace.Displays),
        Displays_Market: createGeneric('market', ModelNamespace.Displays),
        Displays_OperationalTime: createGeneric('operationaltime', ModelNamespace.Displays),
        Displays_Owner: {
            ...createGeneric('owner', ModelNamespace.Displays),
            getOwner: `${ModelNamespace.Displays}owner/get-owner`,
        },
        Displays_Pack: {
            ...createGeneric('pack', ModelNamespace.Displays),
            createOperationalTimes: `${ModelNamespace.Displays}pack/:id/operational-times`,
            destroyOperationalTime: `${ModelNamespace.Displays}pack/:id/operational-times/:oid`,
            brochure: `${ModelNamespace.Displays}pack/:id/brochure`,
        },
        Displays_Partner: createGeneric('partner', ModelNamespace.Displays),
        Displays_Screen: {
            ...createGeneric('screen', ModelNamespace.Displays),
            createOperationalTimes: `${ModelNamespace.Displays}screen/:id/operational-times`,
            destroyOperationalTime: `${ModelNamespace.Displays}screen/:id/operational-times/:oid`,
            attachVendorIds: `${ModelNamespace.Displays}screen/:id/attachDisplaysVendorIds`,
            deleteVendorIds: `${ModelNamespace.Displays}screen/:screenId/vendor/:vendorId/delete`,
            listVendorIds: `${ModelNamespace.Displays}screen/vendor/vendorName/list`,
            listEnvironments: `${ModelNamespace.Displays}screen/environment/list`,
            brochure: `${ModelNamespace.Displays}screen/:id/brochure`,
            frameSearch: `${ModelNamespace.Displays}screen/frame-search `,
            screenAllocation: `${ModelNamespace.Displays}screen/allocation`,
            exportCsv: `${ModelNamespace.Displays}screen/export`,
            reImport: `${ModelNamespace.Displays}screen/re-import`,
            massUpdating: `${ModelNamespace.Displays}screen/import`,
            getScreens: `${ModelNamespace.Displays}screen/get-screen`,
        },
        Displays_LineItem: {
            ...createGeneric('lineitem', ModelNamespace.Displays),
            validateFrameIds: `${ModelNamespace.Displays}lineitem/frameids`,
            frameDownload: `${ModelNamespace.Displays}screen/download/resolutions`,
        },
        Displays_Vendor: createGeneric('vendor', ModelNamespace.Displays),

        PopDisplays_ChannelFrame: {
            ...createGeneric('channelframe', ModelNamespace.PopDisplays),
            mediaBreakdown: `${ModelNamespace.PopDisplays}channelframe/:id/media`,
            export: `${ModelNamespace.PopDisplays}channelframe/export`,
            import: `${ModelNamespace.PopDisplays}channelframe/import`,
        },
        PopDisplays_Display: {
            ...createGeneric('display', ModelNamespace.PopDisplays),
            export: `${ModelNamespace.PopDisplays}display/export`,
            mediaBreakdown: `${ModelNamespace.PopDisplays}display/:id/media`,
            attachChannelFrames: `${ModelNamespace.PopDisplays}displaychannelframe/:id/create`,
            detachChannelFrames: `${ModelNamespace.PopDisplays}displaychannelframe/:id`,
        },
        PopDisplays_GraphReportType: {
            ...createGeneric('graphreporttype', ModelNamespace.PopDisplays),
        },
        PopDisplays_JobStatus: {
            ...createGeneric('jobstatus', ModelNamespace.PopDisplays),
        },
        PopDisplays_LogFileImport: {
            ...createGeneric('logfileimport', ModelNamespace.PopDisplays),
            upload: `${ModelNamespace.PopDisplays}logfileimport/upload`,
        },
        PopDisplays_LogFileParsed: {
            ...createGeneric('logfileparsed', ModelNamespace.PopDisplays),
        },
        PopDisplays_LogFileResource: {
            ...createGeneric('logfileresource', ModelNamespace.PopDisplays),
        },
        PopDisplays_LogImportSource: {
            ...createGeneric('logimportsource', ModelNamespace.PopDisplays),
        },
        PopDisplays_LogSourceAccount: {
            ...createGeneric('logsourceaccount', ModelNamespace.PopDisplays),
        },
        PopDisplays_LogSourceCampaignIdentifier: {
            ...createGeneric('logsourcecampaignidentifier', ModelNamespace.PopDisplays),
        },
        PopDisplays_Market: {
            ...createGeneric('market', ModelNamespace.PopDisplays),
        },
        PopDisplays_Media: {
            ...createGeneric('media', ModelNamespace.PopDisplays),
        },
        Campaign_Chat: {
            ...createGeneric('chat', ModelNamespace.AI),
            chatEndpoint: `${ModelNamespace.AI}chat`,
        },
    },
    auth: {
        login: `${ModelNamespace.Auth}login`,
        refresh: `${ModelNamespace.Auth}refresh`,
        logout: `${ModelNamespace.Auth}logout`,
        requestForgotPassword: `${ModelNamespace.Auth}forgot-password`,
        resetPassword: `${ModelNamespace.Auth}reset-password`,
        revalidate: `${ModelNamespace.Auth}revalidate-email`,
        startImpersonating: `${ModelNamespace.Auth}impersonate/:id`,
        stopImpersonating: `${ModelNamespace.Auth}impersonate/stop`,

        inviteDetails: `${ModelNamespace.Portal}invite/detail`,
        inviteAccept: `${ModelNamespace.Portal}invite/accept`,
        register: `${ModelNamespace.Portal}user/register`,
        verifyEmail: `${ModelNamespace.Portal}user/verify`,
    },
    happyDemics: `${ModelNamespace.Portal}happydemics/list`,
    reports: {
        dashboard: {
            charts: `${ModelNamespace.Reporting}dynamic`,
            campaignReports: `${ModelNamespace.Reporting}campaign`,
        },
        pca: {
            endpoint: `${ModelNamespace.Reporting}pcareport/:id/:report`,
            mapEndpoint: `${ModelNamespace.Reporting}pcareport-map/:id`,
            analyticsEndpoint: `${ModelNamespace.Reporting}pcareport-analytics/:id`,
            campaignFramesEndpoint: `${ModelNamespace.Reporting}pcareport-frames/:id`,
            energyEndpoint: `${ModelNamespace.Reporting}pcareport/:id/energy/:field`,
            run: `${ModelNamespace.Reporting}cadreport/:id/run`,
            delete: `${ModelNamespace.Reporting}cadreport/:id/delete`,
            targetMultiplier: `${ModelNamespace.Reporting}cadreport/:id/calculate-target-multiplier`,
            publish: `${ModelNamespace.Reporting}pcareport/:id/publish`,
            impressionMultipliers: `${ModelNamespace.Reporting}pcareport/:id/impression-multipliers`,
            geopathImpressionMultipliers: `${ModelNamespace.Reporting}pcareport/:id/geopath-impression-multipliers`,
        },
        pcaElasticSearch: {
            summary: `${ModelNamespace.Reporting}pcareport/:id/summary`,
            groupByField: `${ModelNamespace.Reporting}pcareport/:id`,
            frameIdMapData: `${ModelNamespace.Reporting}pcareport/:id/map`,
            displayMapData: `${ModelNamespace.Reporting}pcareport/:id/map/:displayId`,
            stats: `${ModelNamespace.Reporting}pcareport/:id/stats`,
            histogram: `${ModelNamespace.Reporting}pcareport/:id/histogram`,
        },
    },
    virtualCampaignBuilder: {
        summary: `${ModelNamespace.PopDisplays}campaign/build`,
        standardPlaysExport: `${ModelNamespace.PopDisplays}campaign/export`,
        displayPlaysExport: `${ModelNamespace.PopDisplays}campaign/display-export`,
    },
    popDisplays: {
        playoutExport: `${ModelNamespace.PopDisplays}logfileparsed/playoutExport`,
    },
};
