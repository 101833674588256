import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDateUtils } from 'c-hooks';
import {
    CampaignCreativesResponse,
    CampaignErrorableType,
    CampaignErrorType,
    CreativeGroup,
    Campaign,
} from 'c-sdk';
import { uniq } from 'ramda';
import { parse } from 'date-fns';
import { useEntityData } from 'c-data';
import { useDebounce } from 'use-debounce';
import { creativeToFileBrowserFile } from 'c-main/Lib';
import { stringOnlyNumbers } from 'c-lib';
import { FileBrowserFile } from 'c-components';
import { filterTaggedFiles } from 'c-components/FileBrowser/lib';
import { creativeInOneOfGroups, StaticCreativeGroup } from './lib';
import useCampaignCreativesData from './useCampaignCreativesData';

function useCampaignCreatives(campaignId: number) {
    const {
        creativeData,
        setCreativeData,
        getCreatives,
        updateCreatives,
        resetCreativesData: $resetCreativesData,
        reValidateCampaign,
        revalidateLoading,
    } = useCampaignCreativesData(campaignId);

    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id: campaignId });

    const [openGroup, setOpenGroup] = useState<number>(StaticCreativeGroup.AllCreatives);
    const [selectedCreatives, setSelectedCreatives] = useState<number[]>([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [resolutionFilters, setResolutionFilters] = useState<string[]>([]);
    const [aspectRatioFilters, setAspectFilters] = useState<string[]>([]);
    const [fileTypeFilters, setFileTypeFilters] = useState<string[]>([]);
    const [errorFilters, setErrorFilters] = useState<CampaignErrorType[]>([]);
    const [durationFilter, setDurationFilter] = useState<string>(null);
    const [liveDateFilter, setLiveDateFilter] = useState<string>(null);
    const [uploadDateFilter, setUploadDateFilter] = useState<string>(null);

    const resetCreativesData = useCallback(
        (newData: CampaignCreativesResponse) => {
            $resetCreativesData(newData);
            setOpenGroup(StaticCreativeGroup.AllCreatives);
        },
        [$resetCreativesData],
    );

    const addGroup = useCallback(
        async (group: CreativeGroup, openGroup = true) => {
            setCreativeData(curr => ({
                ...curr,
                creativeGroups: [...curr.creativeGroups, { ...group }],
            }));

            if (openGroup) setOpenGroup(group.id);
        },
        [setCreativeData],
    );

    const updateGroup = useCallback(
        async (group: CreativeGroup, openGroup = true) => {
            setCreativeData(curr => {
                const index = curr.creativeGroups.findIndex(g => g.id === group.id);
                const newGroups = [...curr.creativeGroups];
                newGroups[index] = group;
                return {
                    ...curr,
                    creativeGroups: [...newGroups],
                };
            });

            if (openGroup) setOpenGroup(group.id);
        },
        [setCreativeData],
    );

    const deleteCreativesAndGroups = useCallback(
        ({
            creatives = [],
            groups = [],
            deleteCreativesFromGroup = false,
        }: {
            creatives?: number[];
            groups?: number[];
            deleteCreativesFromGroup?: boolean;
        }) => {
            setCreativeData(curr => ({
                // filter out deleted groups
                creativeGroups: curr.creativeGroups.filter(g => groups.indexOf(g.id) === -1),
                creatives: curr.creatives
                    // filter out deleted creatives
                    .filter(c => creatives.indexOf(c.id) === -1)
                    .filter(c => {
                        if (deleteCreativesFromGroup && creativeInOneOfGroups(c, groups)) {
                            // the creative was in one of the deleted groups
                            // and the flag for deleting the creatives from the deleted group was turned on
                            return false;
                        }

                        return true;
                    })
                    .map(c => ({
                        ...c,
                        creativeGroupIds:
                            // remove deleted group ids from any creative that still has an association to the group
                            c?.creativeGroupIds?.filter(id => groups.indexOf(id) === -1) ?? [],
                    })),
            }));

            setOpenGroup(val => {
                if (groups.indexOf(val) !== -1) {
                    return StaticCreativeGroup.AllCreatives;
                }
                return val;
            });
        },
        [setCreativeData],
    );

    const fileCreativesKeyed = useMemo(
        () =>
            creativeData?.creatives?.reduce(
                (files, creative) => ({
                    ...files,
                    [creative.id]: {
                        ...creativeToFileBrowserFile(creative),
                        id: creative.id,
                    },
                }),
                {} as Record<number, FileBrowserFile>,
            ),
        [creativeData?.creatives],
    );

    const creativeLevelErrors = useMemo(
        () =>
            campaign?.errors?.filter(
                err => err.errorable_type === CampaignErrorableType.Creative,
            ) ?? [],
        [campaign],
    );

    const allFilters = useMemo(() => {
        const theFilters = creativeData.creatives.reduce(
            (filters, creative) => {
                const file = fileCreativesKeyed[creative.id];

                if (
                    file.tags.resolution != null &&
                    filters.resolutions.indexOf(file.tags.resolution) === -1
                )
                    filters.resolutions.push(file.tags.resolution);

                if (
                    file.tags.aspectRatio !== '' &&
                    filters.aspectRatios.indexOf(file.tags.aspectRatio) === -1
                )
                    filters.aspectRatios.push(file.tags.aspectRatio);

                if (
                    file.tags.fileType != null &&
                    filters.fileTypes.indexOf(file.tags.fileType) === -1
                )
                    filters.fileTypes.push(file.tags.fileType);

                return filters;
            },
            {
                resolutions: [] as string[],
                aspectRatios: [] as string[],
                fileTypes: [] as string[],
                errors: [] as CampaignErrorType[],
                duration: null as string,
                liveDate: null as Date as any,
                uploadDate: null as Date as any,
            },
        );

        theFilters.errors = uniq(creativeLevelErrors.map(err => err.type));

        return theFilters;
    }, [creativeData.creatives, creativeLevelErrors, fileCreativesKeyed]);

    useEffect(() => {
        /**
         * covers a use case where you have for example filtered by resolution 1080x1920
         * then you delete the creative with that resolution which removes it from the dropdown.
         * problem is that the resolution is then stuck as a filter.
         *
         * Here we're automatically removing filter values for filter options that no longer exist.
         */
        setResolutionFilters(current => {
            const newList = current.filter(val => allFilters.resolutions.indexOf(val) !== -1);
            return newList.length !== current.length ? newList : current;
        });
        setAspectFilters(current => {
            const newList = current.filter(val => allFilters.aspectRatios.indexOf(val) !== -1);
            return newList.length !== current.length ? newList : current;
        });
        setFileTypeFilters(current => {
            const newList = current.filter(val => allFilters.fileTypes.indexOf(val) !== -1);
            return newList.length !== current.length ? newList : current;
        });
        setErrorFilters(current => {
            const newList = current.filter(val => allFilters.errors.indexOf(val) !== -1);
            return newList.length !== current.length ? newList : current;
        });
    }, [allFilters]);

    const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
    const [debouncedResolutions] = useDebounce(resolutionFilters, 1000);
    const [debouncedAspectRatios] = useDebounce(aspectRatioFilters, 1000);
    const [debouncedFileTypes] = useDebounce(fileTypeFilters, 1000);
    const [debouncedErrors] = useDebounce(errorFilters, 1000);
    const [debouncedDuration] = useDebounce(durationFilter, 1000);
    const [debouncedLiveDate] = useDebounce(liveDateFilter, 1000);
    const [debouncedUploadDate] = useDebounce(uploadDateFilter, 1000);

    const { dayMonthYearApiFormat, dayMonthYearHourMinuteApiFormat } = useDateUtils();

    const debouncedFilters = useMemo<typeof allFilters>(
        () => ({
            resolutions: debouncedResolutions,
            aspectRatios: debouncedAspectRatios,
            fileTypes: debouncedFileTypes,
            errors: debouncedErrors,
            duration: debouncedDuration,
            liveDate: debouncedLiveDate
                ? parse(debouncedLiveDate, dayMonthYearApiFormat, new Date())
                : null,
            uploadDate: debouncedUploadDate
                ? parse(debouncedUploadDate, dayMonthYearHourMinuteApiFormat, new Date())
                : null,
        }),
        [
            debouncedAspectRatios,
            debouncedFileTypes,
            debouncedResolutions,
            debouncedErrors,
            debouncedDuration,
            debouncedLiveDate,
            debouncedUploadDate,
            dayMonthYearApiFormat,
            dayMonthYearHourMinuteApiFormat,
        ],
    );

    const filteredCreatives = useMemo<number[]>(
        () =>
            filterTaggedFiles(Object.values(fileCreativesKeyed), {
                ...debouncedFilters,
                duration: stringOnlyNumbers(debouncedFilters.duration)
                    ? Number(debouncedFilters.duration)
                    : null,
                term: debouncedSearchTerm.toLowerCase(),
            }).filter(creativeId => {
                if (debouncedFilters.errors.length === 0) return true;

                const allCreativeErrorTypes = creativeLevelErrors
                    .filter(
                        err =>
                            err.errorable_type === CampaignErrorableType.Creative &&
                            err.errorable_id === String(creativeId),
                    )
                    .map(err => err.type);

                // check there are some errors in common
                return debouncedFilters.errors.some(
                    type => allCreativeErrorTypes.indexOf(type) !== -1,
                );
            }),
        [fileCreativesKeyed, debouncedFilters, debouncedSearchTerm, creativeLevelErrors],
    );

    useEffect(() => {
        // clear selected creatives when switching groups
        setSelectedCreatives([]);
    }, [openGroup]);

    return {
        resetCreativesData,

        openGroup,
        setOpenGroup,

        addGroup,

        updateGroup,

        selectedCreatives,
        setSelectedCreatives,
        updateCreatives,

        getCreatives,
        creatives: creativeData,

        debouncedSearchTerm,
        searchTerm,
        setSearchTerm,

        filteredCreatives,
        allFilters,
        debouncedFilters,

        resolutionFilters,
        setResolutionFilters,
        aspectRatioFilters,
        setAspectFilters,
        fileTypeFilters,
        setFileTypeFilters,
        errorFilters,
        setErrorFilters,
        durationFilter,
        setDurationFilter,
        liveDateFilter,
        setLiveDateFilter,
        uploadDateFilter,
        setUploadDateFilter,

        deleteCreativesAndGroups,
        reValidateCampaign,
        revalidateLoading,
    };
}

export default useCampaignCreatives;
