import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { logFileImportIcon } from 'c-main/icons';
import { PermissionName, PopDisplays_LogFileImport } from 'c-sdk';
import {
    FilterableEntityTable,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { LogFileImportFileNameCell } from 'c-pop-displays/Components/LogFileImportTableCells';
import ReactGA from 'react-ga';
import {
    Box,
    Button,
    LinearProgress,
    LinearProgressProps,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { Alert, AllEntitiesDropdown, DialogV2, FileUploadDropzone } from 'c-components';
import { useBoolean } from 'react-hanger';
import { useAPIClientRequest } from 'c-hooks';
import { apiClient } from 'c-data';
import { useCommonTranslation } from 'c-translation';
import { NetworkRequestState } from 'c-data-layer';
import { CheckCircle, Error } from '@mui/icons-material';

const paginationTag = 'log-file-import-list-search';
const textSearchCols = ['file'];

const columns = [
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.idCol',
        field: 'id',
        entityName: 'PopDisplays_LogFileImport',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.typeCol',
        field: 'type',
        entityName: 'PopDisplays_LogFileImport',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.statusCol',
        field: 'status',
        entityName: 'PopDisplays_LogFileImport',
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.dateCol',
        field: 'datetime',
        entityName: 'PopDisplays_LogFileImport',
        dataType: 'date',
        dateProps: { showTime: true },
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.fileCol',
        field: 'file',
        entityName: 'PopDisplays_LogFileImport',
        RenderCell: LogFileImportFileNameCell,
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.fileSizeCol',
        field: 'filesize',
        entityName: 'PopDisplays_LogFileImport',
        dataType: 'fileSize',
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.createdCol',
        field: 'created_at',
        entityName: 'PopDisplays_LogFileImport',
        dataType: 'date',
        dateProps: { showTime: true },
        orderable: true,
    }),
    generateColumn<PopDisplays_LogFileImport>({
        headerTitle: 'Modules.PopDisplays.LogFileImport.table.updatedCol',
        field: 'updated_at',
        entityName: 'PopDisplays_LogFileImport',
        dataType: 'date',
        dateProps: { showTime: true },
        orderable: true,
    }),
];

const handleDownloadDateAndHour = () => {
    const headers = [
        'date',
        'time',
        'media_name',
        'channel_name',
        'plays',
        'duration',
        'impacts',
        'value',
        'media_extid',
        'creative_ref',
        'buyer',
        'agency',
        'brand',
        'uuid',
        'channel_extid',
        'frame_name',
        'frame_extid',
        'player_name',
        'player_extid',
        'ext_source',
        'custom',
    ];

    const rows = [
        [
            '10/03/2025',
            '22',
            '123.jpg',
            '123',
            '1',
            '6',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ],
        [
            '12/03/2025',
            '19',
            '123.jpg',
            '123',
            '18',
            '10',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ],
    ];

    const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Date And Time.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const handleDownloadDateTime = () => {
    const headers = [
        'datetime',
        'media_name',
        'channel_name',
        'media_extid',
        'plays',
        'duration',
        'impacts',
        'value',
        'media_extid.1',
        'creative_ref',
        'buyer',
        'agency',
        'brand',
        'uuid',
        'channel_extid',
        'frame_name',
        'frame_extid',
        'player_name',
        'player_extid',
        'ext_source',
        'custom',
    ];

    const rows = [
        [
            '02/02/2025 06:00',
            '123.jpg',
            '123',
            '1120810865',
            '1',
            '6',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ],
        [
            '02/02/2025 07:00',
            '123.jpg',
            '123',
            '1120810865',
            '18',
            '10',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ],
    ];

    const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'DateTime.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const LogFileImportListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const [dropdownValue, setDropdownValue] = useState(null);
    const uploadState = useBoolean(false);
    const { start, data, error, reset, requestState } = useAPIClientRequest(
        apiClient.PopDisplays.logUpload,
    );
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState<ProgressEvent>(null);
    const progressPercentage = useMemo(
        () =>
            progress?.total != null && progress?.loaded != null
                ? (progress.loaded / progress.total) * 100
                : 0,
        [progress],
    );
    const progressColor = useMemo<LinearProgressProps['color']>(() => {
        if (requestState === NetworkRequestState.Error) return 'error';
        if (progressPercentage >= 100) return 'success';

        return 'primary';
    }, [requestState, progressPercentage]);
    const onFileAdded = useCallback((files: File[]) => {
        setFiles(prev => [...prev, ...files]);
    }, []);
    const t = useCommonTranslation();
    const uploadFiles = useCallback(async () => {
        reset();
        const formData = new FormData();
        formData.append('source_id', dropdownValue);
        files.forEach(f => formData.append('files[]', f));

        await start(formData, setProgress);
    }, [dropdownValue, files, reset, start]);
    const closeDialog = useCallback(() => {
        uploadState.setFalse();
        setFiles(null);
        setDropdownValue(null);
        reset();
    }, [reset, uploadState]);
    useEffect(() => {
        if (data?.data?.success) {
            setFiles(null);
            setDropdownValue(null);
            setTimeout(() => {
                reset();
            }, 3000);
        }
    }, [data, reset]);

    const rawUploadLogHandler = useMemo(
        () => (
            <>
                <Button onClick={uploadState.setTrue} variant="contained">
                    {t('Modules.PopDisplays.LogFileImport.uploadRawLog')}
                </Button>
                <DialogV2 onClose={closeDialog} open={uploadState.value} maxWidth="xl">
                    <Stack
                        direction="row"
                        gap={2}
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <Button
                            onClick={handleDownloadDateAndHour}
                            variant="contained"
                            sx={{ minWidth: '10rem' }} // Ensures same size for buttons
                        >
                            {t('Modules.PopDisplays.LogFileImport.downloadDateAndHourTemplate')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleDownloadDateTime}
                            sx={{ minWidth: '10rem' }} // Ensures same size for buttons
                        >
                            {t('Modules.PopDisplays.LogFileImport.downloadDateTimeTemplate')}
                        </Button>
                    </Stack>

                    <AllEntitiesDropdown
                        entityName="PopDisplays_LogImportSource"
                        labelField="name"
                        onChange={setDropdownValue}
                        value={dropdownValue}
                        multi
                        label={t('Modules.PopDisplays.LogFileImport.selectLogSource')}
                    />
                    <FileUploadDropzone
                        fileAccept=".csv"
                        onFilesAdded={onFileAdded}
                        dropzoneLabel="Modules.PopDisplays.LogFileImport.dropzoneText"
                    />
                    {requestState === NetworkRequestState.Idle && (
                        <Stack gap={1}>
                            {files &&
                                files.map((f, index) => (
                                    <Stack key={f.name} alignItems="center">
                                        <Alert
                                            severity="info"
                                            onClose={() => {
                                                setFiles(prevFiles =>
                                                    prevFiles.filter((_, i) => i !== index),
                                                );
                                            }}
                                        >
                                            {f.name}
                                        </Alert>
                                    </Stack>
                                ))}
                        </Stack>
                    )}
                    {requestState !== NetworkRequestState.Idle && (
                        <Stack direction="row" gap={1} alignItems="center">
                            <Box flex={1}>
                                <LinearProgress
                                    variant="determinate"
                                    value={progressPercentage}
                                    color={progressColor}
                                />
                            </Box>
                            {requestState === NetworkRequestState.InProgress && (
                                <Typography variant="caption">
                                    {progressPercentage.toFixed(0)} %
                                </Typography>
                            )}
                            {requestState === NetworkRequestState.Error && (
                                <Tooltip title={String(error)}>
                                    <Typography color="error.main">
                                        <Error />
                                    </Typography>
                                </Tooltip>
                            )}
                            {requestState === NetworkRequestState.Success && (
                                <Typography color="success.main">
                                    <CheckCircle />
                                </Typography>
                            )}
                        </Stack>
                    )}
                    <Button onClick={uploadFiles} variant="contained">
                        {t('Modules.PopDisplays.LogFileImport.submit')}
                    </Button>
                    {error && (
                        <Alert severity="error" variant="outlined" onClose={reset}>
                            {String(error)}
                        </Alert>
                    )}
                    {data?.data?.success && (
                        <Alert severity="success" variant="outlined" onClose={reset}>
                            {t('Modules.PopDisplays.LogFileImport.uploadSuccess')}
                        </Alert>
                    )}
                </DialogV2>
            </>
        ),
        [
            dropdownValue,
            error,
            files,
            onFileAdded,
            progressColor,
            progressPercentage,
            requestState,
            reset,
            t,
            uploadFiles,
            uploadState,
            closeDialog,
            data,
        ],
    );
    return (
        <FullPageEntityTableWrapper
            title="Modules.PopDisplays.LogFileImport.metaTitle"
            controls={useMemo(() => [rawUploadLogHandler], [rawUploadLogHandler])}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="PopDisplays_LogFileImport"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                showFilters
            />
        </FullPageEntityTableWrapper>
    );
};

const setup: PageConfig = {
    id: 'LogFileImportListPage',
    component: LogFileImportListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.PopDisplays.LogFileImport.metaTitle',
        description: 'Modules.PopDisplays.LogFileImport.description',
        type: 'page',
        icon: logFileImportIcon,
        aliases: 'SystemSearch.aliases.popDisplaysLogFileImportList',
        route: PostAuthRoutes.PopDisplays.LogFileImportList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            { label: 'Modules.PopDisplays.LogFileImport.metaTitle' },
        ],
        permissions: [PermissionName.Pop_displaysLog_file_importsRead],
    },
};

export default setup;
