import { sort } from 'ramda';
import { isAfter, isSameDay, isValid, isEqual, isBefore } from 'date-fns';
import { dateSort } from 'c-lib';
import {
    FileBrowserFile,
    FileBrowserOrderBy,
    FileBrowserOrderByDirection,
    FileIconType,
    FileTagsGroups,
} from './types';

export const getFileType = (file: FileBrowserFile): FileIconType => {
    const parts = file?.mimeType?.split('/') ?? [''];

    // lots of weird checks before the DB is full of garbage data
    if (parts[0] === 'video' || file.fileType === 'video' || file.name.endsWith('.mov'))
        return FileIconType.Video;
    if (['pdf', 'octet-stream'].indexOf(parts[1]) !== -1 || file.name.endsWith('.pdf'))
        return FileIconType.PDF;
    if (parts[0] === 'image' || file.fileType === 'png' || file.fileType === 'jpeg')
        return FileIconType.Image;

    return FileIconType.File;
};

export const generateFileResolutionTag = (width: number, height: number): string => {
    if (width > 0 && height > 0) {
        return `${width} x ${height}`;
    }

    return null;
};

export const generateFileResolution = (file?: FileBrowserFile): string => {
    if (file?.width > 0 && file?.height > 0) {
        return `${file.width} x ${file.height}`;
    }

    return generateFileResolutionTag(file?.width, file?.height) ?? '';
};

export const fileHasResolution = (file?: FileBrowserFile) => file?.width > 0 && file?.height > 0;

export const extractTagsFromFiles = (files: FileBrowserFile[]): FileTagsGroups =>
    files.reduce(
        (acc, curr) => {
            // resolutions
            const res = generateFileResolution(curr);
            if (acc.resolution[res] == null) {
                acc.resolution[res] = [];
            }
            acc.resolution[res].push(curr.id);

            // file types
            if (acc.fileType[curr.fileTypeSimple] == null) {
                acc.fileType[curr.fileTypeSimple] = [];
            }
            acc.fileType[curr.fileTypeSimple].push(curr.id);

            // aspect ratios
            if (acc.aspectRatios[curr.aspectRatio] == null) {
                acc.aspectRatios[curr.aspectRatio] = [];
            }
            acc.aspectRatios[curr.aspectRatio].push(curr.id);

            return acc;
        },
        { fileType: {}, resolution: {}, aspectRatios: {} } as FileTagsGroups,
    );

export const filterTaggedFiles = (
    files: FileBrowserFile[],
    {
        term,
        resolutions,
        fileTypes,
        aspectRatios,
        groupIds,
        duration,
        liveDate,
        uploadDate,
    }: {
        term?: string;
        resolutions?: string[];
        fileTypes?: string[];
        aspectRatios?: string[];
        groupIds?: number[];
        // expected as number of seconds
        duration?: number;
        liveDate?: Date;
        endDate?: Date;
        uploadDate?: Date;
    },
): number[] => {
    let filteredFiles = files;
    if (term != null && term?.trim()?.length > 0) {
        filteredFiles = files.filter(
            f => (f?.name?.toLowerCase() ?? '').indexOf(term?.toLowerCase()) !== -1,
        );
    }

    if (Array.isArray(resolutions) && resolutions?.length > 0) {
        filteredFiles = filteredFiles.filter(
            f => f?.tags?.resolution != null && resolutions.indexOf(f?.tags?.resolution) !== -1,
        );
    }

    if (Array.isArray(fileTypes) && fileTypes?.length > 0) {
        filteredFiles = filteredFiles.filter(
            f => f?.tags?.fileType != null && fileTypes.indexOf(f?.tags?.fileType) !== -1,
        );
    }

    if (Array.isArray(aspectRatios) && aspectRatios?.length > 0) {
        filteredFiles = filteredFiles.filter(
            f => f?.tags?.aspectRatio != null && aspectRatios.indexOf(f?.tags?.aspectRatio) !== -1,
        );
    }

    if (Array.isArray(groupIds) && groupIds?.length > 0) {
        filteredFiles = filteredFiles.filter(f =>
            f.groupIds.some(gid => groupIds.indexOf(gid) !== -1),
        );
    }

    if (isValid(liveDate)) {
        filteredFiles = filteredFiles.filter(
            f =>
                isSameDay(liveDate, f.liveDateDate) ||
                isSameDay(liveDate, f.endDateDate) ||
                (isAfter(liveDate, f.liveDateDate) && isBefore(liveDate, f.endDateDate)),
        );
    }

    if (isValid(uploadDate)) {
        filteredFiles = filteredFiles.filter(
            f =>
                (f.createdAtDate != null && isEqual(f.createdAtDate, uploadDate)) ||
                isAfter(f.createdAtDate, uploadDate),
        );
    }

    if (duration != null) {
        filteredFiles = filteredFiles.filter(
            f => Number((f.durationMs / 1000).toFixed(0)) === duration,
        );
    }

    return filteredFiles.map(f => f.id);
};

const fieldMapping: Partial<Record<FileBrowserOrderBy, keyof FileBrowserFile>> = {
    [FileBrowserOrderBy.UploadDate]: 'createdAt',
    [FileBrowserOrderBy.UpdatedAt]: 'updatedAt',
    [FileBrowserOrderBy.LiveDate]: 'liveDate',
    [FileBrowserOrderBy.EndDate]: 'endDate',
};

export const sortFilesBy = (
    files: FileBrowserFile[],
    column: FileBrowserOrderBy,
    direction: FileBrowserOrderByDirection,
) => {
    const isDate =
        [
            FileBrowserOrderBy.UploadDate,
            FileBrowserOrderBy.UpdatedAt,
            FileBrowserOrderBy.LiveDate,
            FileBrowserOrderBy.EndDate,
        ].indexOf(column) !== -1;
    if (isDate) {
        return sort(
            (a, b) =>
                dateSort(
                    a[fieldMapping[column]] as string,
                    b[fieldMapping[column]] as string,
                    direction,
                ),
            files,
        );
    }
    if (column === FileBrowserOrderBy.Name) {
        return sort(
            (a, b) =>
                direction === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
            files,
        );
    }
    if (column === FileBrowserOrderBy.AspectRatio) {
        return sort(
            (a, b) =>
                direction === 'asc'
                    ? a.aspectRatioNormalised - b.aspectRatioNormalised
                    : b.aspectRatioNormalised - a.aspectRatioNormalised,
            files,
        );
    }
    if (column === FileBrowserOrderBy.FileSize) {
        return sort((a, b) => (direction === 'asc' ? a.size - b.size : b.size - a.size), files);
    }
    if (column === FileBrowserOrderBy.AssignedDisplays) {
        return sort(
            (a, b) =>
                direction === 'asc'
                    ? a.assignedDisplays - b.assignedDisplays
                    : b.assignedDisplays - a.assignedDisplays,
            files,
        );
    }

    return files;
};
