import React, { useCallback, useMemo } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { Button, Checkbox, CircularProgress, Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import to from 'await-to-js';
import { useCommonTranslation } from 'c-translation';
import { NetworkRequestState } from 'c-data-layer';
import { Alert } from 'c-components';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';

const UnknownTab = ({ id }) => {
    const { start: removeStart, isLoading: isRemoving } = useAPIClientRequest(
        ApiClient.Entities.Campaign.removeUnknownDisplays,
    );
    const {
        start: importStart,
        isLoading: isImporting,
        reset,
        requestState,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.reImportUnknownDisplays);
    const t = useCommonTranslation();
    const {
        listUnknownDisplays,
        unknownLoading,
        setSelectedUnknowns,
        selectedUnknowns,
        unknownDisplays,
    } = useNewCampaignSchedule(id);

    const importUnknownDisplays = useCallback(async () => {
        const [err] = await to(importStart(id));
        if (!err) {
            listUnknownDisplays();
        }
    }, [id, importStart, listUnknownDisplays]);

    const removeUnknownDisplays = useCallback(async () => {
        const [err] = await to(removeStart(id, { ids: selectedUnknowns }));
        if (!err) {
            setSelectedUnknowns([]);
            listUnknownDisplays();
        }
    }, [removeStart, id, selectedUnknowns, setSelectedUnknowns, listUnknownDisplays]);

    const toggleSelection = useCallback(
        rowId => {
            setSelectedUnknowns(prev =>
                prev.includes(rowId) ? prev.filter(id => id !== rowId) : [...prev, rowId],
            );
        },
        [setSelectedUnknowns],
    );

    const toggleSelectAll = useCallback(() => {
        setSelectedUnknowns(prev =>
            prev.length === unknownDisplays.length ? [] : unknownDisplays.map(row => row.id),
        );
    }, [setSelectedUnknowns, unknownDisplays]);

    const columns = useMemo(
        () => [
            {
                field: 'select',
                headerName: '',
                width: 100,
                sortable: false,
                renderHeader: () => (
                    <Checkbox
                        checked={
                            selectedUnknowns.length === unknownDisplays.length &&
                            unknownDisplays.length > 0
                        }
                        indeterminate={
                            selectedUnknowns.length > 0 &&
                            selectedUnknowns.length < unknownDisplays.length
                        }
                        onChange={toggleSelectAll}
                    />
                ),
                renderCell: ({ row }) => (
                    <Checkbox
                        checked={selectedUnknowns.includes(row.id)}
                        onChange={() => toggleSelection(row.id)}
                    />
                ),
            },
            {
                field: 'id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.unknownTab.table.id'),
                width: 90,
            },
            {
                field: 'frame_id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.unknownTab.table.frameId'),
                width: 150,
            },
        ],
        [t, selectedUnknowns, unknownDisplays?.length, toggleSelectAll, toggleSelection],
    );

    return (
        <Stack spacing={2} p={2}>
            <Stack direction="row" spacing={2}>
                <Stack>
                    <Button
                        onClick={importUnknownDisplays}
                        variant="contained"
                        disabled={isImporting}
                    >
                        {isImporting ? (
                            <CircularProgress size={20} />
                        ) : (
                            t(
                                'Modules.Main.Campaigns.CampaignSchedule.unknownsTab.syncUnknownDisplays',
                            )
                        )}
                    </Button>
                    {requestState === NetworkRequestState.Success && (
                        <Alert severity="success" onClose={reset}>
                            {t('Modules.Main.Campaigns.CampaignSchedule.unknownTab.queued')}
                        </Alert>
                    )}
                </Stack>
                <Button
                    onClick={removeUnknownDisplays}
                    variant="contained"
                    color="error"
                    disabled={selectedUnknowns.length === 0 || isRemoving}
                >
                    {isRemoving ? (
                        <CircularProgress size={20} />
                    ) : (
                        t('Modules.Main.Campaigns.CampaignSchedule.unknownsTab.removeSelected')
                    )}
                </Button>
            </Stack>
            <DataGridPro
                columns={columns}
                rows={unknownDisplays}
                loading={unknownLoading}
                autoHeight
            />
        </Stack>
    );
};

export default UnknownTab;
