import { PopDisplays_JobStatus } from 'c-sdk';

export const isErrorNote = (entity: PopDisplays_JobStatus) =>
    entity.notes?.toLowerCase().indexOf('error') !== -1 ||
    entity.notes?.toLowerCase().indexOf('stopped') !== -1;

export const hasFailedEcsTask = (entity: PopDisplays_JobStatus) =>
    ['STOPPING', 'STOPPED', 'DEPROVISIONING', 'DELETED', 'DEACTIVATING'].includes(
        entity.ecs_task_status,
    ) && entity.completed_at === null;

export const isSuccessNote = (entity: PopDisplays_JobStatus) => entity.completed_at != null;
