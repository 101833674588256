import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import { Buyer as BuyerModel, ModuleConstructorProps } from '../Types';
import { generateArrayQSParams } from '../Lib';
import { BaseCreateFunc, BaseEntityModuleFuncs } from './BaseEntityModuleFuncs';

export type HappyDemics = {
    client_key_name: string;
    created_at: string;
    happy_demics_id: number;
    has_campaigns: boolean;
    id: number;
    name: string;
    report_url_en: string;
    report_url_fr: string;
    type: string;
};
const Buyer = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<BuyerModel>({ endpoints, httpClient, baseUrl }, 'Buyer'),
    create: (data: BuyerModel, includes?: string[], config?: AxiosRequestConfig) =>
        BaseCreateFunc<BuyerModel>({ endpoints, httpClient, baseUrl }, 'Buyer')(
            data,
            includes,
            config,
        ),
    attachAudienceSource: (
        id: number,
        audienceSourceId: number,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        const url = `${urlcat(endpoints.Entities.Buyer.attachAudienceVersion, {
            id,
        })}${includeParams}`;

        return httpClient.post<{ success: boolean }>(
            url,
            { audience_source_id: audienceSourceId },
            config,
        );
    },
    happyDemics: (config?: AxiosRequestConfig) =>
        httpClient.post<{ data: HappyDemics[] }>(
            `${endpoints.happyDemics}?perPage=100000000`,
            config,
        ),
});

export default Buyer;
