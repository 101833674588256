import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { Box, Button, CircularProgress, ToggleButton } from '@mui/material';
import { Alert, AutoGrid, Checkbox, Chip, DialogV2 } from 'c-components';
import TextAreaField from 'c-components/Forms/TextAreaField';
import to from 'await-to-js';
import { DataGridPro } from '@mui/x-data-grid-pro';

import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { ScheduleGet } from 'c-sdk';
import { useCommonTranslation } from 'c-translation';
import StandaloneFilters from 'c-pagination/Components/StandaloneFilters/StandaloneFilters';
import { NetworkRequestState } from 'c-data-layer';

const DisplaysTab = ({ id, handleDetach, detachRequestState }) => {
    const t = useCommonTranslation();
    const frameIdUploadState = useBoolean(false);
    const isFrameIdUpload = useBoolean(true);
    const displayRemoveState = useBoolean(false);
    const [idsToUpload, setIdsToUpload] = useState<string>('');
    const {
        selectedDisplays,
        setSelectedDisplays,
        setExpandedScheduleId,
        setScheduleSearch,
        displayLoading,
        getDisplaysList,
        displayFilters,
        displayData,
        displayFiltersValues,
        attachStateRequest,
        detachStateRequest,
    } = useNewCampaignSchedule(id);
    const { start, isLoading, error, reset, requestState } = useAPIClientRequest(
        ApiClient.Entities.Campaign.addDisplay,
    );

    const {
        start: removeStart,
        isLoading: removeLoading,
        error: removeError,
        reset: removeReset,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.removeDisplay);
    const {
        start: downloadStart,
        data,
        isLoading: downloadLoading,
        error: downloadError,
        reset: downloadReset,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.displaysDownload);
    const uploadIds = useMemo(() => idsToUpload.split('\n'), [idsToUpload]);
    const closeUploadDialog = useCallback(() => {
        frameIdUploadState.setFalse();
        setIdsToUpload('');
        reset();
    }, [frameIdUploadState, reset]);
    const closeRemoveDialog = useCallback(() => {
        displayRemoveState.setFalse();
        removeReset();
    }, [displayRemoveState, removeReset]);
    const removeDisplayCall = useCallback(async () => {
        const [err, response] = await to(
            removeStart(id, { display_ids: selectedDisplays.map(display => display.id) }),
        );
        if (!err) {
            setSelectedDisplays([]);
            closeRemoveDialog();
            getDisplaysList(displayFiltersValues);
        }
    }, [
        removeStart,
        id,
        selectedDisplays,
        setSelectedDisplays,
        closeRemoveDialog,
        getDisplaysList,
        displayFiltersValues,
    ]);

    const addDisplays = useCallback(async () => {
        const [err] = await to(
            start(id, {
                identifier: uploadIds,
                identifier_type: isFrameIdUpload.value ? 'frame_id' : 'display_id',
            }),
        );
        if (!err) {
            setTimeout(() => {
                closeUploadDialog();
                getDisplaysList(displayFiltersValues);
            }, 2000);
        }
    }, [
        start,
        id,
        uploadIds,
        isFrameIdUpload.value,
        closeUploadDialog,
        getDisplaysList,
        displayFiltersValues,
    ]);
    const toggleSelection = useCallback(
        row => {
            setSelectedDisplays(prev => {
                const isSelected = prev.some(display => display.id === row.id);
                return isSelected ? prev.filter(display => display.id !== row.id) : [...prev, row];
            });
        },
        [setSelectedDisplays],
    );

    const allSelected = useMemo(() => {
        if (displayData.length === 0) return false;
        return displayData.every(row => selectedDisplays.some(display => display.id === row.id));
    }, [selectedDisplays, displayData]);

    const toggleSelectAll = useCallback(() => {
        const currentlyDisplayedIds = displayData.map(row => row.id);

        setSelectedDisplays(prev => {
            const isAllSelected = currentlyDisplayedIds.every(id =>
                prev.some(display => display.id === id),
            );

            return isAllSelected
                ? prev.filter(display => !currentlyDisplayedIds.includes(display.id))
                : [...prev, ...displayData.filter(row => !prev.some(d => d.id === row.id))];
        });
    }, [displayData, setSelectedDisplays]);

    const columns = useMemo(
        () => [
            {
                field: 'select',
                headerName: '',
                width: 50,
                sortable: false,
                renderHeader: () => (
                    <Checkbox
                        checked={allSelected}
                        indeterminate={
                            selectedDisplays.length > 0 &&
                            displayData.some(
                                row => !selectedDisplays.some(display => display.id === row.id),
                            )
                        }
                        onChange={toggleSelectAll}
                    />
                ),
                renderCell: ({ row }) => (
                    <Checkbox
                        checked={selectedDisplays.some(display => display.id === row.id)}
                        onChange={() => toggleSelection(row)}
                    />
                ),
            },
            {
                field: 'status',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.status'),
                width: 60,
                flex: 1,
                renderCell: ({ row }) =>
                    row.status == 1
                        ? t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.active')
                        : t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.inactive'),
            },
            {
                field: 'id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.id'),
                width: 90,
            },
            {
                field: 'frame_id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.frameId'),
                width: 120,
            },
            {
                field: 'mediaOwners',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.mediaOwners'),
                renderCell: ({ row }) => row?.mediaOwners?.map(owner => owner?.name).join(', '),
                flex: 1,
            },
            {
                field: 'Packs',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.packs'),
                renderCell: ({ row }) => row?.packs?.map(pack => pack?.name).join(', '),
                flex: 1,
            },
            {
                field: 'sov',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.sov'),
                flex: 1,
            },
            {
                field: 'slot_length',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.slotLength'),
                flex: 1,
            },
            {
                field: 'address',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.address'),
                flex: 1,
            },
            {
                field: 'size',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.size'),
                flex: 1,
            },
            {
                field: 'tv_region',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.tvRegion'),
                flex: 1,
            },
            {
                field: 'env',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.env'),
                flex: 1,
            },
            {
                field: 'schedules',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.displaysTable.schedule'),
                minWidth: 550,
                sortable: false,
                renderCell: ({ row }) => {
                    const id = row.id;
                    const type = row.type;
                    const schedules = row.schedules;
                    return (
                        <Box>
                            {row.schedules.map((schedule: ScheduleGet) => {
                                if (schedule.is_file) {
                                    return (
                                        <Chip
                                            key={schedule.id}
                                            onClick={() => [
                                                setExpandedScheduleId(schedule.id),
                                                setScheduleSearch(schedule.name),
                                            ]}
                                            label={schedule.name}
                                        />
                                    );
                                }
                                if (schedule.name === 'Default') {
                                    return (
                                        <Chip
                                            key={schedule.id}
                                            onClick={() => [
                                                setExpandedScheduleId(schedule.id),
                                                setScheduleSearch(schedule.name),
                                            ]}
                                            label={schedule.name}
                                        />
                                    );
                                }
                                return (
                                    <Chip
                                        key={schedule.id}
                                        onDelete={() => handleDetach(id, type, [schedule])}
                                        onClick={() => [
                                            setExpandedScheduleId(schedule.id),
                                            setScheduleSearch(schedule.name),
                                        ]}
                                        label={schedule.name}
                                    />
                                );
                            })}
                            <Button
                                variant="text"
                                onClick={() => handleDetach(id, type, schedules)}
                            >
                                {t('Modules.Main.Campaigns.CampaignSchedule.table.clearAll')}
                            </Button>
                        </Box>
                    );
                },
            },
        ],
        [
            allSelected,
            displayData,
            handleDetach,
            selectedDisplays,
            setExpandedScheduleId,
            setScheduleSearch,
            t,
            toggleSelectAll,
            toggleSelection,
        ],
    );
    const rows = useMemo(() => displayData, [displayData]);
    const downloadCall = useCallback(async () => {
        const [err, response] = await to(downloadStart(id, displayFiltersValues));
    }, [displayFiltersValues, downloadStart, id]);
    useEffect(() => {
        if (data?.data?.message) {
            setTimeout(() => {
                downloadReset();
            }, 3000);
        }
    }, [data, downloadReset]);
    useEffect(() => {
        if (detachRequestState === NetworkRequestState.Success) {
            getDisplaysList();
        }
        if (detachStateRequest === NetworkRequestState.Success) {
            getDisplaysList();
        }
        if (attachStateRequest === NetworkRequestState.Success) {
            getDisplaysList();
        }
    }, [attachStateRequest, detachRequestState, detachStateRequest, getDisplaysList]);

    return (
        <>
            <AutoGrid pt={2} gap={2} pb={2}>
                <Button variant="contained" onClick={frameIdUploadState.setTrue}>
                    {t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.uploadIds')}
                </Button>
                <Button
                    variant="contained"
                    onClick={displayRemoveState.setTrue}
                    disabled={selectedDisplays.length === 0}
                >
                    {t(
                        'Modules.Main.Campaigns.CampaignSchedule.displaysTab.removeDisplaysOrFrames',
                    )}
                </Button>
                {!data?.data?.message && (
                    <Button onClick={downloadCall}>
                        {downloadError ? (
                            String(downloadError)
                        ) : downloadLoading ? (
                            <CircularProgress />
                        ) : (
                            t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.download')
                        )}
                    </Button>
                )}
                {data?.data?.message && String(data?.data?.message)}
            </AutoGrid>

            <DialogV2
                onClose={closeUploadDialog}
                open={frameIdUploadState.value}
                title={t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.uploads')}
            >
                <ToggleButton
                    value={isFrameIdUpload}
                    onChange={isFrameIdUpload.toggle}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress />
                    ) : isFrameIdUpload.value ? (
                        t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.frameIds')
                    ) : (
                        t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.displaysIds')
                    )}
                </ToggleButton>
                <TextAreaField
                    multiline
                    fullWidth
                    rows={5}
                    value={idsToUpload}
                    onChange={e => setIdsToUpload(e.target.value)}
                    variant="outlined"
                    helperText={`${
                        isFrameIdUpload.value
                            ? t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.frameIds')
                            : t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.displaysIds')
                    }: ${idsToUpload.split('\n').filter(str => str.trim() !== '').length}`}
                    showCharacterCount={false}
                />
                {requestState !== NetworkRequestState.Success && (
                    <Button
                        onClick={addDisplays}
                        variant="contained"
                        disabled={
                            uploadIds.filter(str => str.trim() !== '').length === 0 || isLoading
                        }
                    >
                        {t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.add')}{' '}
                        {removeLoading ? (
                            <CircularProgress />
                        ) : isFrameIdUpload.value ? (
                            t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.frameIds')
                        ) : (
                            t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.displaysIds')
                        )}
                    </Button>
                )}
                {requestState === NetworkRequestState.Success && (
                    <Alert severity="success" variant="outlined">
                        {t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.success')}
                    </Alert>
                )}
                {error && (
                    <Alert severity="error" variant="outlined">
                        {String(error)}
                    </Alert>
                )}
            </DialogV2>
            <DialogV2
                onClose={closeRemoveDialog}
                open={displayRemoveState.value}
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.displaysTab.removeSelectedFramesOrDisplays',
                )}
            >
                <>
                    <Button onClick={removeDisplayCall} variant="contained">
                        {t('Modules.Main.Campaigns.CampaignSchedule.displaysTab.remove')}
                    </Button>
                    {removeError && (
                        <Alert severity="error" variant="outlined">
                            {String(removeError)}
                        </Alert>
                    )}
                </>
            </DialogV2>
            <StandaloneFilters
                filters={displayFilters}
                onApply={filters => {
                    getDisplaysList(filters);
                }}
                initialFilterValues={displayFiltersValues}
            />
            <DataGridPro
                key={rows.length}
                columns={columns}
                rows={rows}
                loading={displayLoading}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'status', sort: 'asc' }],
                    },
                }}
            />
        </>
    );
};

export default DisplaysTab;
