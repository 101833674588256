import React, { forwardRef, useRef } from 'react';
import { Slider, Box } from '@mui/material';

const PannableSlider = ({
    value,
    onChange,
    min,
    max,
    step = 0.00001,
    getLabelForValue,
}: {
    value: [number, number];
    onChange: (newValue: [number, number]) => void;
    min: number;
    max: number;
    step?: number;
    getLabelForValue?: (index: number) => string;
}) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const dragStartX = useRef<number | null>(null);
    const initialRange = useRef<[number, number]>(value);

    const handleMouseDown = (e: React.MouseEvent) => {
        dragStartX.current = e.clientX;
        initialRange.current = [...value];
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (dragStartX.current === null || !sliderRef.current) return;

        const dx = e.clientX - dragStartX.current;
        const sliderWidth = sliderRef.current.clientWidth;
        const stepRatio = (max - min) / sliderWidth;
        const delta = dx * stepRatio;

        let newStart = Math.round(initialRange.current[0] + delta);
        let newEnd = Math.round(initialRange.current[1] + delta);

        if (newStart < min) {
            newEnd += min - newStart;
            newStart = min;
        }
        if (newEnd > max) {
            newStart -= newEnd - max;
            newEnd = max;
        }

        if (newStart >= min && newEnd <= max) {
            onChange([newStart, newEnd]);
        }
    };

    const handleMouseUp = () => {
        dragStartX.current = null;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        const rounded: [number, number] = [Math.round(value[0]), Math.round(value[1])];
        onChange(rounded);
    };

    return (
        <Box position="relative" ref={sliderRef}>
            <Slider
                value={value}
                onChange={(_, newValue) => onChange(newValue as [number, number])}
                valueLabelDisplay="auto"
                valueLabelFormat={(v, i) => {
                    const isStartThumb = i === 0;
                    const clamped = isStartThumb
                        ? Math.max(min, Math.min(max, Math.floor(v)))
                        : Math.max(min, Math.min(max, Math.ceil(v)));

                    return getLabelForValue?.(clamped) ?? '';
                }}
                min={min}
                max={max}
                step={step}
                disableSwap
                sx={{
                    height: 24,
                    padding: 0,
                    '& .MuiSlider-rail': {
                        height: '100%',
                        border: '1px solid #555',
                        borderRadius: 0,
                        color: 'white',
                    },
                    '& .MuiSlider-track': {
                        height: '100%',
                        borderLeft: '4px solid #1e3a8a',
                        opacity: 0.3,
                        borderRadius: 0,
                    },
                    '& .MuiSlider-thumb': {
                        height: '100%',
                        width: '6px',
                        borderRadius: 0,
                        backgroundColor: 'primary.main',
                        cursor: 'col-resize',
                        marginTop: 0,
                        boxShadow: 'none',
                    },
                    '& .MuiSlider-valueLabel': {
                        background: 'transparent',
                        color: 'text.primary',
                        fontSize: 12,
                        top: -30,
                    },
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    height: '100%',
                    left: `${((value[0] - min) / (max - min)) * 100}%`,
                    width: `${((value[1] - value[0]) / (max - min)) * 100}%`,
                    zIndex: 2,
                    display: 'flex',
                }}
            >
                {/* Left grip zone */}
                <Box
                    sx={{
                        width: '8px',
                        cursor: 'col-resize',
                    }}
                />

                {/* Center draggable area */}
                <Box
                    sx={{
                        flex: 1,
                        cursor: 'move',
                    }}
                    onMouseDown={handleMouseDown}
                />

                {/* Right grip zone */}
                <Box
                    sx={{
                        width: '8px',
                        cursor: 'col-resize',
                    }}
                />
            </Box>
        </Box>
    );
};

export default forwardRef(PannableSlider);
