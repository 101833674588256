import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { TextAreaField } from 'c-components';

import { useDebouncedCallback } from 'use-debounce';
import { useCommonTranslation } from 'c-translation';
import DropdownFilter from '../EntityFilters/DropdownFilter';

type Props = {
    filters: any[];
    onApply: (filters: any) => void;
    initialFilterValues?: any;
};

const StandaloneFilters: React.FC<Props> = ({ filters, onApply, initialFilterValues }) => {
    const [filterValues, setFilterValues] = useState(initialFilterValues);
    const [frameIdInput, setFrameIdInput] = useState('');
    const t = useCommonTranslation();

    const validFilters = useMemo(
        () =>
            filters
                .filter(filter => filter.expose === true)
                .map(filter => ({
                    ...filter,
                    options: Array.isArray(filter.options) ? filter.options : [],
                })),
        [filters],
    );

    const sortedOptions = (options = []) => {
        if (!Array.isArray(options)) return [];
        return [...options].sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));
    };
    const handleFilterChange = useCallback((key, val) => {
        setFilterValues(prev => ({
            ...prev,
            [key]: val,
        }));
    }, []);

    useEffect(() => {
        setFilterValues(prev => ({
            ...prev,
            'filter.frame_id': frameIdInput
                .split('\n')
                .map(id => id.trim())
                .filter(id => id),
        }));
    }, [frameIdInput]);

    const debouncedApplyFilters = useDebouncedCallback(() => {
        onApply(filterValues);
    }, 500);
    useEffect(() => {
        debouncedApplyFilters();
    }, [filterValues, debouncedApplyFilters]);
    const allFilterComponents = useMemo(
        () => (
            <Grid container spacing={1} alignItems="center" wrap="wrap" pt={1}>
                {validFilters.map(filter => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={filter.key}>
                        <Box
                            sx={{
                                bgcolor: 'white',
                                borderRadius: '8px',
                            }}
                        >
                            <DropdownFilter
                                config={{
                                    ...filter,
                                    options: sortedOptions(filter.options).map(opt => ({
                                        label: opt.name,
                                        value: opt.value,
                                        disabled: opt.disabled ?? false,
                                    })),
                                }}
                                value={filterValues[filter.key] || []}
                                onChange={val => handleFilterChange(filter.key, val)}
                                id={`filter-${filter.key}`}
                                className={`filter-${filter.key}`}
                                count={filterValues[filter.key]?.length || 0}
                            />
                        </Box>
                    </Grid>
                ))}

                {filters.length > 0 && (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Box sx={{ width: '100%' }}>
                            <TextAreaField
                                placeholder={t(
                                    'Modules.Main.Campaigns.CampaignSchedule.displaysTab.textAreaPlaceholder',
                                )}
                                multiline
                                fullWidth
                                rows={3}
                                value={frameIdInput}
                                onChange={e => setFrameIdInput(e.target.value)}
                                variant="outlined"
                                helperText={`${t(
                                    'Modules.Main.Campaigns.CampaignSchedule.displaysTab.frameIds',
                                )}: ${
                                    frameIdInput.split('\n').filter(str => str.trim() !== '').length
                                }`}
                                showCharacterCount={false}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        ),
        [validFilters, filters.length, t, frameIdInput, filterValues, handleFilterChange],
    );
    return <>{allFilterComponents}</>;
};

export default StandaloneFilters;
