import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName } from 'c-sdk';
import {
    FilterableEntityTable,
    FilterableEntityTableProps,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    RenderRowProps,
} from 'c-pagination';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { displaysListIcon } from 'c-main/icons';
import { displayScreenColumns } from 'c-displays/Components/DisplaysEntityColumns';
import ReactGA from 'react-ga';
import { useCommonTranslation } from 'c-translation';
import { alpha, Box, CircularProgress, FormLabel, Stack, Switch, Typography } from '@mui/material';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    Checkbox,
    CreateEntityButton,
    DialogV2,
    SelectField,
    TextAreaField,
} from 'c-components';
import { Controller, useForm } from 'react-hook-form';
import { useBoolean } from 'react-hanger';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { NetworkRequestState } from 'c-data-layer';
import { CheckCircle } from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosResponse } from 'axios';
import { useTheme } from '@mui/styles';
import { MassUpdatingDisplays } from 'c-displays/Pages/utils';
import { usePaginatedEntityData } from 'c-data';
import { uniq } from 'ramda';
import ManualAudienceForm from './DisplaysListPageComponents/ManualAudienceForm';
import NewScreenForm from './DisplaysListPageComponents/NewScreenForm';

const textSearchCols = ['location', 'vendor_id', 'address', 'channel', 'lat', 'long'];
const paginationTag = 'displays-admin-displays-list';

const downloadTemplate = () => {
    const csvContent = `Frame Id,Month,Day of the Week,Hour,Estimated Hourly Impacts,Route Version
1234931263,1,Monday,7,0.088505842,48.1.1
1234931263,1,Monday,8,0.149232592,48.1.1
1234931263,1,Monday,9,0.27870812,48.1.1`;

    try {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'frame_template.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        console.log('Failed to download template:', error);
    }
};

const DisplaysListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const [ids, setIds] = useState([]);
    const responseDialog = useBoolean(false);
    const [override, setOverride] = useState(false);
    const [error, setError] = useState<any | null>(null);
    const [allocationResponse, setResponse] = useState<AxiosResponse<unknown> | null>(null);
    const [conflictedIds, setConflictedIds] = useState<number[] | null>(null);
    const kartFilter = useBoolean(false);
    const filtered = useBoolean(false);
    const massAllocationDialogState = useBoolean(false);
    const [deletedIds, setDeletedIds] = useState([]);
    const t = useCommonTranslation();
    const { paginatedData } = usePaginatedEntityData(paginationTag, 'Displays_Screen');
    const [frameIds, setFrameIds] = useState('');
    const paginatedIds = useMemo(
        () => paginatedData?.data.filter(id => !deletedIds.includes(id)),
        [paginatedData, deletedIds],
    );
    const filteringDisplayId = useBoolean(false);
    const filters = useMemo(() => paginatedData?.filters, [paginatedData]);
    const dialogState = useBoolean(false);
    const allocationState = useBoolean(false);

    const {
        start,
        requestState,
        reset: requestReset,
    } = useAPIClientRequest(ApiClient.Entities.Displays_Screen.allocateAndDisAllocate);
    const tableRef = useRef<FilterableEntityTableRefAPI>();
    useEffect(() => {
        if (tableRef?.current) {
            tableRef.current.search(1);
        }
        if ((allocationResponse as any)?.data?.success === true) {
            tableRef.current.search(1);
        }
        if (frameIds) {
            tableRef.current.search(1);
        }
    }, [kartFilter.value, allocationResponse, frameIds]);
    const { handleSubmit, control, watch, reset } = useForm({});
    const kartButton = useMemo(
        () => (
            <Button size="small" disabled={ids.length === 0} onClick={kartFilter.toggle}>
                {kartFilter.value === false
                    ? t('Modules.Displays.DisplaysList.allocationDialog.viewSelected')
                    : t('Modules.Displays.DisplaysList.allocationDialog.viewAll')}
            </Button>
        ),
        [kartFilter, t, ids],
    );
    const clearSelectedButton = useMemo(
        () => (
            <Button size="small" disabled={ids.length === 0} onClick={() => setIds([])}>
                {t('Modules.Displays.DisplaysList.clearSelected')}
            </Button>
        ),
        [ids.length, t],
    );
    const allocationType = watch('allocation_to_type');
    const typeOfAllocation = watch('allocation_type');
    const screenToAllocateType = watch('screen_to_allocate_type');
    const handleFormSubmit = useCallback(
        async data => {
            filtered.setFalse();
            const dataToSubmit = { ...data };
            if (dataToSubmit.screen_to_allocate_type === 'selected') {
                delete dataToSubmit.filter;
                dataToSubmit.display_ids = ids;
            } else {
                delete dataToSubmit.display_ids;
                if (filters) {
                    Object.assign(dataToSubmit, filters);
                }
            }
            if (allocationType === 'packs') {
                dataToSubmit.allocation_to_type_id = dataToSubmit.allocation_to_type_id_packs;
            }
            if (allocationType === 'tags') {
                dataToSubmit.allocation_to_type_id = dataToSubmit.allocation_to_type_id_tags;
            }
            dataToSubmit.allow_override = override;
            const [err, response] = await to(start(dataToSubmit));
            setError(err);
            setResponse(response);
            setIds([]);
            if ((response as any).data.success === false) {
                responseDialog.setTrue();
            }
        },
        [allocationType, override, start, ids, filters, responseDialog, filtered],
    );
    const openAllocationDialog = useCallback(() => {
        filtered.setFalse();
        setConflictedIds(null);
        allocationState.setTrue();
    }, [filtered, allocationState, setConflictedIds]);
    const yesClicked = useCallback(() => {
        filtered.setFalse();
        requestReset();
        setOverride(true);
        responseDialog.setFalse();
    }, [responseDialog, setOverride, requestReset, filtered]);
    const noClicked = useCallback(() => {
        if ((allocationResponse as any)?.data?.conflicts?.data?.length > 0) {
            setConflictedIds(
                (allocationResponse as any)?.data?.conflicts?.data?.map(conflict => conflict.id),
            );
        }
        filtered.setTrue();
        kartFilter.setTrue();
        responseDialog.setFalse();
        allocationState.setFalse();
        reset();
        requestReset();
    }, [
        kartFilter,
        responseDialog,
        allocationState,
        reset,
        requestReset,
        filtered,
        setConflictedIds,
        allocationResponse,
    ]);
    const uniqueIds = useMemo(
        () =>
            uniq(
                frameIds
                    .split('\n')
                    ?.map(id => id.trim())
                    .filter(id => id.length > 0),
            ),
        [frameIds],
    );
    const submitButtonDisabler = useMemo(() => {
        if (requestState === NetworkRequestState.InProgress) {
            return true;
        }
        if (typeOfAllocation == null) return true;
        if (screenToAllocateType == null) return true;

        if (screenToAllocateType === 'selected') {
            if (ids.length < 1) return true;
        }
        if (allocationType == null) return true;
        if (screenToAllocateType === 'filtered') {
            if (filters != null) {
                if (Object.keys(filters).length === 0) {
                    return true;
                }
                return false;
            }
        }

        return false;
    }, [requestState, screenToAllocateType, typeOfAllocation, ids, filters, allocationType]);
    const closeDialog = useCallback(() => {
        setConflictedIds(null);
        filtered.setFalse();
        responseDialog.setFalse();
        allocationState.setFalse();
        reset();
        requestReset();
    }, [responseDialog, allocationState, reset, requestReset, filtered, setConflictedIds]);
    const massAllocationDialog = useMemo(
        () => (
            <>
                <DialogV2
                    onClose={() => massAllocationDialogState.setFalse()}
                    open={massAllocationDialogState.value}
                    title="Modules.Displays.DisplaysList.massUpdating"
                >
                    <MassUpdatingDisplays
                        location="Displays_Screen"
                        dialogState={massAllocationDialogState}
                    />
                </DialogV2>
                <Button size="small" onClick={() => massAllocationDialogState.setTrue()}>
                    {t('Modules.Displays.DisplaysList.massUpdating')}
                </Button>
            </>
        ),
        [massAllocationDialogState, t],
    );
    const allocationDialog = useMemo(
        () => (
            <>
                <DialogV2
                    onClose={closeDialog}
                    open={allocationState.value}
                    maxWidth="xl"
                    title="Modules.Displays.DisplaysList.allocationDialog.title"
                >
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <AutoGrid xs={5} gap={1}>
                            <Controller
                                name="allocation_type"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysList.allocationDialog.allocationType',
                                            )}
                                        </FormLabel>
                                        <SelectField
                                            value={field.value}
                                            options={[
                                                {
                                                    value: 'allocate',
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.allocate',
                                                    ),
                                                },
                                                {
                                                    value: 'deallocate',
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.deallocate',
                                                    ),
                                                },
                                                {
                                                    value: null,
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.empty',
                                                    ),
                                                },
                                            ]}
                                            {...field}
                                        />
                                    </>
                                )}
                            />
                            <Controller
                                name="screen_to_allocate_type"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysList.allocationDialog.screensToAllocate',
                                            )}
                                        </FormLabel>
                                        <SelectField
                                            value={field.value}
                                            options={[
                                                {
                                                    value: 'selected',
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.selected',
                                                    ),
                                                },
                                                {
                                                    value: 'filtered',
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.filtered',
                                                    ),
                                                },
                                                {
                                                    value: null,
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.empty',
                                                    ),
                                                },
                                            ]}
                                            {...field}
                                        />
                                    </>
                                )}
                            />
                            <Controller
                                name="allocation_to_type"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysList.allocationDialog.allocateTo',
                                            )}
                                        </FormLabel>
                                        <SelectField
                                            value={field.value}
                                            options={[
                                                {
                                                    value: 'packs',
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.packs',
                                                    ),
                                                },
                                                {
                                                    value: 'tags',
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.tags',
                                                    ),
                                                },
                                                {
                                                    value: null,
                                                    label: t(
                                                        'Modules.Displays.DisplaysList.allocationDialog.empty',
                                                    ),
                                                },
                                            ]}
                                            {...field}
                                        />
                                    </>
                                )}
                            />
                            <Controller
                                name="allocation_to_type_id_packs"
                                disabled={
                                    allocationType !== 'packs' || typeOfAllocation !== 'allocate'
                                }
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysList.allocationDialog.packs',
                                            )}
                                        </FormLabel>
                                        <AllEntitiesDropdown
                                            entityName="Displays_Pack"
                                            labelField="name"
                                            useLocalEntityData
                                            {...field}
                                        />
                                    </>
                                )}
                            />
                            <Controller
                                name="allocation_to_type_id_tags"
                                disabled={
                                    allocationType !== 'tags' || typeOfAllocation !== 'allocate'
                                }
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysList.allocationDialog.tags',
                                            )}
                                        </FormLabel>
                                        <AllEntitiesDropdown
                                            entityName="Displays_Tag"
                                            labelField="name"
                                            useLocalEntityData
                                            {...field}
                                        />
                                    </>
                                )}
                            />
                        </AutoGrid>
                        <Checkbox
                            label={t(
                                'Modules.Displays.DisplaysList.allocationDialog.allowOverride',
                            )}
                            checked={override}
                            onClick={() => setOverride(prevState => !prevState)}
                        />
                        <Box pt={2}>
                            <Box>
                                {requestState === NetworkRequestState.Error && (
                                    <Alert severity="error">{String(error)}</Alert>
                                )}
                                {requestState === NetworkRequestState.InProgress && (
                                    <CircularProgress size={25} />
                                )}
                                {requestState === NetworkRequestState.Success && (
                                    <Typography color="success.main">
                                        <CheckCircle />
                                    </Typography>
                                )}
                            </Box>
                            {responseDialog.value && (
                                <Alert severity="warning">
                                    {t('Modules.Displays.DisplaysList.allocationDialog.conflicts', {
                                        name: allocationType,
                                    })}
                                    <Box display="flex" justifyContent="center">
                                        <Stack gap={1} direction="row">
                                            <Button onClick={yesClicked}>
                                                {t(
                                                    'Modules.Displays.DisplaysList.allocationDialog.override',
                                                )}
                                            </Button>
                                            <Button onClick={noClicked}>
                                                {t(
                                                    'Modules.Displays.DisplaysList.allocationDialog.back',
                                                )}
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Alert>
                            )}
                            {!responseDialog.value && (
                                <Button type="submit" disabled={submitButtonDisabler}>
                                    {t('Modules.Displays.DisplaysList.allocationDialog.submitBtn')}
                                </Button>
                            )}
                        </Box>
                    </form>
                </DialogV2>
                <Button size="small" onClick={openAllocationDialog}>
                    {t('Modules.Displays.DisplaysList.allocation')}
                </Button>
            </>
        ),
        [
            closeDialog,
            allocationState,
            handleSubmit,
            handleFormSubmit,
            control,
            allocationType,
            typeOfAllocation,
            t,
            override,
            requestState,
            error,
            responseDialog,
            yesClicked,
            noClicked,
            submitButtonDisabler,
            openAllocationDialog,
        ],
    );
    const theme = useTheme();
    const generateSx = useCallback<RenderRowProps<any>['generateSx']>(
        (row, id) => {
            const resp = (allocationResponse as any)?.data?.conflicts?.data.map(id => id.id) ?? [];
            if (deletedIds.includes(id)) {
                return { opacity: 0.25 };
            }
            const exists = ids.includes(id);
            const showColor = exists ? resp?.includes(id) : false;
            if (!showColor) return {};

            return {
                bgcolor: alpha(theme.palette.error.light, 0.25),
            };
        },
        [theme, allocationResponse, ids, deletedIds],
    );
    const manualAudienceDialog = useMemo(
        () => (
            <>
                <DialogV2
                    onClose={dialogState.setFalse}
                    open={dialogState.value}
                    title="Modules.Displays.DisplaysList.uploadManualAudience"
                >
                    <Stack gap={1}>
                        <Button onClick={downloadTemplate}>
                            {t('Modules.Displays.DisplaysList.downloadTemplate')}
                        </Button>
                        <ManualAudienceForm dialogState={dialogState} />
                    </Stack>
                </DialogV2>
                <Button size="small" onClick={dialogState.setTrue}>
                    {t('Modules.Displays.DisplaysList.uploadManualAudience')}
                </Button>
            </>
        ),
        [dialogState, t],
    );
    const updateSearchPayload = useCallback<FilterableEntityTableProps['updateSearchPayload']>(
        payload => ({
            ...payload,
            filters: {
                ...payload.filters,
                ...(filteringDisplayId.value
                    ? {
                          'filter.id': filtered.value
                              ? conflictedIds
                              : filteringDisplayId.value
                              ? uniqueIds
                              : kartFilter.value
                              ? ids
                              : [],
                      }
                    : {
                          'filter.frame_id': uniqueIds,
                          'filter.id': filtered.value ? conflictedIds : kartFilter.value ? ids : [],
                      }),
            },
        }),
        [ids, kartFilter.value, filtered.value, conflictedIds, uniqueIds, filteringDisplayId.value],
    );

    useEffect(() => {
        if (ids.length === 0) {
            kartFilter.setFalse();
        }
    }, [ids.length, kartFilter]);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => setFrameIds(e.target.value),
        [],
    );
    return (
        <FullPageEntityTableWrapper
            title="Modules.Displays.DisplaysList.metaTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton
                        entityName="Displays_Screen"
                        title={t('Modules.Displays.DisplaysList.createButton')}
                        form={<NewScreenForm />}
                        key="new-screen-button"
                        buttonLabel={t('Modules.Displays.DisplaysList.createButton')}
                        permissions={[PermissionName.DisplaysScreensCreate]}
                        redirectPath={PostAuthRoutes.Displays.DisplaysDisplayEdit}
                        maxWidth="xl"
                    />,
                    massAllocationDialog,
                    manualAudienceDialog,
                    allocationDialog,
                    kartButton,
                    clearSelectedButton,
                ],
                [
                    allocationDialog,
                    manualAudienceDialog,
                    t,
                    kartButton,
                    massAllocationDialog,
                    clearSelectedButton,
                ],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                ref={tableRef}
                columns={displayScreenColumns(setIds, ids, paginatedIds, setDeletedIds, deletedIds)}
                baseEntityName="Displays_Screen"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                revertToIdSearchOnNumberOnlyInput={false}
                orderBy="id"
                direction="desc"
                // onRowClick={id => {
                //     if (!deletedIds.includes(id)) {
                //         const rout = generatePath(PostAuthRoutes.Displays.DisplaysDisplayEdit, {
                //             id,
                //         });
                //         $history.push(rout);
                //     }
                // }}
                showFilters
                customFilters={useMemo(
                    () => [
                        <Stack key="frameIdFilter">
                            <Stack direction="row">
                                <Switch
                                    value={filteringDisplayId.value}
                                    onClick={filteringDisplayId.toggle}
                                />
                                <Typography variant="body2" color="textSecondary">
                                    {t('Modules.Displays.DisplaysList.customFilters.toggle')}
                                </Typography>
                            </Stack>
                            <TextAreaField
                                value={frameIds}
                                onChange={onChange}
                                placeholder={
                                    filteringDisplayId.value
                                        ? t(
                                              'Modules.Displays.DisplaysList.customFilters.displayIds',
                                          )
                                        : t('Modules.Displays.DisplaysList.customFilters.frameIds')
                                }
                                showCharacterCount={false}
                            />
                            {uniqueIds?.length > 0 && (
                                <Typography fontSize="small" align="right">
                                    {filteringDisplayId.value
                                        ? t(
                                              'Modules.Displays.DisplaysList.customFilters.displaysCount',
                                              {
                                                  count: uniqueIds?.length,
                                              },
                                          )
                                        : t(
                                              'Modules.Displays.DisplaysList.customFilters.frameCount',
                                              {
                                                  count: uniqueIds?.length,
                                              },
                                          )}
                                </Typography>
                            )}
                        </Stack>,
                    ],
                    [filteringDisplayId, frameIds, onChange, t, uniqueIds?.length],
                )}
                customFilterResets={[() => setFrameIds('')]}
                generateRowSx={generateSx}
                updateSearchPayload={updateSearchPayload}
                displayDownloadButton
                reImportButton
                filterApplyButton
                includes={[
                    'energy_conversion_override',
                    'energy_uses_per_second',
                    'fileTypes',
                    'tags',
                    'operationalTimes',
                    'packs',
                    'mediaOwners',
                    'markets',
                    'resolutions',
                    'displaysVendorIds',
                    'owner',
                    'softDeleted',
                ]}
                disableCache
            />
        </FullPageEntityTableWrapper>
    );
};

const setup: PageConfig = {
    id: 'DisplaysListPage',
    component: DisplaysListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Displays.DisplaysList.metaTitle',
        description: 'Modules.Displays.DisplaysList.description',
        icon: displaysListIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.displaysAdminDisplayList',
        route: PostAuthRoutes.Displays.DisplaysList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.DisplaysList.metaTitle',
            },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
