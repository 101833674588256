import React, { ReactNode } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies

import {
    BarProps,
    BrushProps,
    CellProps,
    LineProps,
    PieProps,
    TooltipProps,
    XAxisProps,
    YAxisProps,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { BoxProps, TableCellProps, TableHeadProps } from '@mui/material';
import { NetworkRequest } from 'c-data-layer';
import { AxiosRequestData } from 'c-sdk';
import { TableRowProps } from '@mui/material';

export type ReportWrapperRefAPI = {
    refreshData: () => void;
    setDates: (timeframe: ReportDateTimeframe, startDate?: Date, endDate?: Date) => void;
};

export enum ReportType {
    Table,
    Line,
    Bar,
    Pie,
    Mixed,
    Custom,
}

export enum PartType {
    Line,
    Bar,
}

export enum ReportDateTimeframe {
    Daily,
    Weekly,
    Monthly,
    Range,

    /**
     * You will never get an on change event for this enum value.
     * It's purely a shortcut for selecting the maximum date range
     * which then sets the timeframe to ReportDateTimeframe.Range
     */
    All,
    BiWeekly,
    AllCampaignDates,
}

export enum ReportWeekDay {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
}

export type ReportWrapperProps<DataStructure> = ReportConfig<DataStructure> & {
    initialStartDate: Date;
    initialEndDate: Date;
    wrapperProps?: BoxProps;
    reportWrapperProps?: BoxProps;
    reportChartWrapperProps?: BoxProps;
    additionalDateControls?: React.ReactNode;
    beforeChartComponent?: React.ReactNode;
    afterChartComponent?: React.ReactNode;
    additionalDateControlsPosition?: 'start' | 'end';
    ReportRender?: React.FC<{ reports: React.ReactNode[] }>;

    onLoadingStateUpdated?: (state: NetworkRequest) => void;

    /**
     * Allows you to separate data caching based on an arbitrary key
     */
    requestKeyPrefix?: string;
};

export type ReportConfig<DataStructure> = {
    fetchData: (start: Date, end: Date, timeframe: ReportDateTimeframe) => AxiosRequestData<any>;

    /* Listen to when data is returned */
    onDataUpdated?: (
        data: DataStructure,
        start: Date,
        end: Date,
        timeframe: ReportDateTimeframe,
    ) => void;

    dateSettings?: ReportDateSettings;

    /**
     * Props for reports in the order you want to render them
     */
    reportProps?: ReportProps<DataStructure>[];
};

export type TableOrChartProps<DataStructure> =
    | TableReportProps<DataStructure>
    | ChartReportProps<DataStructure>;

export type ReportProps<
    DataStructure,
    ReportProps = TableOrChartProps<DataStructure>,
> = ReportProps & {
    type: ReportType;
    namePrefix: string;
    RenderReport?: React.FC<ReportProps>;
};

export type ChartReportProps<DataStructure> = {
    chartProps?: CategoricalChartProps;
    brushProps?: Partial<BrushProps>;
    xAxisDataKey?: string;
    yAxisDataKey?: string;
    hideLegend?: boolean;
    hideTooltip?: boolean;
    hideBrush?: boolean;

    yAxisTickFormatterAsTime?: boolean;
    xAxisProps?: XAxisProps;
    yAxisProps?: YAxisProps;
    yAxisTickFormatter?: YAxisProps['tickFormatter'];

    parts: ChartReportPart[];
    data: any[];
    renderTooltipLabel?: (val: TooltipProps<any, any>) => JSX.Element;

    chartChildren?: React.ReactNode[];

    // use when rendering a pie chart. Required.
    pieProps?: Partial<PieProps>;

    lineChartProps?: {
        accumulative?: boolean;
    };
};

export type CustomBarProps = Omit<BarProps, 'dataKey'> & {
    /**
     * The key being the value of the data key of the bar.
     * For example, if you have 1 bar per day of the week (monday to sunday), you could use this to colour each one separately.
     * Value is the colour. hex or rgb(a)
     */
    customColors?: Record<string, string>;
};

export type ChartReportPart = {
    dataKey: string;
    labelOverride?: string;

    // mandatory when ReportType.Mixed
    partType?: PartType;

    pieCellProps?: Partial<CellProps>;

    // use when rendering a line chart
    lineProps?: Partial<LineProps>;

    // use when rendering a bar chart
    barProps?: CustomBarProps;
};

export type TableReportProps<DataStructure> = {
    // either provide headers or generateHeaders.
    headerStickers?: TableReportHeader[];
    headers: TableReportHeader[];
    rows: TableReportRow[];

    data: any[];

    /**
     * Default: false. (virtualized by default)
     *
     * Virtualising table results makes rendering large tables significantly more performant
     * If you have a short list or are precious about real HTML tables being rendered, set this to false.
     *
     * But be warned. Large (non virtualized) tables will perform badly.
     */
    disableVirtualization?: boolean;

    /**
     * Table should be densly layout out with little padding and margins where possible
     */
    dense?: boolean;
};

export type TableReportHeader = {
    content: ReactNode;
    tableCellProps?: TableCellProps;
    sx?: TableHeadProps['sx'];

    // for virtualized tables. See ReportTableWrapper.tsx.
    width?: number;
};

export type TableReportCell = {
    content: ReactNode;
    sx?: TableCellProps['sx'];
};

export type TableReportRow = {
    /**
     * The number of content children should match the number of headers
     */
    content: TableReportCell[];
    sx?: TableRowProps['sx'];
};

export type ReportDateSettings = {
    timeframe: ReportDateTimeframe;
    minDate?: Date;
    maxDate?: Date;

    /**
     * Set timeframes if you want users to be able to switch between them.
     */
    availableTimeframes?: ReportDateTimeframe[];

    /**
     * Week day number (1-7). Monday is 1, Sunday is 7. See https://date-fns.org/v2.25.0/docs/format
     *
     * Only applicable when ReportDateFilterType.Weekly
     */
    firstDayOfWeek?: ReportWeekDay;

    onDatesUpdated?: (timeframe: ReportDateTimeframe, start?: Date, end?: Date) => void;

    /**
     * show a button to go to the start of the date range
     */
    showGoStart?: boolean;

    /**
     * show a button to go to the end of the date range
     */
    showGoEnd?: boolean;
};
