import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PermissionName, ScheduleGet } from 'c-sdk';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
} from '@mui/material';
import {
    Alert,
    AutoGrid,
    Checkbox,
    FileUploadDropzone,
    JoinComponents,
    LoadingSpinner,
    TextField,
} from 'c-components';

import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useBoolean } from 'react-hanger';

import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import DialogV2 from 'c-components/DialogV2';
import { NetworkRequestState } from 'c-data-layer';
import { uploadIcon } from 'c-main/icons';
import SelectedLineItemsTable from 'c-main/Components/Schedule/Components/Schedule/SelectedLineItemsTable';
import { useCommonTranslation } from 'c-translation';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { Search } from '@mui/icons-material';
import { debounce } from 'lodash';

import NewScheduleButton from './NewScheduleButton';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import ScheduleListItem from './ScheduleListItem';

type Props = {
    id: number;
    onDelete: (id: number) => void;
    updateSchedules: (schedules: ScheduleGet[]) => void;
    fetchData: () => void;
};

const createPerms = [
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiRulesCreate,
];

const readListPerms = [PermissionName.UniliveapiSchedulesRead, PermissionName.UniliveapiRulesRead];
const sitelistUploadPerms = [PermissionName.UniliveapiSchedulesSitelist_upload];

const csvTemplate = `Display ID,Frame ID,Start Date,End Date,Start Hour,End Hour,SOV,Slot Length
60794,,01/01/2024,05/01/2024,0,24,0.1,
,2000139238,01/01/2024,05/01/2024,0,13,0.1,
`;

const handleDownload = () => {
    const blob = new Blob([csvTemplate], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `template.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

const ScheduleList: React.FC<Props> = ({ id, onDelete, updateSchedules, fetchData }) => {
    const [editId, setEditId] = useState<number>(null);
    const attachSchedule = useBoolean(false);
    const detachSchedule = useBoolean(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const resetEdit = useCallback(() => {
        setEditId(null);
    }, []);
    const {
        selected,
        setSelected,
        setSelectedScreens,
        selectedScreens,
        schedules,
        getScheduleLineItems,
        page,
        perPage,
        prevSelectedLineItemId,
        scheduleLoading,
        getSchedules,
        schedulesPerPage,
        schedulesCurrentPage,
        lineItemScheduleOptions,
        setAttachStateRequest,
        scheduleSearch: search,
        setScheduleSearch: setSearch,
        setDetachStateRequest,
        setExpandedScheduleId,
        selectedDisplays,
    } = useNewCampaignSchedule(id);
    const editSchedule = useMemo(
        () => (editId != null ? schedules?.find(s => s?.id === editId) : null),
        [schedules, editId],
    );
    const collapseAll = useBoolean(false);

    const onSearchChange = useCallback(
        e => {
            setSearch(e.target.value);
        },
        [setSearch],
    );
    useEffect(() => {
        const schedulesWithRefresh = schedules?.filter(
            (schedule: any) => schedule.scheduleFile?.data?.refresh === true,
        );

        if (schedulesWithRefresh?.length > 0) {
            const timer = setTimeout(() => {
                getSchedules(schedulesCurrentPage, schedulesPerPage, search);
            }, 60000);

            return () => clearTimeout(timer);
        }
        return undefined;
    }, [getSchedules, schedules, schedulesCurrentPage, schedulesPerPage, search]);

    const scheduleItems = useMemo(
        () => (
            <JoinComponents
                components={schedules?.map(schedule => (
                    <ScheduleListItem
                        key={schedule.id}
                        campaignId={id}
                        schedule={schedule}
                        onEdit={setEditId}
                        onDelete={onDelete}
                        onScreensClicked={() => attachSchedule.setTrue()}
                        onDetachClicked={() => detachSchedule.setTrue()}
                        setId={setSelectedSchedule}
                        collapseAll={collapseAll}
                    />
                ))}
                separator={<Divider />}
            />
        ),
        [schedules, id, onDelete, collapseAll, attachSchedule, detachSchedule],
    );

    const addScheduleState = useBoolean(false);

    const scheduleListContent = useMemo(
        () => (
            <Stack gap={1}>
                <IfHasAllPermissions permissions={readListPerms}>
                    {scheduleItems}
                </IfHasAllPermissions>
            </Stack>
        ),
        [scheduleItems],
    );

    const showList = useMemo(
        () => !addScheduleState.value && editSchedule == null,
        [addScheduleState.value, editSchedule],
    );

    const {
        start,
        isLoading,
        hasFailed,
        error,
        hasSucceeded,
        reset,
        abortControllerRef,
        requestStateRef,
    } = useAPIClientRequest(apiClient.Entities.Schedule.uploadSiteList);

    const {
        start: attachStart,
        isLoading: attachLoading,
        reset: attachReset,
        error: attachError,
        requestState: attachRequestState,
    } = useAPIClientRequest(apiClient.Entities.Campaign.attachSchedules);

    const {
        start: detachStart,
        isLoading: detachLoading,
        reset: detachReset,
        error: detachError,
        requestState: detachRequestState,
    } = useAPIClientRequest(apiClient.Entities.Campaign.detachSchedules);

    const uploadDialogState = useBoolean(false);
    const [file, setFile] = useState<File[] | null>(null);
    const onFileAdded = useCallback((files: File[]) => {
        setFile(prevFiles => [...(prevFiles || []), ...files]);
    }, []);

    useEffect(() => {
        if (hasSucceeded) {
            setFile(null);
        }
    }, [hasSucceeded]);

    const overrideBool = useBoolean(false);

    const onFileSubmitted = useCallback(async () => {
        const formData = new FormData();
        file.forEach(f => formData.append('file[]', f));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append('override', overrideBool.value === true ? 1 : 0);
        await to(start({ formData, campaign: id }));
    }, [file, overrideBool.value, start, id]);

    const closeDetachDialog = useCallback(() => {
        setSelectedSchedule(null);
        detachSchedule.setFalse();
    }, [setSelectedSchedule, detachSchedule]);

    const closeAttachDialog = useCallback(() => {
        setSelectedSchedule(null);
        attachSchedule.setFalse();
    }, [setSelectedSchedule, attachSchedule]);

    const onClose = useCallback(() => {
        if (requestStateRef?.current === NetworkRequestState.Success) {
            fetchData();
        }
        abortControllerRef?.current?.abort();
        reset();
        setFile(null);
        uploadDialogState.setFalse();
        overrideBool.setFalse();
    }, [requestStateRef, abortControllerRef, reset, fetchData, uploadDialogState, overrideBool]);
    const handleCloseUploadDialog = useCallback(() => {
        uploadDialogState.setFalse();
        setFile(null);
        overrideBool.setFalse();
    }, [uploadDialogState, overrideBool]);
    const t = useCommonTranslation();
    const allSelected = useMemo(
        () => selected.concat(selectedScreens, selectedDisplays),
        [selected, selectedDisplays, selectedScreens],
    );
    const groupIdsByType = items =>
        items.reduce(
            (acc, item) => {
                const key = item.type === 'Display' ? 'displays' : 'line_items';
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item.id);
                return acc;
            },
            { displays: [], line_items: [] },
        );

    const detachScheduleCall = useCallback(async () => {
        const groupedIds = groupIdsByType(allSelected);
        groupedIds.schedules = [selectedSchedule];
        await to(detachStart(id, groupedIds));
    }, [allSelected, detachStart, id, selectedSchedule]);

    const attachScheduleCall = useCallback(async () => {
        const groupedIds = groupIdsByType(allSelected);
        groupedIds.schedules = [selectedSchedule];
        await to(attachStart(id, groupedIds));
    }, [allSelected, attachStart, id, selectedSchedule]);

    const resetAllStates = useCallback(
        requestState => {
            setSelected([]);
            setSelectedScreens([]);
            if (requestState === attachRequestState) {
                attachReset();
                setAttachStateRequest(NetworkRequestState.Idle);
                closeAttachDialog();
            }
            if (requestState === detachRequestState) {
                detachReset();
                closeDetachDialog();
                setDetachStateRequest(NetworkRequestState.Idle);
            } else {
                detachReset();
                closeDetachDialog();
            }
        },
        [
            attachRequestState,
            attachReset,
            closeAttachDialog,
            closeDetachDialog,
            detachRequestState,
            detachReset,
            setAttachStateRequest,
            setDetachStateRequest,
            setSelected,
            setSelectedScreens,
        ],
    );

    useEffect(() => {
        if (attachRequestState === NetworkRequestState.Success) {
            getScheduleLineItems(page, perPage);
            setAttachStateRequest(NetworkRequestState.Success);
            setTimeout(() => {
                resetAllStates(attachRequestState);
            }, 500);
        }

        if (detachRequestState === NetworkRequestState.Success) {
            getScheduleLineItems(page, perPage);
            setDetachStateRequest(NetworkRequestState.Success);
            setTimeout(() => {
                resetAllStates(detachRequestState);
            }, 500);
        }
    }, [
        attachRequestState,
        detachRequestState,
        getScheduleLineItems,
        page,
        perPage,
        prevSelectedLineItemId,
        resetAllStates,
        setAttachStateRequest,
        setDetachStateRequest,
    ]);

    useEffect(() => {
        const debouncedApiCall = debounce(() => {
            getSchedules(lineItemScheduleOptions.page, lineItemScheduleOptions.perPage, search);
        }, 1000);

        debouncedApiCall();

        return () => {
            debouncedApiCall.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    const handleCollapseAll = useCallback(() => {
        collapseAll.setTrue();
        setExpandedScheduleId(null);
    }, [collapseAll, setExpandedScheduleId]);

    return (
        <Box flex={1} overflow="auto" position="relative" display="flex">
            {scheduleLoading && <LoadingSpinner />}
            <Box
                display={!showList ? 'none' : undefined}
                flex={1}
                sx={{ opacity: scheduleLoading ? 0.5 : 1 }}
            >
                <Stack
                    textAlign="center"
                    position="sticky"
                    top={0}
                    bgcolor="white"
                    pb={2}
                    justifyContent="space-between"
                    direction="row"
                >
                    <IfHasAllPermissions permissions={createPerms}>
                        <NewScheduleButton onClick={addScheduleState.setTrue} />
                    </IfHasAllPermissions>
                    <Button onClick={handleCollapseAll}>
                        {t('Modules.Main.Campaigns.CampaignSchedule.Schedules.list.collapseAll')}
                    </Button>
                    <IfHasAllPermissions permissions={sitelistUploadPerms}>
                        <IconButton color="primary" onClick={uploadDialogState.setTrue}>
                            {uploadIcon}
                        </IconButton>
                        <DialogV2
                            onClose={
                                hasSucceeded
                                    ? onClose
                                    : hasFailed
                                    ? onClose
                                    : isLoading
                                    ? onClose
                                    : handleCloseUploadDialog
                            }
                            open={uploadDialogState.value}
                            title="Modules.Main.Campaigns.CampaignSchedule.upload"
                        >
                            <Box justifyContent="center">
                                <Stack gap={1}>
                                    <Button variant="contained" onClick={handleDownload}>
                                        {t(
                                            'Modules.Main.Campaigns.CampaignSchedule.downloadTemplate',
                                        )}
                                    </Button>
                                    <FileUploadDropzone
                                        fileAccept=".csv,.txt"
                                        onFilesAdded={onFileAdded}
                                        dropzoneLabel="Modules.Main.Campaigns.Overview.BookingSummary.dropzoneText"
                                    />
                                    {file && (
                                        <Stack gap={1}>
                                            {file.map((f, index) => (
                                                <Stack
                                                    direction="row"
                                                    key={f.name}
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    spacing={2}
                                                >
                                                    <Alert severity="info" sx={{ flexGrow: 1 }}>
                                                        {f.name}
                                                    </Alert>
                                                    <IconButton
                                                        size="small"
                                                        color="error"
                                                        onClick={() => {
                                                            setFile(prevFiles =>
                                                                prevFiles.filter(
                                                                    (_, i) => i !== index,
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        X
                                                    </IconButton>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    )}

                                    <Stack direction="row">
                                        <Checkbox
                                            value={overrideBool.value}
                                            isBoolean
                                            label={t(
                                                'Modules.Main.Campaigns.CampaignSchedule.override',
                                            )}
                                            onClick={overrideBool.toggle}
                                        />
                                    </Stack>
                                    <Button
                                        variant="contained"
                                        disabled={file == null || isLoading || file.length === 0}
                                        onClick={onFileSubmitted}
                                    >
                                        {t('Modules.Main.Campaigns.CampaignSchedule.uploadFile')}
                                    </Button>
                                    <Box display="flex" py={2} justifyContent="center">
                                        {isLoading && <CircularProgress size={35} />}
                                        {hasSucceeded && (
                                            <Alert severity="success">Upload Successful</Alert>
                                        )}
                                        {hasFailed && (
                                            <Alert severity="error">{String(error)}</Alert>
                                        )}
                                    </Box>
                                </Stack>
                            </Box>
                        </DialogV2>
                    </IfHasAllPermissions>
                </Stack>
                <Box pb={2}>
                    <TextField
                        value={search}
                        size="small"
                        variant="outlined"
                        onChange={onSearchChange}
                        placeholder={t(
                            'Modules.Main.Campaigns.CampaignSchedule.filters.searchLabel',
                        )}
                        disabled={scheduleLoading}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search fontSize="inherit" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {scheduleListContent}
            </Box>
            {addScheduleState.value && (
                <Box display="flex" flex={1} overflow="hidden">
                    <ScheduleForm
                        onClose={addScheduleState.setFalse}
                        campaignId={id}
                        updateSchedules={updateSchedules}
                    />
                </Box>
            )}
            {editSchedule != null && (
                <Box display="flex" flex={1} overflow="hidden">
                    <ScheduleForm
                        onClose={resetEdit}
                        campaignId={id}
                        schedule={editSchedule}
                        updateSchedules={updateSchedules}
                    />
                </Box>
            )}
            <DialogV2
                onClose={closeAttachDialog}
                open={attachSchedule.value}
                title="Modules.Main.Campaigns.CampaignSchedule.Schedules.attachSchedules"
                maxWidth="xl"
            >
                <Stack>
                    <SelectedLineItemsTable id={id} />
                    {!attachLoading && (
                        <AutoGrid pt={2} justifyContent="center">
                            <Button
                                sx={{ minWidth: '10rem' }}
                                variant="contained"
                                disabled={allSelected.length === 0 || selectedSchedule === null}
                                onClick={attachScheduleCall}
                            >
                                {t('Modules.Main.Campaigns.CampaignSchedule.Schedules.apply')}
                            </Button>
                        </AutoGrid>
                    )}
                    {attachLoading && <LoadingSpinner />}
                    {attachError && <Alert severity="error">{String(attachError)}</Alert>}
                </Stack>
            </DialogV2>
            <DialogV2
                onClose={closeDetachDialog}
                open={detachSchedule.value}
                title="Modules.Main.Campaigns.CampaignSchedule.Schedules.detachSchedules"
                maxWidth="xl"
            >
                <Stack>
                    <SelectedLineItemsTable id={id} />
                    {!detachLoading && (
                        <AutoGrid pt={2} justifyContent="center">
                            <Button
                                sx={{ minWidth: '10rem' }}
                                variant="contained"
                                disabled={allSelected.length === 0 || selectedSchedule === null}
                                onClick={detachScheduleCall}
                            >
                                {t('Modules.Main.Campaigns.CampaignSchedule.Schedules.apply')}
                            </Button>
                        </AutoGrid>
                    )}
                    {detachLoading && <LoadingSpinner />}
                    {detachError && <Alert severity="error">{String(detachError)}</Alert>}
                </Stack>
            </DialogV2>
        </Box>
    );
};

export default ScheduleList;
