export enum PermissionName {
    DisplaysActionsCreate = 'displays.actions.create',
    DisplaysActionsRead = 'displays.actions.read',
    DisplaysActionsUpdate = 'displays.actions.update',
    DisplaysActionsDestroy = 'displays.actions.destroy',
    DisplaysAdminsCreate = 'displays.admins.create',
    DisplaysAdminsRead = 'displays.admins.read',
    DisplaysAdminsUpdate = 'displays.admins.update',
    DisplaysAdminsDestroy = 'displays.admins.destroy',
    DisplaysAdmin_password_resetsCreate = 'displays.admin_password_resets.create',
    DisplaysAdmin_password_resetsRead = 'displays.admin_password_resets.read',
    DisplaysAdmin_password_resetsUpdate = 'displays.admin_password_resets.update',
    DisplaysAdmin_password_resetsDestroy = 'displays.admin_password_resets.destroy',
    DisplaysAnalogue_screensCreate = 'displays.analogue_screens.create',
    DisplaysAnalogue_screensRead = 'displays.analogue_screens.read',
    DisplaysAnalogue_screensUpdate = 'displays.analogue_screens.update',
    DisplaysAnalogue_screensDestroy = 'displays.analogue_screens.destroy',
    DisplaysCacheCreate = 'displays.cache.create',
    DisplaysCacheRead = 'displays.cache.read',
    DisplaysCacheUpdate = 'displays.cache.update',
    DisplaysCacheDestroy = 'displays.cache.destroy',
    DisplaysCountriesCreate = 'displays.countries.create',
    DisplaysCountriesRead = 'displays.countries.read',
    DisplaysCountriesUpdate = 'displays.countries.update',
    DisplaysCountriesDestroy = 'displays.countries.destroy',
    DisplaysCountry_ownerCreate = 'displays.country_owner.create',
    DisplaysCountry_ownerRead = 'displays.country_owner.read',
    DisplaysCountry_ownerUpdate = 'displays.country_owner.update',
    DisplaysCountry_ownerDestroy = 'displays.country_owner.destroy',
    DisplaysCurrenciesCreate = 'displays.currencies.create',
    DisplaysCurrenciesRead = 'displays.currencies.read',
    DisplaysCurrenciesUpdate = 'displays.currencies.update',
    DisplaysCurrenciesDestroy = 'displays.currencies.destroy',
    DisplaysDisplays_import_requestsCreate = 'displays.displays_import_requests.create',
    DisplaysDisplays_import_requestsRead = 'displays.displays_import_requests.read',
    DisplaysDisplays_import_requestsUpdate = 'displays.displays_import_requests.update',
    DisplaysDisplays_import_requestsDestroy = 'displays.displays_import_requests.destroy',
    DisplaysFailed_jobsCreate = 'displays.failed_jobs.create',
    DisplaysFailed_jobsRead = 'displays.failed_jobs.read',
    DisplaysFailed_jobsUpdate = 'displays.failed_jobs.update',
    DisplaysFailed_jobsDestroy = 'displays.failed_jobs.destroy',
    DisplaysFile_typesCreate = 'displays.file_types.create',
    DisplaysFile_typesRead = 'displays.file_types.read',
    DisplaysFile_typesUpdate = 'displays.file_types.update',
    DisplaysFile_typesDestroy = 'displays.file_types.destroy',
    DisplaysFile_type_groupsCreate = 'displays.file_type_groups.create',
    DisplaysFile_type_groupsRead = 'displays.file_type_groups.read',
    DisplaysFile_type_groupsUpdate = 'displays.file_type_groups.update',
    DisplaysFile_type_groupsDestroy = 'displays.file_type_groups.destroy',
    DisplaysFrame_typesCreate = 'displays.frame_types.create',
    DisplaysFrame_typesRead = 'displays.frame_types.read',
    DisplaysFrame_typesUpdate = 'displays.frame_types.update',
    DisplaysFrame_typesDestroy = 'displays.frame_types.destroy',
    DisplaysGeopath_displaysCreate = 'displays.geopath_displays.create',
    DisplaysGeopath_displaysRead = 'displays.geopath_displays.read',
    DisplaysGeopath_displaysUpdate = 'displays.geopath_displays.update',
    DisplaysGeopath_displaysDestroy = 'displays.geopath_displays.destroy',
    DisplaysIlluminationsCreate = 'displays.illuminations.create',
    DisplaysIlluminationsRead = 'displays.illuminations.read',
    DisplaysIlluminationsUpdate = 'displays.illuminations.update',
    DisplaysIlluminationsDestroy = 'displays.illuminations.destroy',
    DisplaysMarketsCreate = 'displays.markets.create',
    DisplaysMarketsRead = 'displays.markets.read',
    DisplaysMarketsUpdate = 'displays.markets.update',
    DisplaysMarketsDestroy = 'displays.markets.destroy',
    DisplaysMarket_ownerCreate = 'displays.market_owner.create',
    DisplaysMarket_ownerRead = 'displays.market_owner.read',
    DisplaysMarket_ownerUpdate = 'displays.market_owner.update',
    DisplaysMarket_ownerDestroy = 'displays.market_owner.destroy',
    DisplaysOauth_access_tokensCreate = 'displays.oauth_access_tokens.create',
    DisplaysOauth_access_tokensRead = 'displays.oauth_access_tokens.read',
    DisplaysOauth_access_tokensUpdate = 'displays.oauth_access_tokens.update',
    DisplaysOauth_access_tokensDestroy = 'displays.oauth_access_tokens.destroy',
    DisplaysOauth_auth_codesCreate = 'displays.oauth_auth_codes.create',
    DisplaysOauth_auth_codesRead = 'displays.oauth_auth_codes.read',
    DisplaysOauth_auth_codesUpdate = 'displays.oauth_auth_codes.update',
    DisplaysOauth_auth_codesDestroy = 'displays.oauth_auth_codes.destroy',
    DisplaysOauth_clientsCreate = 'displays.oauth_clients.create',
    DisplaysOauth_clientsRead = 'displays.oauth_clients.read',
    DisplaysOauth_clientsUpdate = 'displays.oauth_clients.update',
    DisplaysOauth_clientsDestroy = 'displays.oauth_clients.destroy',
    DisplaysOauth_personal_access_clientsCreate = 'displays.oauth_personal_access_clients.create',
    DisplaysOauth_personal_access_clientsRead = 'displays.oauth_personal_access_clients.read',
    DisplaysOauth_personal_access_clientsUpdate = 'displays.oauth_personal_access_clients.update',
    DisplaysOauth_personal_access_clientsDestroy = 'displays.oauth_personal_access_clients.destroy',
    DisplaysOauth_refresh_tokensCreate = 'displays.oauth_refresh_tokens.create',
    DisplaysOauth_refresh_tokensRead = 'displays.oauth_refresh_tokens.read',
    DisplaysOauth_refresh_tokensUpdate = 'displays.oauth_refresh_tokens.update',
    DisplaysOauth_refresh_tokensDestroy = 'displays.oauth_refresh_tokens.destroy',
    DisplaysOperational_timesCreate = 'displays.operational_times.create',
    DisplaysOperational_timesRead = 'displays.operational_times.read',
    DisplaysOperational_timesUpdate = 'displays.operational_times.update',
    DisplaysOperational_timesDestroy = 'displays.operational_times.destroy',
    DisplaysOwnersCreate = 'displays.owners.create',
    DisplaysOwnersRead = 'displays.owners.read',
    DisplaysOwnersUpdate = 'displays.owners.update',
    DisplaysOwnersDestroy = 'displays.owners.destroy',
    DisplaysOwner_displayCreate = 'displays.owner_display.create',
    DisplaysOwner_displayRead = 'displays.owner_display.read',
    DisplaysOwner_displayUpdate = 'displays.owner_display.update',
    DisplaysOwner_displayDestroy = 'displays.owner_display.destroy',
    DisplaysPacksCreate = 'displays.packs.create',
    DisplaysPacksRead = 'displays.packs.read',
    DisplaysPacksUpdate = 'displays.packs.update',
    DisplaysPacksDestroy = 'displays.packs.destroy',
    DisplaysPack_propertiesCreate = 'displays.pack_properties.create',
    DisplaysPack_propertiesRead = 'displays.pack_properties.read',
    DisplaysPack_propertiesUpdate = 'displays.pack_properties.update',
    DisplaysPack_propertiesDestroy = 'displays.pack_properties.destroy',
    DisplaysPartnersCreate = 'displays.partners.create',
    DisplaysPartnersRead = 'displays.partners.read',
    DisplaysPartnersUpdate = 'displays.partners.update',
    DisplaysPartnersDestroy = 'displays.partners.destroy',
    DisplaysPartner_space_displaysCreate = 'displays.partner_space_displays.create',
    DisplaysPartner_space_displaysRead = 'displays.partner_space_displays.read',
    DisplaysPartner_space_displaysUpdate = 'displays.partner_space_displays.update',
    DisplaysPartner_space_displaysDestroy = 'displays.partner_space_displays.destroy',
    DisplaysPassword_resetsCreate = 'displays.password_resets.create',
    DisplaysPassword_resetsRead = 'displays.password_resets.read',
    DisplaysPassword_resetsUpdate = 'displays.password_resets.update',
    DisplaysPassword_resetsDestroy = 'displays.password_resets.destroy',
    DisplaysPropertiesCreate = 'displays.properties.create',
    DisplaysPropertiesRead = 'displays.properties.read',
    DisplaysPropertiesUpdate = 'displays.properties.update',
    DisplaysPropertiesDestroy = 'displays.properties.destroy',
    DisplaysScreensCreate = 'displays.screens.create',
    DisplaysScreensRead = 'displays.screens.read',
    DisplaysScreensUpdate = 'displays.screens.update',
    DisplaysScreensDestroy = 'displays.screens.destroy',
    DisplaysScreen_booking_timesCreate = 'displays.screen_booking_times.create',
    DisplaysScreen_booking_timesRead = 'displays.screen_booking_times.read',
    DisplaysScreen_booking_timesUpdate = 'displays.screen_booking_times.update',
    DisplaysScreen_booking_timesDestroy = 'displays.screen_booking_times.destroy',
    DisplaysScreen_packCreate = 'displays.screen_pack.create',
    DisplaysScreen_packRead = 'displays.screen_pack.read',
    DisplaysScreen_packUpdate = 'displays.screen_pack.update',
    DisplaysScreen_packDestroy = 'displays.screen_pack.destroy',
    DisplaysScreen_tagCreate = 'displays.screen_tag.create',
    DisplaysScreen_tagRead = 'displays.screen_tag.read',
    DisplaysScreen_tagUpdate = 'displays.screen_tag.update',
    DisplaysScreen_tagDestroy = 'displays.screen_tag.destroy',
    DisplaysSessionsCreate = 'displays.sessions.create',
    DisplaysSessionsRead = 'displays.sessions.read',
    DisplaysSessionsUpdate = 'displays.sessions.update',
    DisplaysSessionsDestroy = 'displays.sessions.destroy',
    DisplaysTagsCreate = 'displays.tags.create',
    DisplaysTagsRead = 'displays.tags.read',
    DisplaysTagsUpdate = 'displays.tags.update',
    DisplaysTagsDestroy = 'displays.tags.destroy',
    DisplaysUsersCreate = 'displays.users.create',
    DisplaysUsersRead = 'displays.users.read',
    DisplaysUsersUpdate = 'displays.users.update',
    DisplaysUsersDestroy = 'displays.users.destroy',
    DisplaysV_displaysCreate = 'displays.v_displays.create',
    DisplaysV_displaysRead = 'displays.v_displays.read',
    DisplaysV_displaysUpdate = 'displays.v_displays.update',
    DisplaysV_displaysDestroy = 'displays.v_displays.destroy',
    DisplaysV_sites_packsCreate = 'displays.v_sites_packs.create',
    DisplaysV_sites_packsRead = 'displays.v_sites_packs.read',
    DisplaysV_sites_packsUpdate = 'displays.v_sites_packs.update',
    DisplaysV_sites_packsDestroy = 'displays.v_sites_packs.destroy',
    DisplaysV_sites_packs_displaysCreate = 'displays.v_sites_packs_displays.create',
    DisplaysV_sites_packs_displaysRead = 'displays.v_sites_packs_displays.read',
    DisplaysV_sites_packs_displaysUpdate = 'displays.v_sites_packs_displays.update',
    DisplaysV_sites_packs_displaysDestroy = 'displays.v_sites_packs_displays.destroy',
    Pop_displaysAdminsCreate = 'pop_displays.admins.create',
    Pop_displaysAdminsRead = 'pop_displays.admins.read',
    Pop_displaysAdminsUpdate = 'pop_displays.admins.update',
    Pop_displaysAdminsDestroy = 'pop_displays.admins.destroy',
    Pop_displaysAudiencesCreate = 'pop_displays.audiences.create',
    Pop_displaysAudiencesRead = 'pop_displays.audiences.read',
    Pop_displaysAudiencesUpdate = 'pop_displays.audiences.update',
    Pop_displaysAudiencesDestroy = 'pop_displays.audiences.destroy',
    Pop_displaysAudience_filesCreate = 'pop_displays.audience_files.create',
    Pop_displaysAudience_filesRead = 'pop_displays.audience_files.read',
    Pop_displaysAudience_filesUpdate = 'pop_displays.audience_files.update',
    Pop_displaysAudience_filesDestroy = 'pop_displays.audience_files.destroy',
    Pop_displaysCacheCreate = 'pop_displays.cache.create',
    Pop_displaysCacheRead = 'pop_displays.cache.read',
    Pop_displaysCacheUpdate = 'pop_displays.cache.update',
    Pop_displaysCacheDestroy = 'pop_displays.cache.destroy',
    Pop_displaysChannel_framesCreate = 'pop_displays.channel_frames.create',
    Pop_displaysChannel_framesRead = 'pop_displays.channel_frames.read',
    Pop_displaysChannel_framesUpdate = 'pop_displays.channel_frames.update',
    Pop_displaysChannel_framesDestroy = 'pop_displays.channel_frames.destroy',
    Pop_displaysDisplaysCreate = 'pop_displays.displays.create',
    Pop_displaysDisplaysRead = 'pop_displays.displays.read',
    Pop_displaysDisplaysUpdate = 'pop_displays.displays.update',
    Pop_displaysDisplaysDestroy = 'pop_displays.displays.destroy',
    Pop_displaysDisplay_channel_framesCreate = 'pop_displays.display_channel_frames.create',
    Pop_displaysDisplay_channel_framesRead = 'pop_displays.display_channel_frames.read',
    Pop_displaysDisplay_channel_framesUpdate = 'pop_displays.display_channel_frames.update',
    Pop_displaysDisplay_channel_framesDestroy = 'pop_displays.display_channel_frames.destroy',
    Pop_displaysDisplay_groupsCreate = 'pop_displays.display_groups.create',
    Pop_displaysDisplay_groupsRead = 'pop_displays.display_groups.read',
    Pop_displaysDisplay_groupsUpdate = 'pop_displays.display_groups.update',
    Pop_displaysDisplay_groupsDestroy = 'pop_displays.display_groups.destroy',
    Pop_displaysDisplay_group_displaysCreate = 'pop_displays.display_group_displays.create',
    Pop_displaysDisplay_group_displaysRead = 'pop_displays.display_group_displays.read',
    Pop_displaysDisplay_group_displaysUpdate = 'pop_displays.display_group_displays.update',
    Pop_displaysDisplay_group_displaysDestroy = 'pop_displays.display_group_displays.destroy',
    Pop_displaysFailed_jobsCreate = 'pop_displays.failed_jobs.create',
    Pop_displaysFailed_jobsRead = 'pop_displays.failed_jobs.read',
    Pop_displaysFailed_jobsUpdate = 'pop_displays.failed_jobs.update',
    Pop_displaysFailed_jobsDestroy = 'pop_displays.failed_jobs.destroy',
    Pop_displaysGraph_report_typesCreate = 'pop_displays.graph_report_types.create',
    Pop_displaysGraph_report_typesRead = 'pop_displays.graph_report_types.read',
    Pop_displaysGraph_report_typesUpdate = 'pop_displays.graph_report_types.update',
    Pop_displaysGraph_report_typesDestroy = 'pop_displays.graph_report_types.destroy',
    Pop_displaysJob_statusesCreate = 'pop_displays.job_statuses.create',
    Pop_displaysJob_statusesRead = 'pop_displays.job_statuses.read',
    Pop_displaysJob_statusesUpdate = 'pop_displays.job_statuses.update',
    Pop_displaysJob_statusesDestroy = 'pop_displays.job_statuses.destroy',
    Pop_displaysLog_file_importsCreate = 'pop_displays.log_file_imports.create',
    Pop_displaysLog_file_importsRead = 'pop_displays.log_file_imports.read',
    Pop_displaysLog_file_importsUpdate = 'pop_displays.log_file_imports.update',
    Pop_displaysLog_file_importsDestroy = 'pop_displays.log_file_imports.destroy',
    Pop_displaysLog_file_parsedCreate = 'pop_displays.log_file_parsed.create',
    Pop_displaysLog_file_parsedRead = 'pop_displays.log_file_parsed.read',
    Pop_displaysLog_file_parsedUpdate = 'pop_displays.log_file_parsed.update',
    Pop_displaysLog_file_parsedDestroy = 'pop_displays.log_file_parsed.destroy',
    Pop_displaysLog_file_resourcesCreate = 'pop_displays.log_file_resources.create',
    Pop_displaysLog_file_resourcesRead = 'pop_displays.log_file_resources.read',
    Pop_displaysLog_file_resourcesUpdate = 'pop_displays.log_file_resources.update',
    Pop_displaysLog_file_resourcesDestroy = 'pop_displays.log_file_resources.destroy',
    Pop_displaysLog_file_storeCreate = 'pop_displays.log_file_store.create',
    Pop_displaysLog_file_storeRead = 'pop_displays.log_file_store.read',
    Pop_displaysLog_file_storeUpdate = 'pop_displays.log_file_store.update',
    Pop_displaysLog_file_storeDestroy = 'pop_displays.log_file_store.destroy',
    Pop_displaysLog_import_sourceCreate = 'pop_displays.log_import_source.create',
    Pop_displaysLog_import_sourceRead = 'pop_displays.log_import_source.read',
    Pop_displaysLog_import_sourceUpdate = 'pop_displays.log_import_source.update',
    Pop_displaysLog_import_sourceDestroy = 'pop_displays.log_import_source.destroy',
    Pop_displaysMarketsCreate = 'pop_displays.markets.create',
    Pop_displaysMarketsRead = 'pop_displays.markets.read',
    Pop_displaysMarketsUpdate = 'pop_displays.markets.update',
    Pop_displaysMarketsDestroy = 'pop_displays.markets.destroy',
    Pop_displaysMediaCreate = 'pop_displays.media.create',
    Pop_displaysMediaRead = 'pop_displays.media.read',
    Pop_displaysMediaUpdate = 'pop_displays.media.update',
    Pop_displaysMediaDestroy = 'pop_displays.media.destroy',
    Pop_displaysOauth_access_tokensCreate = 'pop_displays.oauth_access_tokens.create',
    Pop_displaysOauth_access_tokensRead = 'pop_displays.oauth_access_tokens.read',
    Pop_displaysOauth_access_tokensUpdate = 'pop_displays.oauth_access_tokens.update',
    Pop_displaysOauth_access_tokensDestroy = 'pop_displays.oauth_access_tokens.destroy',
    Pop_displaysOauth_auth_codesCreate = 'pop_displays.oauth_auth_codes.create',
    Pop_displaysOauth_auth_codesRead = 'pop_displays.oauth_auth_codes.read',
    Pop_displaysOauth_auth_codesUpdate = 'pop_displays.oauth_auth_codes.update',
    Pop_displaysOauth_auth_codesDestroy = 'pop_displays.oauth_auth_codes.destroy',
    Pop_displaysOauth_clientsCreate = 'pop_displays.oauth_clients.create',
    Pop_displaysOauth_clientsRead = 'pop_displays.oauth_clients.read',
    Pop_displaysOauth_clientsUpdate = 'pop_displays.oauth_clients.update',
    Pop_displaysOauth_clientsDestroy = 'pop_displays.oauth_clients.destroy',
    Pop_displaysOauth_personal_access_clientsCreate = 'pop_displays.oauth_personal_access_clients.create',
    Pop_displaysOauth_personal_access_clientsRead = 'pop_displays.oauth_personal_access_clients.read',
    Pop_displaysOauth_personal_access_clientsUpdate = 'pop_displays.oauth_personal_access_clients.update',
    Pop_displaysOauth_personal_access_clientsDestroy = 'pop_displays.oauth_personal_access_clients.destroy',
    Pop_displaysOauth_refresh_tokensCreate = 'pop_displays.oauth_refresh_tokens.create',
    Pop_displaysOauth_refresh_tokensRead = 'pop_displays.oauth_refresh_tokens.read',
    Pop_displaysOauth_refresh_tokensUpdate = 'pop_displays.oauth_refresh_tokens.update',
    Pop_displaysOauth_refresh_tokensDestroy = 'pop_displays.oauth_refresh_tokens.destroy',
    Pop_displaysPassword_resetsCreate = 'pop_displays.password_resets.create',
    Pop_displaysPassword_resetsRead = 'pop_displays.password_resets.read',
    Pop_displaysPassword_resetsUpdate = 'pop_displays.password_resets.update',
    Pop_displaysPassword_resetsDestroy = 'pop_displays.password_resets.destroy',
    Pop_displaysPop_audienceCreate = 'pop_displays.pop_audience.create',
    Pop_displaysPop_audienceRead = 'pop_displays.pop_audience.read',
    Pop_displaysPop_audienceUpdate = 'pop_displays.pop_audience.update',
    Pop_displaysPop_audienceDestroy = 'pop_displays.pop_audience.destroy',
    Pop_displaysPop_audience_displayCreate = 'pop_displays.pop_audience_display.create',
    Pop_displaysPop_audience_displayRead = 'pop_displays.pop_audience_display.read',
    Pop_displaysPop_audience_displayUpdate = 'pop_displays.pop_audience_display.update',
    Pop_displaysPop_audience_displayDestroy = 'pop_displays.pop_audience_display.destroy',
    Pop_displaysPop_settingsCreate = 'pop_displays.pop_settings.create',
    Pop_displaysPop_settingsRead = 'pop_displays.pop_settings.read',
    Pop_displaysPop_settingsUpdate = 'pop_displays.pop_settings.update',
    Pop_displaysPop_settingsDestroy = 'pop_displays.pop_settings.destroy',
    Pop_displaysSessionsCreate = 'pop_displays.sessions.create',
    Pop_displaysSessionsRead = 'pop_displays.sessions.read',
    Pop_displaysSessionsUpdate = 'pop_displays.sessions.update',
    Pop_displaysSessionsDestroy = 'pop_displays.sessions.destroy',
    Pop_displaysUnilive_campaign_pop_dataCreate = 'pop_displays.unilive_campaign_pop_data.create',
    Pop_displaysUnilive_campaign_pop_dataRead = 'pop_displays.unilive_campaign_pop_data.read',
    Pop_displaysUnilive_campaign_pop_dataUpdate = 'pop_displays.unilive_campaign_pop_data.update',
    Pop_displaysUnilive_campaign_pop_dataDestroy = 'pop_displays.unilive_campaign_pop_data.destroy',
    Pop_displaysUnilive_campaign_pop_playback_dataCreate = 'pop_displays.unilive_campaign_pop_playback_data.create',
    Pop_displaysUnilive_campaign_pop_playback_dataRead = 'pop_displays.unilive_campaign_pop_playback_data.read',
    Pop_displaysUnilive_campaign_pop_playback_dataUpdate = 'pop_displays.unilive_campaign_pop_playback_data.update',
    Pop_displaysUnilive_campaign_pop_playback_dataDestroy = 'pop_displays.unilive_campaign_pop_playback_data.destroy',
    Pop_displaysUsersCreate = 'pop_displays.users.create',
    Pop_displaysUsersRead = 'pop_displays.users.read',
    Pop_displaysUsersUpdate = 'pop_displays.users.update',
    Pop_displaysUsersDestroy = 'pop_displays.users.destroy',
    Pop_displaysV_pop_summaryCreate = 'pop_displays.v_pop_summary.create',
    Pop_displaysV_pop_summaryRead = 'pop_displays.v_pop_summary.read',
    Pop_displaysV_pop_summaryUpdate = 'pop_displays.v_pop_summary.update',
    Pop_displaysV_pop_summaryDestroy = 'pop_displays.v_pop_summary.destroy',
    UniledportalAgencyCreate = 'uniledportal.agency.create',
    UniledportalAgencyRead = 'uniledportal.agency.read',
    UniledportalAgencyUpdate = 'uniledportal.agency.update',
    UniledportalAgencyDestroy = 'uniledportal.agency.destroy',
    UniledportalAgency_userCreate = 'uniledportal.agency_user.create',
    UniledportalAgency_userRead = 'uniledportal.agency_user.read',
    UniledportalAgency_userUpdate = 'uniledportal.agency_user.update',
    UniledportalAgency_userDestroy = 'uniledportal.agency_user.destroy',
    UniledportalBuyerCreate = 'uniledportal.buyer.create',
    UniledportalBuyerRead = 'uniledportal.buyer.read',
    UniledportalBuyerUpdate = 'uniledportal.buyer.update',
    UniledportalBuyerDestroy = 'uniledportal.buyer.destroy',
    UniledportalBuyer_userCreate = 'uniledportal.buyer_user.create',
    UniledportalBuyer_userRead = 'uniledportal.buyer_user.read',
    UniledportalBuyer_userUpdate = 'uniledportal.buyer_user.update',
    UniledportalBuyer_userDestroy = 'uniledportal.buyer_user.destroy',
    UniledportalCampaignCreate = 'uniledportal.campaign.create',
    UniledportalCampaignRead = 'uniledportal.campaign.read',
    UniledportalCampaignUpdate = 'uniledportal.campaign.update',
    UniledportalCampaignDestroy = 'uniledportal.campaign.destroy',
    UniledportalCampaign_displayCreate = 'uniledportal.campaign_display.create',
    UniledportalCampaign_displayRead = 'uniledportal.campaign_display.read',
    UniledportalCampaign_displayUpdate = 'uniledportal.campaign_display.update',
    UniledportalCampaign_displayDestroy = 'uniledportal.campaign_display.destroy',
    UniledportalCampaign_display_creativeCreate = 'uniledportal.campaign_display_creative.create',
    UniledportalCampaign_display_creativeRead = 'uniledportal.campaign_display_creative.read',
    UniledportalCampaign_display_creativeUpdate = 'uniledportal.campaign_display_creative.update',
    UniledportalCampaign_display_creativeDestroy = 'uniledportal.campaign_display_creative.destroy',
    UniledportalCampaign_display_noteCreate = 'uniledportal.campaign_display_note.create',
    UniledportalCampaign_display_noteRead = 'uniledportal.campaign_display_note.read',
    UniledportalCampaign_display_noteUpdate = 'uniledportal.campaign_display_note.update',
    UniledportalCampaign_display_noteDestroy = 'uniledportal.campaign_display_note.destroy',
    UniledportalCampaign_display_ruleCreate = 'uniledportal.campaign_display_rule.create',
    UniledportalCampaign_display_ruleRead = 'uniledportal.campaign_display_rule.read',
    UniledportalCampaign_display_ruleUpdate = 'uniledportal.campaign_display_rule.update',
    UniledportalCampaign_display_ruleDestroy = 'uniledportal.campaign_display_rule.destroy',
    UniledportalCampaign_display_tagCreate = 'uniledportal.campaign_display_tag.create',
    UniledportalCampaign_display_tagRead = 'uniledportal.campaign_display_tag.read',
    UniledportalCampaign_display_tagUpdate = 'uniledportal.campaign_display_tag.update',
    UniledportalCampaign_display_tagDestroy = 'uniledportal.campaign_display_tag.destroy',
    UniledportalCampaign_filterCreate = 'uniledportal.campaign_filter.create',
    UniledportalCampaign_filterRead = 'uniledportal.campaign_filter.read',
    UniledportalCampaign_filterUpdate = 'uniledportal.campaign_filter.update',
    UniledportalCampaign_filterDestroy = 'uniledportal.campaign_filter.destroy',
    UniledportalCampaign_ownerCreate = 'uniledportal.campaign_owner.create',
    UniledportalCampaign_ownerRead = 'uniledportal.campaign_owner.read',
    UniledportalCampaign_ownerUpdate = 'uniledportal.campaign_owner.update',
    UniledportalCampaign_ownerDestroy = 'uniledportal.campaign_owner.destroy',
    UniledportalCampaign_packCreate = 'uniledportal.campaign_pack.create',
    UniledportalCampaign_packRead = 'uniledportal.campaign_pack.read',
    UniledportalCampaign_packUpdate = 'uniledportal.campaign_pack.update',
    UniledportalCampaign_packDestroy = 'uniledportal.campaign_pack.destroy',
    UniledportalCampaign_resolutionCreate = 'uniledportal.campaign_resolution.create',
    UniledportalCampaign_resolutionRead = 'uniledportal.campaign_resolution.read',
    UniledportalCampaign_resolutionUpdate = 'uniledportal.campaign_resolution.update',
    UniledportalCampaign_resolutionDestroy = 'uniledportal.campaign_resolution.destroy',
    UniledportalCampaign_tagCreate = 'uniledportal.campaign_tag.create',
    UniledportalCampaign_tagRead = 'uniledportal.campaign_tag.read',
    UniledportalCampaign_tagUpdate = 'uniledportal.campaign_tag.update',
    UniledportalCampaign_tagDestroy = 'uniledportal.campaign_tag.destroy',
    UniledportalChannelCreate = 'uniledportal.channel.create',
    UniledportalChannelRead = 'uniledportal.channel.read',
    UniledportalChannelUpdate = 'uniledportal.channel.update',
    UniledportalChannelDestroy = 'uniledportal.channel.destroy',
    UniledportalClientCreate = 'uniledportal.client.create',
    UniledportalClientRead = 'uniledportal.client.read',
    UniledportalClientUpdate = 'uniledportal.client.update',
    UniledportalClientDestroy = 'uniledportal.client.destroy',
    UniledportalClient_userCreate = 'uniledportal.client_user.create',
    UniledportalClient_userRead = 'uniledportal.client_user.read',
    UniledportalClient_userUpdate = 'uniledportal.client_user.update',
    UniledportalClient_userDestroy = 'uniledportal.client_user.destroy',
    UniledportalCreativeCreate = 'uniledportal.creative.create',
    UniledportalCreativeRead = 'uniledportal.creative.read',
    UniledportalCreativeUpdate = 'uniledportal.creative.update',
    UniledportalCreativeDestroy = 'uniledportal.creative.destroy',
    UniledportalCreative_all_groupCreate = 'uniledportal.creative_all_group.create',
    UniledportalCreative_all_groupRead = 'uniledportal.creative_all_group.read',
    UniledportalCreative_all_groupUpdate = 'uniledportal.creative_all_group.update',
    UniledportalCreative_all_groupDestroy = 'uniledportal.creative_all_group.destroy',
    UniledportalCreative_creativeCreate = 'uniledportal.creative_creative.create',
    UniledportalCreative_creativeRead = 'uniledportal.creative_creative.read',
    UniledportalCreative_creativeUpdate = 'uniledportal.creative_creative.update',
    UniledportalCreative_creativeDestroy = 'uniledportal.creative_creative.destroy',
    UniledportalCreative_userCreate = 'uniledportal.creative_user.create',
    UniledportalCreative_userRead = 'uniledportal.creative_user.read',
    UniledportalCreative_userUpdate = 'uniledportal.creative_user.update',
    UniledportalCreative_userDestroy = 'uniledportal.creative_user.destroy',
    UniledportalDateCreate = 'uniledportal.date.create',
    UniledportalDateRead = 'uniledportal.date.read',
    UniledportalDateUpdate = 'uniledportal.date.update',
    UniledportalDateDestroy = 'uniledportal.date.destroy',
    UniledportalDisplayCreate = 'uniledportal.display.create',
    UniledportalDisplayRead = 'uniledportal.display.read',
    UniledportalDisplayUpdate = 'uniledportal.display.update',
    UniledportalDisplayDestroy = 'uniledportal.display.destroy',
    UniledportalDisplay_brochureCreate = 'uniledportal.display_brochure.create',
    UniledportalDisplay_brochureRead = 'uniledportal.display_brochure.read',
    UniledportalDisplay_brochureUpdate = 'uniledportal.display_brochure.update',
    UniledportalDisplay_brochureDestroy = 'uniledportal.display_brochure.destroy',
    UniledportalDisplay_creativeCreate = 'uniledportal.display_creative.create',
    UniledportalDisplay_creativeRead = 'uniledportal.display_creative.read',
    UniledportalDisplay_creativeUpdate = 'uniledportal.display_creative.update',
    UniledportalDisplay_creativeDestroy = 'uniledportal.display_creative.destroy',
    UniledportalDisplay_groupCreate = 'uniledportal.display_group.create',
    UniledportalDisplay_groupRead = 'uniledportal.display_group.read',
    UniledportalDisplay_groupUpdate = 'uniledportal.display_group.update',
    UniledportalDisplay_groupDestroy = 'uniledportal.display_group.destroy',
    UniledportalDisplay_packageCreate = 'uniledportal.display_package.create',
    UniledportalDisplay_packageRead = 'uniledportal.display_package.read',
    UniledportalDisplay_packageUpdate = 'uniledportal.display_package.update',
    UniledportalDisplay_packageDestroy = 'uniledportal.display_package.destroy',
    UniledportalDisplay_resolutionCreate = 'uniledportal.display_resolution.create',
    UniledportalDisplay_resolutionRead = 'uniledportal.display_resolution.read',
    UniledportalDisplay_resolutionUpdate = 'uniledportal.display_resolution.update',
    UniledportalDisplay_resolutionDestroy = 'uniledportal.display_resolution.destroy',
    UniledportalDisplay_ruleCreate = 'uniledportal.display_rule.create',
    UniledportalDisplay_ruleRead = 'uniledportal.display_rule.read',
    UniledportalDisplay_ruleUpdate = 'uniledportal.display_rule.update',
    UniledportalDisplay_ruleDestroy = 'uniledportal.display_rule.destroy',
    UniledportalDisplay_tagCreate = 'uniledportal.display_tag.create',
    UniledportalDisplay_tagRead = 'uniledportal.display_tag.read',
    UniledportalDisplay_tagUpdate = 'uniledportal.display_tag.update',
    UniledportalDisplay_tagDestroy = 'uniledportal.display_tag.destroy',
    UniledportalFileCreate = 'uniledportal.file.create',
    UniledportalFileRead = 'uniledportal.file.read',
    UniledportalFileUpdate = 'uniledportal.file.update',
    UniledportalFileDestroy = 'uniledportal.file.destroy',
    UniledportalFile_backup20200421Create = 'uniledportal.file_backup20200421.create',
    UniledportalFile_backup20200421Read = 'uniledportal.file_backup20200421.read',
    UniledportalFile_backup20200421Update = 'uniledportal.file_backup20200421.update',
    UniledportalFile_backup20200421Destroy = 'uniledportal.file_backup20200421.destroy',
    UniledPortalHappyDemicsRead = 'uniledportal.happydemics.read',
    UniledportalFilterCreate = 'uniledportal.filter.create',
    UniledportalFilterRead = 'uniledportal.filter.read',
    UniledportalFilterUpdate = 'uniledportal.filter.update',
    UniledportalFilterDestroy = 'uniledportal.filter.destroy',
    UniledportalFilter_displayCreate = 'uniledportal.filter_display.create',
    UniledportalFilter_displayRead = 'uniledportal.filter_display.read',
    UniledportalFilter_displayUpdate = 'uniledportal.filter_display.update',
    UniledportalFilter_displayDestroy = 'uniledportal.filter_display.destroy',
    UniledportalFilter_groupCreate = 'uniledportal.filter_group.create',
    UniledportalFilter_groupRead = 'uniledportal.filter_group.read',
    UniledportalFilter_groupUpdate = 'uniledportal.filter_group.update',
    UniledportalFilter_groupDestroy = 'uniledportal.filter_group.destroy',
    UniledportalFilter_ownerCreate = 'uniledportal.filter_owner.create',
    UniledportalFilter_ownerRead = 'uniledportal.filter_owner.read',
    UniledportalFilter_ownerUpdate = 'uniledportal.filter_owner.update',
    UniledportalFilter_ownerDestroy = 'uniledportal.filter_owner.destroy',
    UniledportalFilter_packCreate = 'uniledportal.filter_pack.create',
    UniledportalFilter_packRead = 'uniledportal.filter_pack.read',
    UniledportalFilter_packUpdate = 'uniledportal.filter_pack.update',
    UniledportalFilter_packDestroy = 'uniledportal.filter_pack.destroy',
    UniledportalFilter_resolutionCreate = 'uniledportal.filter_resolution.create',
    UniledportalFilter_resolutionRead = 'uniledportal.filter_resolution.read',
    UniledportalFilter_resolutionUpdate = 'uniledportal.filter_resolution.update',
    UniledportalFilter_resolutionDestroy = 'uniledportal.filter_resolution.destroy',
    UniledportalFilter_tagCreate = 'uniledportal.filter_tag.create',
    UniledportalFilter_tagRead = 'uniledportal.filter_tag.read',
    UniledportalFilter_tagUpdate = 'uniledportal.filter_tag.update',
    UniledportalFilter_tagDestroy = 'uniledportal.filter_tag.destroy',
    UniledportalGalleryCreate = 'uniledportal.gallery.create',
    UniledportalGalleryRead = 'uniledportal.gallery.read',
    UniledportalGalleryUpdate = 'uniledportal.gallery.update',
    UniledportalGalleryDestroy = 'uniledportal.gallery.destroy',
    UniledportalHourCreate = 'uniledportal.hour.create',
    UniledportalHourRead = 'uniledportal.hour.read',
    UniledportalHourUpdate = 'uniledportal.hour.update',
    UniledportalHourDestroy = 'uniledportal.hour.destroy',
    UniledportalInvoiceCreate = 'uniledportal.invoice.create',
    UniledportalInvoiceRead = 'uniledportal.invoice.read',
    UniledportalInvoiceUpdate = 'uniledportal.invoice.update',
    UniledportalInvoiceDestroy = 'uniledportal.invoice.destroy',
    UniledportalInvoice_creativeCreate = 'uniledportal.invoice_creative.create',
    UniledportalInvoice_creativeRead = 'uniledportal.invoice_creative.read',
    UniledportalInvoice_creativeUpdate = 'uniledportal.invoice_creative.update',
    UniledportalInvoice_creativeDestroy = 'uniledportal.invoice_creative.destroy',
    UniledportalLocationCreate = 'uniledportal.location.create',
    UniledportalLocationRead = 'uniledportal.location.read',
    UniledportalLocationUpdate = 'uniledportal.location.update',
    UniledportalLocationDestroy = 'uniledportal.location.destroy',
    UniledportalMarketCreate = 'uniledportal.market.create',
    UniledportalMarketRead = 'uniledportal.market.read',
    UniledportalMarketUpdate = 'uniledportal.market.update',
    UniledportalMarketDestroy = 'uniledportal.market.destroy',
    UniledportalMedia_creativeCreate = 'uniledportal.media_creative.create',
    UniledportalMedia_creativeRead = 'uniledportal.media_creative.read',
    UniledportalMedia_creativeUpdate = 'uniledportal.media_creative.update',
    UniledportalMedia_creativeDestroy = 'uniledportal.media_creative.destroy',
    UniledportalNoteCreate = 'uniledportal.note.create',
    UniledportalNoteRead = 'uniledportal.note.read',
    UniledportalNoteUpdate = 'uniledportal.note.update',
    UniledportalNoteDestroy = 'uniledportal.note.destroy',
    UniledportalNote_dateCreate = 'uniledportal.note_date.create',
    UniledportalNote_dateRead = 'uniledportal.note_date.read',
    UniledportalNote_dateUpdate = 'uniledportal.note_date.update',
    UniledportalNote_dateDestroy = 'uniledportal.note_date.destroy',
    UniledportalOwnerCreate = 'uniledportal.owner.create',
    UniledportalOwnerRead = 'uniledportal.owner.read',
    UniledportalOwnerUpdate = 'uniledportal.owner.update',
    UniledportalOwnerDestroy = 'uniledportal.owner.destroy',
    UniledportalOwner_userCreate = 'uniledportal.owner_user.create',
    UniledportalOwner_userRead = 'uniledportal.owner_user.read',
    UniledportalOwner_userUpdate = 'uniledportal.owner_user.update',
    UniledportalOwner_userDestroy = 'uniledportal.owner_user.destroy',
    UniledportalPackCreate = 'uniledportal.pack.create',
    UniledportalPackRead = 'uniledportal.pack.read',
    UniledportalPackUpdate = 'uniledportal.pack.update',
    UniledportalPackDestroy = 'uniledportal.pack.destroy',
    UniledportalPack_displayCreate = 'uniledportal.pack_display.create',
    UniledportalPack_displayRead = 'uniledportal.pack_display.read',
    UniledportalPack_displayUpdate = 'uniledportal.pack_display.update',
    UniledportalPack_displayDestroy = 'uniledportal.pack_display.destroy',
    UniledportalPack_groupCreate = 'uniledportal.pack_group.create',
    UniledportalPack_groupRead = 'uniledportal.pack_group.read',
    UniledportalPack_groupUpdate = 'uniledportal.pack_group.update',
    UniledportalPack_groupDestroy = 'uniledportal.pack_group.destroy',
    UniledportalPlayCreate = 'uniledportal.play.create',
    UniledportalPlayRead = 'uniledportal.play.read',
    UniledportalPlayUpdate = 'uniledportal.play.update',
    UniledportalPlayDestroy = 'uniledportal.play.destroy',
    UniledportalPlaylistCreate = 'uniledportal.playlist.create',
    UniledportalPlaylistRead = 'uniledportal.playlist.read',
    UniledportalPlaylistUpdate = 'uniledportal.playlist.update',
    UniledportalPlaylistDestroy = 'uniledportal.playlist.destroy',
    UniledportalPostCreate = 'uniledportal.post.create',
    UniledportalPostRead = 'uniledportal.post.read',
    UniledportalPostUpdate = 'uniledportal.post.update',
    UniledportalPostDestroy = 'uniledportal.post.destroy',
    UniledportalPostalCreate = 'uniledportal.postal.create',
    UniledportalPostalRead = 'uniledportal.postal.read',
    UniledportalPostalUpdate = 'uniledportal.postal.update',
    UniledportalPostalDestroy = 'uniledportal.postal.destroy',
    UniledportalPostcodeCreate = 'uniledportal.postcode.create',
    UniledportalPostcodeRead = 'uniledportal.postcode.read',
    UniledportalPostcodeUpdate = 'uniledportal.postcode.update',
    UniledportalPostcodeDestroy = 'uniledportal.postcode.destroy',
    UniledportalQueue_cronCreate = 'uniledportal.queue_cron.create',
    UniledportalQueue_cronRead = 'uniledportal.queue_cron.read',
    UniledportalQueue_cronUpdate = 'uniledportal.queue_cron.update',
    UniledportalQueue_cronDestroy = 'uniledportal.queue_cron.destroy',
    UniledportalReminderCreate = 'uniledportal.reminder.create',
    UniledportalReminderRead = 'uniledportal.reminder.read',
    UniledportalReminderUpdate = 'uniledportal.reminder.update',
    UniledportalReminderDestroy = 'uniledportal.reminder.destroy',
    UniledportalRequestCreate = 'uniledportal.request.create',
    UniledportalRequestRead = 'uniledportal.request.read',
    UniledportalRequestUpdate = 'uniledportal.request.update',
    UniledportalRequestDestroy = 'uniledportal.request.destroy',
    UniledportalResolutionCreate = 'uniledportal.resolution.create',
    UniledportalResolutionRead = 'uniledportal.resolution.read',
    UniledportalResolutionUpdate = 'uniledportal.resolution.update',
    UniledportalResolutionDestroy = 'uniledportal.resolution.destroy',
    UniledportalRuleCreate = 'uniledportal.rule.create',
    UniledportalRuleRead = 'uniledportal.rule.read',
    UniledportalRuleUpdate = 'uniledportal.rule.update',
    UniledportalRuleDestroy = 'uniledportal.rule.destroy',
    UniledportalSlotCreate = 'uniledportal.slot.create',
    UniledportalSlotRead = 'uniledportal.slot.read',
    UniledportalSlotUpdate = 'uniledportal.slot.update',
    UniledportalSlotDestroy = 'uniledportal.slot.destroy',
    UniledportalSnapshotCreate = 'uniledportal.snapshot.create',
    UniledportalSnapshotRead = 'uniledportal.snapshot.read',
    UniledportalSnapshotUpdate = 'uniledportal.snapshot.update',
    UniledportalSnapshotDestroy = 'uniledportal.snapshot.destroy',
    UniledportalStatCreate = 'uniledportal.stat.create',
    UniledportalStatRead = 'uniledportal.stat.read',
    UniledportalStatUpdate = 'uniledportal.stat.update',
    UniledportalStatDestroy = 'uniledportal.stat.destroy',
    UniledportalStoreCreate = 'uniledportal.store.create',
    UniledportalStoreRead = 'uniledportal.store.read',
    UniledportalStoreUpdate = 'uniledportal.store.update',
    UniledportalStoreDestroy = 'uniledportal.store.destroy',
    UniledportalStreamCreate = 'uniledportal.stream.create',
    UniledportalStreamRead = 'uniledportal.stream.read',
    UniledportalStreamUpdate = 'uniledportal.stream.update',
    UniledportalStreamDestroy = 'uniledportal.stream.destroy',
    UniledportalTagCreate = 'uniledportal.tag.create',
    UniledportalTagRead = 'uniledportal.tag.read',
    UniledportalTagUpdate = 'uniledportal.tag.update',
    UniledportalTagDestroy = 'uniledportal.tag.destroy',
    UniledportalTag_tagCreate = 'uniledportal.tag_tag.create',
    UniledportalTag_tagRead = 'uniledportal.tag_tag.read',
    UniledportalTag_tagUpdate = 'uniledportal.tag_tag.update',
    UniledportalTag_tagDestroy = 'uniledportal.tag_tag.destroy',
    UniledportalTimestampsCreate = 'uniledportal.timestamps.create',
    UniledportalTimestampsRead = 'uniledportal.timestamps.read',
    UniledportalTimestampsUpdate = 'uniledportal.timestamps.update',
    UniledportalTimestampsDestroy = 'uniledportal.timestamps.destroy',
    UniledportalUserCreate = 'uniledportal.user.create',
    UniledportalUserRead = 'uniledportal.user.read',
    UniledportalUserUpdate = 'uniledportal.user.update',
    UniledportalUserDestroy = 'uniledportal.user.destroy',
    UniledportalUser_agencyCreate = 'uniledportal.user_agency.create',
    UniledportalUser_agencyRead = 'uniledportal.user_agency.read',
    UniledportalUser_agencyUpdate = 'uniledportal.user_agency.update',
    UniledportalUser_agencyDestroy = 'uniledportal.user_agency.destroy',
    UniledportalUser_buyerCreate = 'uniledportal.user_buyer.create',
    UniledportalUser_buyerRead = 'uniledportal.user_buyer.read',
    UniledportalUser_buyerUpdate = 'uniledportal.user_buyer.update',
    UniledportalUser_buyerDestroy = 'uniledportal.user_buyer.destroy',
    UniledportalCampaign_userCreate = 'uniledportal.campaign_user.create',
    UniledportalCampaign_userRead = 'uniledportal.campaign_user.read',
    UniledportalCampaign_userUpdate = 'uniledportal.campaign_user.update',
    UniledportalCampaign_userDestroy = 'uniledportal.campaign_user.destroy',
    UniledportalUser_channelCreate = 'uniledportal.user_channel.create',
    UniledportalUser_channelRead = 'uniledportal.user_channel.read',
    UniledportalUser_channelUpdate = 'uniledportal.user_channel.update',
    UniledportalUser_channelDestroy = 'uniledportal.user_channel.destroy',
    UniledportalUser_clientCreate = 'uniledportal.user_client.create',
    UniledportalUser_clientRead = 'uniledportal.user_client.read',
    UniledportalUser_clientUpdate = 'uniledportal.user_client.update',
    UniledportalUser_clientDestroy = 'uniledportal.user_client.destroy',
    UniledportalUser_creativeCreate = 'uniledportal.user_creative.create',
    UniledportalUser_creativeRead = 'uniledportal.user_creative.read',
    UniledportalUser_creativeUpdate = 'uniledportal.user_creative.update',
    UniledportalUser_creativeDestroy = 'uniledportal.user_creative.destroy',
    UniledportalUser_displayCreate = 'uniledportal.user_display.create',
    UniledportalUser_displayRead = 'uniledportal.user_display.read',
    UniledportalUser_displayUpdate = 'uniledportal.user_display.update',
    UniledportalUser_displayDestroy = 'uniledportal.user_display.destroy',
    UniledportalUser_invoiceCreate = 'uniledportal.user_invoice.create',
    UniledportalUser_invoiceRead = 'uniledportal.user_invoice.read',
    UniledportalUser_invoiceUpdate = 'uniledportal.user_invoice.update',
    UniledportalUser_invoiceDestroy = 'uniledportal.user_invoice.destroy',
    UniledportalUser_ownerCreate = 'uniledportal.user_owner.create',
    UniledportalUser_ownerRead = 'uniledportal.user_owner.read',
    UniledportalUser_ownerUpdate = 'uniledportal.user_owner.update',
    UniledportalUser_ownerDestroy = 'uniledportal.user_owner.destroy',
    UniledportalUser_packCreate = 'uniledportal.user_pack.create',
    UniledportalUser_packRead = 'uniledportal.user_pack.read',
    UniledportalUser_packUpdate = 'uniledportal.user_pack.update',
    UniledportalUser_packDestroy = 'uniledportal.user_pack.destroy',
    UniledportalUser_requestCreate = 'uniledportal.user_request.create',
    UniledportalUser_requestRead = 'uniledportal.user_request.read',
    UniledportalUser_requestUpdate = 'uniledportal.user_request.update',
    UniledportalUser_requestDestroy = 'uniledportal.user_request.destroy',
    UniledportalUser_streamCreate = 'uniledportal.user_stream.create',
    UniledportalUser_streamRead = 'uniledportal.user_stream.read',
    UniledportalUser_streamUpdate = 'uniledportal.user_stream.update',
    UniledportalUser_streamDestroy = 'uniledportal.user_stream.destroy',
    UniledportalUser_webcamCreate = 'uniledportal.user_webcam.create',
    UniledportalUser_webcamRead = 'uniledportal.user_webcam.read',
    UniledportalUser_webcamUpdate = 'uniledportal.user_webcam.update',
    UniledportalUser_webcamDestroy = 'uniledportal.user_webcam.destroy',
    UniledportalVersionCreate = 'uniledportal.version.create',
    UniledportalVersionRead = 'uniledportal.version.read',
    UniledportalVersionUpdate = 'uniledportal.version.update',
    UniledportalVersionDestroy = 'uniledportal.version.destroy',
    UniledportalWebcamCreate = 'uniledportal.webcam.create',
    UniledportalWebcamRead = 'uniledportal.webcam.read',
    UniledportalWebcamUpdate = 'uniledportal.webcam.update',
    UniledportalWebcamDestroy = 'uniledportal.webcam.destroy',
    ReportingPcareportRead = 'reporting.pcareport.read',
    PowerpointPcareportRead = 'reporting.powerpoint',
    UniledportalSkinCreate = 'uniledportal.skin.create',
    UniledportalSkinRead = 'uniledportal.skin.read',
    UniledportalSkinUpdate = 'uniledportal.skin.update',
    UniledportalSkinDestroy = 'uniledportal.skin.destroy',
    UniledportalLanguageCreate = 'uniledportal.language.create',
    UniledportalLanguageRead = 'uniledportal.language.read',
    UniledportalLanguageUpdate = 'uniledportal.language.update',
    UniledportalLanguageDestroy = 'uniledportal.language.destroy',
    UniledportalEarly_access = 'uniledportal.early_access',
    UniledportalRoleDestroy = 'uniledportal.role.destroy',
    UniledportalRoleUpdate = 'uniledportal.role.update',
    UniledportalRoleRead = 'uniledportal.role.read',
    UniledportalRoleCreate = 'uniledportal.role.create',
    AdminScopingCampaignIgnore = 'admin.scoping.campaign.ignore',
    Admin = 'admin',
    AdminScopingClientIgnore = 'admin.scoping.client.ignore',
    AdminScopingCreativeIgnore = 'admin.scoping.creative.ignore',
    AdminCan_impersonate_user = 'admin.can_impersonate_user',
    UniledportalCampaignCreativeDownload = 'uniledportal.campaign.creative.download',
    UniledportalCampaignSpecsDownload = 'uniledportal.campaign.specs.download',
    AdminCan_change_passwords = 'admin.can_change_passwords',
    FilteringCampaignClient = 'filtering.campaign.client',
    FilteringCampaignBuyer = 'filtering.campaign.buyer',
    FilteringCampaignMarket = 'filtering.campaign.market',
    UniledportalBuyer_costCreate = 'uniledportal.buyer_cost.create',
    UniledportalBuyer_costRead = 'uniledportal.buyer_cost.read',
    UniledportalBudget_summaryStatus_change = 'uniledportal.budget_summary.status_change',
    UniledportalBudget_summaryOverride = 'uniledportal.budget_summary.override',
    DisplaysLine_itemsCreate = 'displays.line_items.create',
    DisplaysLine_itemsRead = 'displays.line_items.read',
    UniledportalBudget_summary_line_itemCreate = 'uniledportal.budget_summary_line_item.create',
    UniledportalBudget_summary_line_itemRead = 'uniledportal.budget_summary_line_item.read',
    UniledportalBudget_summary_line_itemUpdate = 'uniledportal.budget_summary_line_item.update',
    UniledportalBudget_summaryCreate = 'uniledportal.budget_summary.create',
    UniledportalBudget_summaryRead = 'uniledportal.budget_summary.read',
    UniledportalBudget_summaryUpdate = 'uniledportal.budget_summary.update',
    AdminScopingBuyersIgnore = 'admin.scoping.buyers.ignore',
    AdminScopingLine_itemsIgnore = 'admin.scoping.line_items.ignore',
    AdminScopingMarketsIgnore = 'admin.scoping.markets.ignore',
    DisplaysDisplay_issuesCreate = 'displays.display_issues.create',
    DisplaysDisplay_issuesRead = 'displays.display_issues.read',
    DisplaysDisplay_issuesUpdate = 'displays.display_issues.update',
    DisplaysDisplay_issuesDelete = 'displays.display_issues.delete',
    AdminCan_set_display_notices = 'admin.can_set_display_notices',
    DisplaysLine_item_linksRead = 'displays.line_item_links.read',
    DisplaysDisplay_issues_pivotRead = 'displays.display_issues_pivot.read',
    UniledportalCreative_groupCreate = 'uniledportal.creative_group.create',
    UniledportalCreative_groupRead = 'uniledportal.creative_group.read',
    UniledportalCreative_groupUpdate = 'uniledportal.creative_group.update',
    UniledportalCreative_groupDestroy = 'uniledportal.creative_group.destroy',
    AdminCan_read_pca_sandbox = 'admin.can_read_pca_sandbox',
    Pop_displaysPca_reports_rawRead = 'pop_displays.pca_reports_raw.read',
    Pop_displaysPca_reports_rawUpdate = 'pop_displays.pca_reports_raw.update',
    Pop_displaysPca_reports_dailyRead = 'pop_displays.pca_reports_daily.read',
    Pop_displaysPca_reports_dailyUpdate = 'pop_displays.pca_reports_daily.update',
    ReportingPcareportDownload = 'reporting.pcareport.download',
    DashboardReporting = 'reporting.campaign_reports',
    CampaignBooking_summaryUpdate = 'campaign.booking_summary.update',
    UniledportalBuyer_costUpdate = 'uniledportal.buyer_cost.update',
    GeneralView_creatives = 'general.view_creatives',
    Internal_adminCampaign_sitelist_displayCreate = 'internal_admin.campaign_sitelist_display.create',
    Internal_adminCampaign_sitelist_displayRead = 'internal_admin.campaign_sitelist_display.read',
    Internal_adminCampaign_sitelist_displayUpdate = 'internal_admin.campaign_sitelist_display.update',
    Internal_adminCampaign_sitelist_displayDestroy = 'internal_admin.campaign_sitelist_display.destroy',
    UniledportalNotificationCreate = 'uniledportal.notification.create',
    UniledportalNotificationUpdate = 'uniledportal.notification.update',
    UniledportalCreativeDownload = 'uniledportal.creative.download',
    UniledportalCampaign_errorCreate = 'uniledportal.campaign_error.create',
    UniledportalCampaign_errorRead = 'uniledportal.campaign_error.read',
    UniledportalCampaign_errorUpdate = 'uniledportal.campaign_error.update',
    UniledportalCampaign_errorDestroy = 'uniledportal.campaign_error.destroy',
    Pop_displaysCreative_searchRead = 'pop_displays.creative_search.read',
    Pop_displaysSitelistUpload = 'pop_displays.sitelist.upload',
    Pop_displaysVirtual_campaignCreate = 'pop_displays.virtual_campaign.create',
    AdminCampaignCan_be_internal_owner = 'admin.campaign.can_be_internal_owner',
    AdminCampaignCan_be_internal_assignee = 'admin.campaign.can_be_internal_assignee',
    UniledportalCampaign_booking_summariesCreate = 'uniledportal.campaign_booking_summaries.create',
    UniledportalCampaign_booking_summariesRead = 'uniledportal.campaign_booking_summaries.read',
    UniledportalCampaign_booking_summariesUpdate = 'uniledportal.campaign_booking_summaries.update',
    UniledportalCampaign_booking_summariesDestroy = 'uniledportal.campaign_booking_summaries.destroy',
    UniledPortalCampaign_booking_summariesNotesUpdate = 'uniledportal.campaign_booking_summaries_notes.update',
    UniledportalCampaign_booking_summariesNotesRead = 'uniledportal.campaign_booking_summaries_notes.read',
    CampaignCan_invite_user = 'campaign.can_invite_user',
    DisplaysResolutionsRead = 'displays.resolutions.read',
    GeneralView_data_status = 'general.view_data_status',
    GeneralView_data_status_booking = 'general.view_data_status_booking',
    UniledportalCreativeStart_end_dateEdit = 'uniledportal.creative.start_end_date.edit',
    DisplaysAudience_versionRead = 'displays.audience_version.read',
    Pop_displaysLog_source_accountsCreate = 'pop_displays.log_source_accounts.create',
    Pop_displaysLog_source_accountsRead = 'pop_displays.log_source_accounts.read',
    Pop_displaysLog_source_accountsUpdate = 'pop_displays.log_source_accounts.update',
    Pop_displaysLog_source_campaign_identifierCreate = 'pop_displays.log_source_campaign_identifier.create',
    Pop_displaysLog_source_campaign_identifierRead = 'pop_displays.log_source_campaign_identifier.read',
    Pop_displaysLog_source_campaign_identifierUpdate = 'pop_displays.log_source_campaign_identifier.update',
    Internal_adminCampaign_missing_displaysCreate = 'internal_admin.campaign_missing_displays.create',
    Internal_adminCampaign_missing_displaysRead = 'internal_admin.campaign_missing_displays.read',
    Internal_adminCampaign_missing_displaysUpdate = 'internal_admin.campaign_missing_displays.update',
    Internal_adminCampaign_missing_displaysDestroy = 'internal_admin.campaign_missing_displays.destroy',
    UniledportalLogin_attemptsCreate = 'uniledportal.login_attempts.create',
    UniledportalLogin_attemptsRead = 'uniledportal.login_attempts.read',
    UniledportalLogin_attemptsUpdate = 'uniledportal.login_attempts.update',
    UniledportalLogin_attemptsDestroy = 'uniledportal.login_attempts.destroy',
    DisplaysDisplays_vendor_idsCreate = 'displays.displays_vendor_ids.create',
    DisplaysDisplays_vendor_idsRead = 'displays.displays_vendor_ids.read',
    DisplaysDisplays_vendor_idsUpdate = 'displays.displays_vendor_ids.update',
    DisplaysDisplays_vendor_idsDestroy = 'displays.displays_vendor_ids.destroy',
    Pop_displaysDisplaysLog_file_parsed_mediaRead = 'pop_displays.displays.log_file_parsed_media.read',
    Pop_displaysLog_file_parsedPlayout_export = 'pop_displays.log_file_parsed.playout_export',
    UniledportalLocaleRead = 'uniledportal.locale.read',
    GeneralReset_data_status = 'general.reset_data_status',
    UniledportalCampaign_instructionsCreate = 'uniledportal.campaign_instructions.create',
    UniledportalCampaign_instructionsRead = 'uniledportal.campaign_instructions.read',
    UniledportalCampaign_instructionsUpdate = 'uniledportal.campaign_instructions.update',
    UniledportalCampaign_instruction_filesCreate = 'uniledportal.campaign_instruction_files.create',
    UniledportalCampaign_instruction_filesRead = 'uniledportal.campaign_instruction_files.read',
    UniledportalCampaign_instruction_filesUpdate = 'uniledportal.campaign_instruction_files.update',
    UniledportalCreative_creative_groupCreate = 'uniledportal.creative_creative_group.create',
    UniledportalCreative_creative_groupRead = 'uniledportal.creative_creative_group.read',
    UniledportalCreative_creative_groupUpdate = 'uniledportal.creative_creative_group.update',
    UniledportalCreative_creative_groupDestroy = 'uniledportal.creative_creative_group.destroy',
    UniledportalCampaign_owner_contacted_datesCreate = 'uniledportal.campaign_owner_contacted_dates.create',
    UniledportalCampaign_owner_contacted_datesRead = 'uniledportal.campaign_owner_contacted_dates.read',
    UniledportalCampaign_owner_contacted_datesUpdate = 'uniledportal.campaign_owner_contacted_dates.update',
    UniliveapiSchedulesCreate = 'uniliveapi.schedules.create',
    UniliveapiSchedulesRead = 'uniliveapi.schedules.read',
    UniliveapiSchedulesUpdate = 'uniliveapi.schedules.update',
    UniliveapiSchedulesDestroy = 'uniliveapi.schedules.destroy',
    UniliveapiRulesCreate = 'uniliveapi.rules.create',
    UniliveapiRulesRead = 'uniliveapi.rules.read',
    UniliveapiRulesUpdate = 'uniliveapi.rules.update',
    UniliveapiRulesDestroy = 'uniliveapi.rules.destroy',
    UniledportalAlertsCreate = 'uniledportal.alerts.create',
    UniledportalAlertsRead = 'uniledportal.alerts.read',
    UniledportalAlertsUpdate = 'uniledportal.alerts.update',
    UniledportalAlertsDestroy = 'uniledportal.alerts.destroy',
    UniledportalPortal_settingsCreate = 'uniledportal.portal_settings.create',
    UniledportalPortal_settingsRead = 'uniledportal.portal_settings.read',
    UniledportalPortal_settingsUpdate = 'uniledportal.portal_settings.update',
    UniledportalPortal_settingsDestroy = 'uniledportal.portal_settings.destroy',
    AdminScopingCreative_groupIgnore = 'admin.scoping.creative_group.ignore',
    UniledportalInviteRead = 'uniledportal.invite.read',
    UniliveapiSchedulesSitelist_upload = 'uniliveapi.schedules.sitelist_upload',
    UniliveapiSchedulablesCreate = 'uniliveapi.schedulables.create',
    UniliveapiSchedulablesRead = 'uniliveapi.schedulables.read',
    UniliveapiSchedulablesUpdate = 'uniliveapi.schedulables.update',
    UniliveapiSchedulablesDestroy = 'uniliveapi.schedulables.destroy',
    DisplaysAudience_fallbackCreate = 'displays.audience_fallback.create',
    DisplaysAnalogue_screensBrochureDownload = 'displays.analogue_screens.brochure.download',
    Internal_adminCampaign_sitelistCreate = 'internal_admin.campaign_sitelist.create',
    Internal_adminCampaign_sitelistRead = 'internal_admin.campaign_sitelist.read',
    Internal_adminCampaign_sitelistUpdate = 'internal_admin.campaign_sitelist.update',
    Internal_adminCampaign_sitelistDestroy = 'internal_admin.campaign_sitelist.destroy',
    Internal_adminCampaign_sitelist_unknownCreate = 'internal_admin.campaign_sitelist_unknown.create',
    Internal_adminCampaign_sitelist_unknownRead = 'internal_admin.campaign_sitelist_unknown.read',
    Internal_adminCampaign_sitelist_unknownUpdate = 'internal_admin.campaign_sitelist_unknown.update',
    Internal_adminCampaign_sitelist_unknownDestroy = 'internal_admin.campaign_sitelist_unknown.destroy',
    DisplaysScreensImport = 'displays.screens.import',
    Pop_displaysChannel_framesExport = 'pop_displays.channel_frames.export',
    UniledportalCampaign_pop_shotsCreate = 'uniledportal.campaign_pop_shots.create',
    UniledportalCampaign_pop_shotsRead = 'uniledportal.campaign_pop_shots.read',
    UniledportalCampaign_pop_shotsUpdate = 'uniledportal.campaign_pop_shots.update',
    AdminScopingCampaign_pop_shotsIgnore = 'admin.scoping.campaign_pop_shots.ignore',
    UniledportalCampaign_noticesRead = 'uniledportal.campaign_notices.read',
    UniledportalCampaign_pop_shotsDestroy = 'uniledportal.campaign_pop_shots.destroy',
    DisplaysAudienceRead = 'displays.audience.read',
    ImpressionMultiplierRead = 'reporting.impression_multipliers.read',
}
