import React, { useMemo } from 'react';
import { LoadScript, LoadScriptProps } from '@react-google-maps/api';
// eslint-disable-next-line import/no-cycle
import {
    CampaignCosts,
    CampaignCreativeManagement,
    CampaignReportingWrapper,
    CampaignOverviewDisplays,
} from 'c-main/Components';
import { LinkButton, TabsWrapper } from 'c-components';
import { CampaignErrorableType, PermissionName, Campaign } from 'c-sdk';
import { TabWrapperSettings } from 'c-types';
import { useEntityData } from 'c-data';
import { MAIN_CAMPAIGN_TABS_ID } from 'c-main/Components/constants';
import { Badge, Box, Tooltip, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RocketLogo from 'c-assets/rocket.png';
import { PostAuthRoutes } from 'c-routes';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';
import CampaignNotices from 'c-main/Components/Campaign/CampaignReports/CampaignNotices';
import HappyDemicsTabContent from 'c-main/Components/HappyDemics/HappyDemicsTabContent';
import CampaignOverviewPopShotsParentTab from '../CampaignOverviewPopShotsTab/CampaignOverviewPopShotsParentTab';
import PCATabControls from './PCATabControls';

type Props = {
    // add custom props
    id: number;
};

const tabListProps = {
    id: MAIN_CAMPAIGN_TABS_ID,
};

const displaysPerms = [
    PermissionName.UniledportalDisplayRead,
    PermissionName.UniledportalCampaign_displayRead,
    PermissionName.UniledportalBudget_summary_line_itemRead,
];

const popImagePerms = [...displaysPerms, PermissionName.UniledportalCampaign_pop_shotsRead];

const creativesPerms = [
    PermissionName.UniledportalCreativeRead,
    PermissionName.UniledportalCreative_groupRead,
    PermissionName.UniledportalFileRead,
];
const reportsPerms = [PermissionName.ReportingPcareportRead];
const costPerms = [
    PermissionName.UniledportalBudget_summaryRead,
    PermissionName.UniledportalBudget_summary_line_itemRead,
];

const mapLibraries: LoadScriptProps['libraries'] = ['visualization'];

const CampaignOverviewDetailTabs: React.FC<Props> = ({ id }) => {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const lineItemErrors = useMemo(
        () =>
            campaign?.errors?.filter(
                err => err.errorable_type === CampaignErrorableType.LineItem,
            ) ?? [],
        [campaign],
    );

    const { hasPCAData } = useCampaignWrapperState(id);

    const creativeErrors = useMemo(
        () =>
            campaign?.errors?.filter(
                err =>
                    err.errorable_type === CampaignErrorableType.Creative ||
                    err.errorable_type === CampaignErrorableType.Campaign,
            ) ?? [],
        [campaign],
    );

    const tabs = useMemo<TabWrapperSettings['tabs']>(() => {
        const theTabs = [
            {
                title: (
                    <Tooltip
                        title={
                            lineItemErrors.length > 0 ? (
                                <Translate path="Modules.Main.Agency.Overview.Tabs.creativeWarningsTooltip" />
                            ) : (
                                ''
                            )
                        }
                    >
                        <Badge
                            max={9}
                            badgeContent={lineItemErrors.length}
                            color="warning"
                            variant="dot"
                        >
                            <Translate path="Modules.Main.Campaigns.Overview.Tabs.displays" />
                        </Badge>
                    </Tooltip>
                ),
                permissions: displaysPerms,
                content: <CampaignOverviewDisplays id={id} />,
                urlName: 'displays',
                className: 'campaign-tab-displays',
            },
            {
                title: (
                    <Tooltip
                        title={
                            creativeErrors.length > 0 ? (
                                <Translate path="Modules.Main.Agency.Overview.Tabs.creativeWarningsTooltip" />
                            ) : (
                                ''
                            )
                        }
                    >
                        <Badge
                            max={9}
                            badgeContent={creativeErrors.length}
                            color="warning"
                            variant="dot"
                        >
                            <Translate path="Modules.Main.Campaigns.Overview.Tabs.creatives" />
                        </Badge>
                    </Tooltip>
                ),
                permissions: creativesPerms,
                content: <CampaignCreativeManagement id={id} />,
                urlName: 'creatives',
                className: 'campaign-tab-creatives',
            },
            {
                title: hasPCAData ? (
                    <PCATabControls id={id} />
                ) : (
                    'Modules.Main.Campaigns.Overview.Tabs.reporting'
                ),
                permissions: reportsPerms,
                content: !hasPCAData ? (
                    <PCADataComingSoon campaign={campaign} />
                ) : (
                    <CampaignReportingWrapper id={id} campaign={campaign} />
                ),
                urlName: 'reporting',
                className: 'campaign-tab-reporting',
            },
            {
                title: 'Modules.Main.Campaigns.Overview.Tabs.popImages',
                permissions: popImagePerms,
                content: (
                    <CampaignOverviewPopShotsParentTab
                        id={id}
                        hasPopShots={campaign.popShotsCount > 0}
                    />
                ),
                urlName: 'popimages',
                className: 'campaign-tab-popimages',
            },
        ];
        if (campaign?.budgetSummary != null) {
            theTabs.push({
                title: 'Modules.Main.Campaigns.Overview.Tabs.cost',
                permissions: costPerms,
                content: <CampaignCosts campaign={campaign} />,
                urlName: 'cost',
                className: 'campaign-tab-costs',
            });
        }
        if (campaign?.happydemics_id != null) {
            theTabs.splice(3, 0, {
                title: 'Modules.Main.Campaigns.Overview.HappyDemics.title',
                permissions: [],
                content: <HappyDemicsTabContent campaign={campaign} />,
                urlName: 'happydemics',
                className: 'campaign-tab-happydemics',
            });
        }
        return theTabs;
    }, [campaign, id, lineItemErrors, creativeErrors, hasPCAData]);

    const tabWrapperContent = useMemo(
        () => <TabsWrapper tabs={tabs} urlStatePath="tab" tabListProps={tabListProps} />,
        [tabs],
    );
    return (
        <>
            <LoadScript
                googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                libraries={mapLibraries}
            >
                {tabWrapperContent}
            </LoadScript>
        </>
    );
};

const PCADataComingSoon: React.FC<{ campaign: Campaign }> = ({ campaign }) => (
    <Box>
        <CampaignNotices campaign={campaign} />
        <Box textAlign="center" mt={8}>
            <Typography color="textSecondary" variant="h2">
                {useCommonTranslation('Modules.Main.Campaigns.Overview.pcaDataComingSoon')}
            </Typography>
            <Box>
                <img src={RocketLogo} alt="reporting data coming soon" height={250} />
            </Box>
            <Box mt={4}>
                <LinkButton to={PostAuthRoutes.Campaigns.CampaignList}>
                    {useCommonTranslation(
                        'Modules.Main.Campaigns.Overview.pcaDataComingSoonBtnLabel',
                    )}
                </LinkButton>
            </Box>
        </Box>
    </Box>
);

export default CampaignOverviewDetailTabs;
