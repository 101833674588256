export enum PreAuthRoutes {
    Login = '/login',
    Welcome = '/welcome',
    RequestPasswordReset = '/request-password-reset',
    RequestPasswordResetCode = '/reset-password',
    Revalidate = '/revalidate/:token',
    // AcceptInvite = '/invite',

    Register = '/register',
    RegisterToken = '/register?token=:token',

    Verify = '/verify',

    CompleteRegistration = '/complete-registration',
}

export enum InviteRoutes {
    LoginToken = '/login?token=:token',

    RegisterToken = '/register?token=:token',

    Invite = '/invite',
    InviteToken = '/invite?token=:token',
}

export const PostAuthRoutes = {
    Dashboard: {
        Dashboard: '/dashboard',
    },
    Users: {
        MyProfile: '/my-profile',
        UserManagement: '/user-management',
        UserManagementUserEdit: '/user-management/user-edit/:id',
    },
    Campaigns: {
        CampaignOverview: '/campaigns/:id',
        CampaignSchedule: '/campaigns/:id/schedule',
        CampaignList: '/campaigns',
        NewCampaignPage: '/campaigns/new',
        UpdateCampaignLineItems: '/campaigns/:id/edit-displays',
        CloneCampaign: '/campaigns/:id/clone',
        DataStatus: '/data-status',
        AcceptCampaignInvite: '/invite',
    },
    Agency: {
        AgencyList: '/agencies',
        AgencyOverview: '/agencies/:id',
    },
    Buyer: {
        BuyerList: '/buyers',
        BuyerOverview: '/buyers/:id',
    },
    Client: {
        ClientList: '/brands',
    },
    Creatives: {
        CreativesSearch: '/creatives',
    },
    Owner: {
        OwnerList: '/owners',
        OwnerOverview: '/owners/:id',
    },
    Admin: {
        AdminHome: '/admin',
        SkinList: '/admin/skins',
        EditSkin: '/admin/skins/:id',
        RoleList: '/admin/roles',
        EditRole: '/admin/roles/:id',
        UserList: '/admin/users',
        EditUser: '/admin/users/:id',
        ImpersonateUser: '/admin/impersonate',
        NewUser: '/admin/users/new',
        MarketList: '/admin/markets',
        FrameSearch: '/admin/frame-search',
        TaskQueue: '/admin/task_queue',
    },
    PopDisplays: {
        PopDisplaysHome: '/admin/pop-displays',
        LogFileImportList: '/admin/pop-displays/logfiles',
        LogImportSource: '/admin/pop-displays/log-import-sources',
        DisplayList: '/admin/pop-displays/displays',
        DisplayOverview: '/admin/pop-displays/displays/:id',
        ChannelFrameList: '/admin/pop-displays/channel-frames',
        ChannelFrameOverview: '/admin/pop-displays/channel-frames/:id',
        PlayoutExport: '/admin/pop-displays/playoutexport',
        VirtualCampaignBuilder: '/admin/pop-displays/virtualcampaign',
        ScheduleTemplates: '/admin/pop-displays/schedule-templates',
    },
    Displays: {
        DisplaysList: '/admin/displays/displays',
        DisplaysAdminHome: '/admin/displays',
        OwnerList: '/admin/displays/owners',
        PackList: '/admin/displays/packs',
        AnaloguesList: '/admin/displays/analogues',
        DisplaysDisplayEdit: '/admin/displays/display/:id',
        TagsList: '/admin/displays/tags',
        DisplaysTagEdit: '/admin/displays/tag/:id',
        DisplaysOwnerEdit: '/admin/displays/owners/:id',
        CountryList: '/admin/displays/countries',
        DisplaysPackEdit: '/admin/displays/packs/:id',
        DisplaysCountryEdit: '/admin/displays/countries/:id',
        DisplaysMarketEdit: '/admin/displays/markets/:id',
        DisplaysAnalogueScreenEdit: '/admin/displays/analoguescreen/:id',
    },
    Main: {
        MainDashboard: '/dashboard',
    },
};
