import React, { useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import RocketLogo from 'c-assets/rocket.png';

const HappyDemicsTabContent = ({ campaign }) => {
    const t = useCommonTranslation();
    const pageDisplay = useMemo(() => {
        if (!campaign.happydemics) {
            return (
                <Typography variant="h1">
                    {t('Modules.Main.Campaigns.Overview.HappyDemics.enabled')}
                </Typography>
            );
        }
        if (campaign.happydemics.report_url_en === null) {
            return (
                <Box textAlign="center" mt={8}>
                    <Typography color="textSecondary" variant="h2">
                        {t('Modules.Main.Campaigns.Overview.HappyDemics.awaitingData')}
                    </Typography>
                    <Box>
                        <img src={RocketLogo} alt="pop images coming soon" height={250} />
                    </Box>
                </Box>
            );
        }
        return (
            <iframe
                title={t('Modules.Main.Campaigns.Overview.HappyDemics.happydemicsReport')}
                src={campaign.happydemics.report_url_en}
                width="100%"
                height="1150rem"
            />
        );
    }, [campaign, t]);

    return (
        <Card>
            <CardHeader title={campaign.happydemics.name} />
            <CardContent>{pageDisplay}</CardContent>
        </Card>
    );
};
export default HappyDemicsTabContent;
