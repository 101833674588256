import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import {
    Campaign as CampaignModel,
    CampaignCreateRequest,
    CampaignCreativeAutoAssignRequest,
    CampaignCreativeForceAssignRequest,
    CampaignCreativeMassUnAssignRequest,
    CampaignCreativesAutoGenerateGroupsResponse,
    CampaignCreativesAutoGroupsGenerateRequest,
    CampaignCreativesResponse,
    CampaignCreativeUnAssignRequest,
    CampaignDisplayIssuesResponse,
    CampaignDisplaySchedule,
    CampaignError,
    CampaignSyncLineItemsRequest,
    Displays_LineItem,
    HttpProgressUpdate,
    ListSearchOptions,
    ListSearchResponse,
    MediaGroup,
    MediaTable,
    ModuleConstructorProps,
    Owner,
    paramGet,
    ParamPost,
    SaveCampaignPopSettingsPayload,
    Schedule,
    ScheduleGet,
    ScheduleRuleGet,
    SetCampaignNoticesRequest,
} from '../Types';
import { generateArrayQSParams } from '../Lib';
import {
    BaseCreateFunc,
    BaseEntityModuleFuncs,
    lineItemSchedualableFunc,
    ListEndpointFunc,
} from './BaseEntityModuleFuncs';

interface DisplayData {
    id: number;
    name: string;
    partner_id: number;
    ext_id: string;
    unilive_id: number;
    market_id: number;
    frame_id: string;
    previous_frame_id: string | null;
    frame_type_id: number;
    type: string;
    description: string;
    brochurelink: string;
    env: string;
    slots: number;
    sov: number;
    slot_length: number;
    animation: string;
    size: string;
    sales_code: string;
    surface_area: string;
    height: string;
    width: string;
    brochure: string | null;
    location: string;
    address: string;
    postcode: string;
    lat: string;
    long: string;
    town: string;
    tv_region: string;
    conurbation: string;
    channel: string;
    frame: string;
    status: number;
    created_at: string | null;
    updated_at: string;
    updated_by: string;
    deleted_at: string | null;
    energy_conversion_override: string | null;
    energy_uses_per_second: string | null;
    salesforce_display_id: string;
    schedules: Schedule[];
    schedulesCount: number;
    mediaOwners: Owner[];
}
const Campaign = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    ...BaseEntityModuleFuncs<CampaignModel>({ endpoints, httpClient, baseUrl }, 'Campaign'),
    create: (data: CampaignCreateRequest, includes?: string[], config?: AxiosRequestConfig) =>
        BaseCreateFunc<CampaignModel>({ endpoints, httpClient, baseUrl }, 'Campaign')(
            data,
            includes,
            config,
        ),
    syncLineItems: (
        id: number,
        data: CampaignSyncLineItemsRequest,
        source?: string,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        const sourceParam = includes
            ? `&source=${encodeURIComponent(source)}`
            : `?source=${encodeURIComponent(source)}`;
        const url = `${urlcat(endpoints.Entities.Campaign.syncLineItems, {
            id,
        })}${includeParams}${sourceParam}`;
        return httpClient.post<{ data: CampaignModel }>(url, data, config);
    },
    lineItems: (id: number, options: ListSearchOptions = {}, config?: AxiosRequestConfig) =>
        ListEndpointFunc<ListSearchResponse<Displays_LineItem>>(httpClient)(
            {
                endpoint: urlcat(endpoints.Entities.Campaign.lineItems, { id }),
                options,
            },
            config,
        ),
    lineItemSchedualables: (
        id: number,
        includes: string[] = ['schedulable', 'rules', 'schedule'],
        perPage: number,
        config?: AxiosRequestConfig,
    ) =>
        lineItemSchedualableFunc(
            { endpoints, httpClient, baseUrl },
            endpoints.Entities.Campaign.lineItemSchedulables,
            id,
            perPage,
            includes,
            config,
        ),

    downloadLink: (
        campaignId: number,
        {
            line_item_ids = [],
            creative_ids = [],
            include_display_specs,
            include_creatives,
            include_pop_shots,
        }: {
            line_item_ids?: string[];
            creative_ids?: number[];
            include_display_specs?: boolean;
            include_creatives?: boolean;
            include_pop_shots?: boolean;
        },
    ) =>
        `${baseUrl}${urlcat(endpoints.Entities.Campaign.download, {
            id: campaignId,
            include_display_specs: include_display_specs ? '1' : '0',
            include_creatives: include_creatives ? '1' : '0',
            include_pop_shots: include_pop_shots ? '1' : '0',
        })}${generateArrayQSParams(line_item_ids, 'line_item_ids', '&')}${generateArrayQSParams(
            creative_ids,
            'creative_ids',
            '&',
        )}`,
    shareUrl: (id: number, config?: AxiosRequestConfig) =>
        httpClient.post<{ url: string; url_legacy: string }>(
            urlcat(endpoints.Entities.Campaign.shareUrl, {
                id,
            }),
            config,
        ),
    overrideBudgetSummary: (
        id: number,
        price: number,
        reason: string,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includesString = generateArrayQSParams(includes, 'include', '?');
        return httpClient.put<{
            data: CampaignModel;
        }>(
            `${urlcat(endpoints.Entities.Campaign.overrideBudgetSummary, {
                id,
            })}${includesString}`,
            { price, reason },
            config,
        );
    },
    setNotices: (
        id: number,
        data: SetCampaignNoticesRequest,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{
            data: CampaignModel;
        }>(
            `${urlcat(endpoints.Entities.Campaign.setNotices, {
                id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            data,
            config,
        ),
    resetNotices: (id: number, includes?: string[], config?: AxiosRequestConfig) =>
        httpClient.get<{
            data: CampaignModel;
        }>(
            `${urlcat(endpoints.Entities.Campaign.resetNotices, {
                id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            config,
        ),
    downloadPCA: (id: number, config?: AxiosRequestConfig) =>
        httpClient.post<{ success: boolean }>(
            urlcat(endpoints.Entities.Campaign.downloadPCA, {
                id,
            }),
            config,
        ),
    archive: (id: number, archive: boolean, config?: AxiosRequestConfig) =>
        httpClient.post<{
            data: CampaignModel;
        }>(
            urlcat(endpoints.Entities.Campaign.archive, {
                id,
            }),
            { archive },
            config,
        ),
    creatives: (id: number, config?: AxiosRequestConfig) =>
        httpClient.get<CampaignCreativesResponse>(
            urlcat(endpoints.Entities.Campaign.creatives, {
                id,
            }),
            config,
        ),
    autoGenerateCreativeGroups: (id: number, resolutions?: string[], config?: AxiosRequestConfig) =>
        httpClient.post<CampaignCreativesAutoGenerateGroupsResponse>(
            urlcat(endpoints.Entities.Campaign.autoGenerateCreativeGroups, {
                id,
            }),
            { resolutions },
            config,
        ),
    createAutoGeneratedGroups: (
        id: number,
        data: CampaignCreativesAutoGroupsGenerateRequest,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<CampaignCreativesResponse>(
            urlcat(endpoints.Entities.Campaign.createAutoGeneratedGroups, {
                id,
            }),
            data,
            config,
        ),
    auditEvent: (id: number, data: { audit_event: string }) =>
        httpClient.post(urlcat(endpoints.Entities.Campaign.auditEvent, { id }), data),
    forceAssignCreativesToLineItems: (
        id: number,
        data: CampaignCreativeForceAssignRequest,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<CampaignCreativesResponse>(
            `${urlcat(endpoints.Entities.Campaign.forceAssignCreativesToLineItems, {
                id,
            })}${generateArrayQSParams(['campaignDisplays'], 'include', '?')}`,
            data,
            config,
        ),
    autoAssignCreativesToLineItems: (
        id: number,
        {
            creatives,
            lineItems,
            creativeGroups,
            filters = {},
            searchables = {},
        }: CampaignCreativeAutoAssignRequest,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<CampaignCreativesResponse>(
            urlcat(endpoints.Entities.Campaign.autoAssignCreativesToLineItems, {
                id,
                ...searchables,
            }),
            { creatives, lineItems, creativeGroups, ...filters, ...searchables },
            config,
        ),
    forceUnAssignCreativesFromLineItems: (
        id: number,
        data: CampaignCreativeUnAssignRequest,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<CampaignCreativesResponse>(
            urlcat(endpoints.Entities.Campaign.forceUnAssignCreativesFromLineItems, {
                id,
            }),
            data,
            config,
        ),
    massUnAssignCreativesFromLineItems: (
        id: number,
        {
            line_item_ids,
            creative_group_ids,
            creative_ids,
            filters = {},
            searchables = {},
        }: CampaignCreativeMassUnAssignRequest,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<CampaignCreativesResponse>(
            urlcat(endpoints.Entities.Campaign.massUnAssignCreativesFromLineItems, {
                id,
                ...searchables,
            }),
            { line_item_ids, creative_group_ids, creative_ids, ...filters, ...searchables },
            config,
        ),
    displayIssues: (id: number, config?: AxiosRequestConfig) =>
        httpClient.get<CampaignDisplayIssuesResponse>(
            urlcat(endpoints.Entities.Campaign.displayIssues, {
                id,
            }),
            config,
        ),
    errors: (id: number, includes?: string[], config?: AxiosRequestConfig) =>
        httpClient.get<{ data: CampaignError[] }>(
            `${urlcat(endpoints.Entities.Campaign.errors, {
                id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            config,
        ),
    validate: (id: number, includes?: string[], config?: AxiosRequestConfig) =>
        httpClient.post<{
            data: CampaignModel;
        }>(
            `${urlcat(endpoints.Entities.Campaign.validate, {
                id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            config,
        ),
    deleteBookingSummary: (
        campaignId: number,
        bookingSummaryId: number,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.delete<{
            data: CampaignModel;
        }>(
            `${urlcat(endpoints.Entities.Campaign.deleteBookingSummary, {
                id: campaignId,
                bookingSummary: bookingSummaryId,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            null,
            config,
        ),
    deleteLegacyBookingSummary: (
        campaignId: number,
        bookingSummaryFileName: string,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.delete<{
            data: CampaignModel;
        }>(
            `${urlcat(endpoints.Entities.Campaign.deleteLegacyBookingSummary, {
                id: campaignId,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            { name: bookingSummaryFileName },
            config,
        ),
    uploadBookingSummary: (
        id: number,
        {
            formData,
            onProgress,
        }: {
            /**
             * file: File - the actual file to upload
             */
            formData;
            onProgress?: HttpProgressUpdate;
        },
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.postFile<{
            data: CampaignModel;
        }>(
            `${urlcat(endpoints.Entities.Campaign.uploadBookingSummary, {
                id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            formData,
            onProgress,
            {},
            config,
        ),
    syncContacts: (
        id: number,
        contacts: number[],
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        return httpClient.post<{ data: CampaignModel }>(
            `${urlcat(endpoints.Entities.Campaign.syncContacts, {
                id,
            })}${includeParams}`,
            { additional_contacts: contacts },
            config,
        );
    },
    updatePopSettings: (
        id: number,
        data: SaveCampaignPopSettingsPayload,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes ?? [], 'include', '?');
        return httpClient.post<{ data: CampaignModel }>(
            `${urlcat(endpoints.Entities.Campaign.updatePopSettings, {
                id,
            })}${includeParams}`,
            data,
            config,
        );
    },
    scheduleLineItems: (
        id: number | string,
        options: ListSearchOptions = {},
        config?: AxiosRequestConfig,
    ) =>
        ListEndpointFunc<ListSearchResponse<Displays_LineItem>>(httpClient)(
            {
                endpoint: urlcat(endpoints.Entities.Campaign.scheduleLineItems, { id }),
                options,
            },
            config,
        ),
    screenFromSchedule: (
        id: number | string,
        lineItemId: string | number,
        options: ListSearchOptions = {},
        config?: AxiosRequestConfig,
    ) =>
        ListEndpointFunc<ListSearchResponse<CampaignDisplaySchedule>>(httpClient)(
            {
                endpoint: urlcat(endpoints.Entities.Campaign.screenFromSchedule, {
                    id,
                    lineItemId,
                }),
                options,
            },
            config,
        ),
    schedules: (
        id: number | string,
        includes?: string[],
        page?: number,
        perPage?: number,
        search?: string,
        config?: AxiosRequestConfig,
    ) => {
        const queryParams = `${generateArrayQSParams(includes, 'include', '?')}${
            includes?.length > 0 ? (page ? `&page=${page}` : '') : page ? `?page=${page}` : ''
        }${
            includes?.length > 0 || page
                ? perPage
                    ? `&perPage=${perPage}`
                    : ''
                : perPage
                ? `?perPage=${perPage}`
                : ''
        }${search ? `&search.name=${search}` : ''}&orderBy=created_at&direction=asc`;
        return httpClient.get<{ data: ScheduleGet }>(
            `${urlcat(endpoints.Entities.Campaign.schedules, {
                id,
            })}${queryParams}`,
            config,
        );
    },
    scheduleRules: (
        id: number | string,
        scheduleId: number | string,
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.get<{ data: ScheduleRuleGet }>(
            `${urlcat(endpoints.Entities.Campaign.scheduleRules, {
                id,
                scheduleId,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            config,
        ),
    attachSchedules: (
        id: number | string,
        data: {
            lineItems?: string[] | number[];
            displays?: string[] | number[];
            schedules?: string[] | number[];
        },
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post(
            `${urlcat(endpoints.Entities.Campaign.attachSchedules, {
                id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            data,
            config,
        ),
    detachSchedules: (
        id: number | string,
        data: {
            lineItems?: string[] | number[];
            displays?: string[] | number[];
            schedules?: string[] | number[];
        },
        includes?: string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post(
            `${urlcat(endpoints.Entities.Campaign.detachSchedules, {
                id,
            })}${generateArrayQSParams(includes, 'include', '?')}`,
            data,
            config,
        ),
    deleteSchedules: (
        id: number | string,
        scheduleId: number | string,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.delete(
            urlcat(endpoints.Entities.Campaign.deleteSchedules, { id, scheduleId }),
            config,
        ),
    campaignPowerpoint: (
        id: number | string,
        powerpoint_template = 'linear_report',
        skin_id: number,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post(
            urlcat(endpoints.Entities.Campaign.campaignPowerpoint, { id }),
            { powerpoint_template, skin_id },
            config,
        ),
    listTargets: (
        id: number | string,
        includes?: string[],
        page = 1,
        perPage = 25,
        search?: string,
        orderBy = 'created_at',
        direction: 'asc' | 'desc' = 'asc',
        config?: AxiosRequestConfig,
    ) => {
        const includeParams = generateArrayQSParams(includes, 'include', '&');

        const queryParams = `?orderBy=${orderBy}&direction=${direction}${
            search ? `&search.name=${search}` : ''
        }${includeParams}${page ? `&page=${page}` : ''}${perPage ? `&perPage=${perPage}` : ''}`;

        const url = `${urlcat(endpoints.Entities.Campaign.listTargets, { id })}${queryParams}`;

        return httpClient.post<{
            data: {
                id: string | number;
                name: string;
                type: 'impacts' | 'plays';
                target: string | number;
                schedules: Schedule[];
            }[];
        }>(url, {}, config);
    },

    updateTargets: (
        campaignId: number | string,
        targetId: number | string,
        data: {
            name: string;
            type: 'impacts' | 'plays';
            target: string | number;
            schedules?: Schedule[];
        },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.put<{
            data: {
                id: string | number;
                name: string;
                type: 'impacts' | 'plays';
                target: string | number;
                schedules?: Schedule[];
            };
        }>(
            urlcat(endpoints.Entities.Campaign.updateTargets, { id: campaignId, targetId }),
            data,
            config,
        ),
    createTargets: (
        campaignId: number | string,
        data: { name: string; type: 'impacts' | 'plays'; target: string | number },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{
            data: {
                id: string | number;
                name: string;
                type: 'impacts' | 'plays';
                target: string | number;
            };
        }>(urlcat(endpoints.Entities.Campaign.createTargets, { id: campaignId }), data, config),
    deleteTargets: (
        campaignId: number | string,
        targetId: number | string,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.delete(
            urlcat(endpoints.Entities.Campaign.deleteTargets, { id: campaignId, targetId }),
            config,
        ),
    attachTargetToSchedules: (
        campaignId: number | string,
        targetId: number | string,
        data: { schedules: number[] },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ success: boolean }>(
            urlcat(endpoints.Entities.Campaign.attachTargetToSchedules, {
                id: campaignId,
                targetId,
            }),
            data,
            config,
        ),
    detachTargetFromSchedules: (
        campaignId: number | string,
        targetId: number | string,
        data?: { schedules: number[] },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ success: boolean }>(
            urlcat(endpoints.Entities.Campaign.detachTargetFromSchedules, {
                id: campaignId,
                targetId,
            }),
            data,
            config,
        ),
    channelFrameGet: (id: number, config?: AxiosRequestConfig) =>
        httpClient.get<{ last_modified: number | null; url: string | null }>(
            urlcat(endpoints.Entities.Campaign.channelFrame, {
                id,
            }),
            config,
        ),
    channelFramePost: (id: number, config?: AxiosRequestConfig) =>
        httpClient.post<{ alert: string; status: string }>(
            urlcat(endpoints.Entities.Campaign.channelFrame, {
                id,
            }),
            config,
        ),
    pcaExport: (id: number, formData: FormData, config?: AxiosRequestConfig) => {
        const url = urlcat(endpoints.Entities.Campaign.pcaExport, { id });
        return httpClient.post<{ success: boolean }>(url, formData, config);
    },
    pcaImport: (
        id: number,
        { formData, onProgress }: { formData: FormData; onProgress: HttpProgressUpdate },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.postFile<{
            data: CampaignModel;
        }>(
            `${urlcat(endpoints.Entities.Campaign.pcaImport, {
                id,
            })}`,
            formData,
            onProgress,
            {},
            config,
        ),
    mediaList: (id: number, config?: AxiosRequestConfig) =>
        httpClient.post<{ media: MediaTable[]; mediaGroups: MediaGroup }>(
            urlcat(endpoints.Entities.Campaign.popSettingsMediaTable, {
                id,
            }),
            config,
        ),
    GetMediaParameter: (id: number, config?: AxiosRequestConfig) =>
        httpClient.get<{ data: paramGet[] }>(
            urlcat(endpoints.Entities.Campaign.mediaTableParameter, {
                id,
            }),
            config,
        ),
    AddMediaParameter: (id: number, data: ParamPost, config?: AxiosRequestConfig) =>
        httpClient.post<{ media: MediaTable[]; mediaGroup: MediaGroup }>(
            urlcat(endpoints.Entities.Campaign.mediaTableParameter, {
                id,
            }),
            data,
            config,
        ),
    UpdateMediaParameter: (id: number, data: ParamPost, config?: AxiosRequestConfig) =>
        httpClient.put<{ media: MediaTable[]; mediaGroup: MediaGroup }>(
            urlcat(endpoints.Entities.Campaign.mediaTableParameter, {
                id,
            }),
            data,
            config,
        ),
    DeleteMediaParameter: (campaignId: number, id: number, config?: AxiosRequestConfig) =>
        httpClient.delete<{ media: MediaTable[]; mediaGroup: MediaGroup }>(
            urlcat(endpoints.Entities.Campaign.deleteMediaTableParameter, {
                campaignId,
                id,
            }),
            config,
        ),
    DeleteMultipleMediaParameters: (
        id: number,
        parameter_ids: number[] | string[],
        config?: AxiosRequestConfig,
    ) =>
        httpClient.delete<{ media: MediaTable[]; mediaGroup: MediaGroup }>(
            urlcat(endpoints.Entities.Campaign.mediaTableParameter, {
                id,
            }),
            { parameter_ids },
            config,
        ),
    removeIdsFromMediaGroup: (
        id: number,
        data: { media_ids: number[] },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ media: MediaTable[]; mediaGroup: MediaGroup }>(
            urlcat(endpoints.Entities.Campaign.removeIdsFromMediaGroup, {
                id,
            }),
            data,
            config,
        ),
    editGroup: (
        id: number,
        data: { group_name: string; media_ids: number[] },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.put<{ media: MediaTable[]; mediaGroup: MediaGroup }>(
            urlcat(endpoints.Entities.Campaign.editGroup, {
                id,
            }),
            data,
            config,
        ),
    syncMedia: (id: number, config?: AxiosRequestConfig) =>
        httpClient.post<{ media: MediaTable[]; mediaGroup: MediaGroup }>(
            urlcat(endpoints.Entities.Campaign.syncMedia, {
                id,
            }),
            config,
        ),
    addDisplay: (
        id: number | string,
        data: { identifier: string[]; identifier_type: 'frame_id' | 'display_id' },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post(
            urlcat(endpoints.Entities.Campaign.addDisplay, {
                id,
            }),
            data,
            config,
        ),
    removeDisplay: (
        id: number | string,
        data: { display_ids: number[] },
        config?: AxiosRequestConfig,
    ) => httpClient.post(urlcat(endpoints.Entities.Campaign.removeDisplay, { id }), data, config),
    removeUnknownDisplays: (
        id: number | string,
        data: { ids: number[] },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post(
            urlcat(endpoints.Entities.Campaign.removeUnknownDisplays, { id }),
            data,
            config,
        ),
    listDisplays: (
        id: number | string,
        options: ListSearchOptions = {},
        config?: AxiosRequestConfig,
    ) =>
        ListEndpointFunc<ListSearchResponse<DisplayData>>(httpClient)(
            {
                endpoint: urlcat(endpoints.Entities.Campaign.listDisplays, { id }),
                options,
            },
            config,
        ),

    displaysDownload: (
        id: number | string,
        data: {
            'search.any'?: string;
            filter?: any;
            'search.vendor_id': string;
        },
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<{ message: string }>(
            urlcat(endpoints.Entities.Campaign.displaysDownload, { id }),
            data,
            config,
        ),
    listUnknownDisplays: (id: number | string, perPage = 25, config?: AxiosRequestConfig) =>
        httpClient.get<{ data: { id: number; frame_id: string; display_id: string | null }[] }>(
            urlcat(endpoints.Entities.Campaign.listUnknownDisplays, { id, perPage }),
            config,
        ),
    reImportUnknownDisplays: (id: number | string, config?: AxiosRequestConfig) =>
        httpClient.post(
            urlcat(endpoints.Entities.Campaign.reImportUnknownDisplays, { id }),
            {},
            config,
        ),
});

export default Campaign;
