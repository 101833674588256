import React, { useEffect, useMemo } from 'react';
import { Box, Card, CardContent, Stack } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { campaignName } from 'c-main/Lib';
import { generatePath } from 'react-router-dom';
import { Campaign, PermissionName } from 'c-sdk';
import { campaignIcon } from 'c-main/icons';
import CampaignSchedule from 'c-main/Components/Schedule/CampaignSchedule';
import { useEntityPage } from 'c-hooks';
import PopSettingsForm from 'c-main/Components/Schedule/Components/PopSettings/PopSettingsForm';
import { CollapsibleTransparentCard, TransparentCard } from 'c-components';
import ReactGA from 'react-ga';
import { useEntityData } from 'c-data';
import { CampaignOverviewDetails, CampaignThumbnail } from 'c-main/Components';
import MediaTable from 'c-main/Components/Schedule/Components/MediaTable/MediaTable';
import { useBoolean } from 'react-hanger';

const CampaignSchedulePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const id = useEntityPage().id;
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const addNameState = useBoolean(false);
    const detailsAndThumbnail = useMemo(
        () => (
            <Box display="flex" alignItems="center">
                <Box pr={2}>
                    <CampaignThumbnail campaign={campaign} size={80} preferredSize="medium" />
                </Box>
                <TransparentCard title={campaignName(campaign)} cardHeaderProps={{ sx: { pb: 0 } }}>
                    <CardContent>
                        <CampaignOverviewDetails id={id} buttons />
                    </CardContent>
                </TransparentCard>
            </Box>
        ),
        [campaign, id],
    );

    return (
        <Stack gap={2} mt={2}>
            {detailsAndThumbnail}
            <Card elevation={0}>
                <Box pl={2}>
                    <CollapsibleTransparentCard
                        title="Modules.Main.Campaigns.CampaignSchedule.mediaTable.title"
                        titleTypographyProps={{ variant: 'subtitle1' }}
                    >
                        <MediaTable campaignId={id} addNameState={addNameState} />
                    </CollapsibleTransparentCard>
                </Box>
            </Card>
            <Card elevation={0}>
                <CardContent sx={{ py: 0, pb: '0 !important' }}>
                    <CollapsibleTransparentCard
                        title="Modules.Main.Campaigns.CampaignSchedule.popSettings.title"
                        titleTypographyProps={{ variant: 'subtitle1' }}
                    >
                        <PopSettingsForm campaignId={id} addNameState={addNameState} />
                    </CollapsibleTransparentCard>
                </CardContent>
            </Card>
            <Card elevation={0} sx={{ mb: 2 }}>
                <Box pl={2}>
                    <CollapsibleTransparentCard
                        title="Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.schedule"
                        titleTypographyProps={{ variant: 'subtitle1' }}
                    >
                        <CardContent sx={{ height: '80vh', overflow: 'hidden', display: 'flex' }}>
                            <CampaignSchedule id={id} />
                        </CardContent>
                    </CollapsibleTransparentCard>
                </Box>
            </Card>
        </Stack>
    );
};

const setup: PageEntityConfig<Campaign> = {
    id: 'CampaignSchedulePage',
    component: CampaignSchedulePage,
    systemSearch: {
        title: 'Modules.Main.Campaigns.CampaignSchedule.metaTitle',
        type: 'entity',
        entityName: 'Campaign',
        icon: campaignIcon,
        route: PostAuthRoutes.Campaigns.CampaignSchedule,
        saveAsRecentPage: true,
        excludeFromSystemSearch: true,
        defaultIncludes: [
            // 'lineItems',
            // 'lineItems.owner',
            // 'lineItems.resolutions',
            // 'lineItems.fileTypes',
            'date',
            'popSettings',
            'logSourceCampaignIdentifiers',
        ],
        permissions: [
            PermissionName.UniliveapiSchedulesRead,
            PermissionName.UniliveapiRulesRead,
            PermissionName.DisplaysLine_itemsRead,
            PermissionName.DisplaysScreensRead,
        ],
        breadcrumbs: [
            {
                label: 'Modules.Main.Campaigns.List.metaTitle',
                link: PostAuthRoutes.Campaigns.CampaignList,
            },
        ],
        nameGen: (entity, id, t) =>
            `${campaignName(entity)} ${t('Modules.Main.Campaigns.CampaignSchedule.metaTitle')}`,
        generateEntityBreadcrumbs: (crumbs, t, entity, id) => [
            ...crumbs,
            {
                label: campaignName(entity),
                link: generatePath(PostAuthRoutes.Campaigns.CampaignOverview, { id }),
            },
            {
                label: 'Modules.Main.Campaigns.CampaignSchedule.metaTitle',
            },
        ],
    },
};

export default setup;
