import React, { useCallback, useMemo, useState } from 'react';
import { useAPIClientRequest, useSkinSwitcher } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import AdminSkinSwitcher from 'c-wrapper/Components/SkinLangSwitcher/AdminSkinSwitcher';
import { Alert, Button, DialogV2, LoadingSpinner, OptionSchema } from 'c-components';
import { DEFAULT_SKIN_ID } from 'c-wrapper/Slices/UI/ui-selectors';
import { useCommonTranslation } from 'c-translation';

import { Stack } from '@mui/material';
import { NetworkRequestState } from 'c-data-layer';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { PermissionName } from 'c-sdk';

type BooleanState = {
    value: boolean;
    toggle: () => void;
    setTrue: () => void;
    setFalse: () => void;
};
interface PcaDialogProps {
    id: string | number;
    pcaState: BooleanState;
    isVistar: boolean;
}
const PptPCADialog: React.FC<PcaDialogProps> = ({ id, pcaState, isVistar }) => {
    const t = useCommonTranslation();
    const { selectedSkin } = useSkinSwitcher();
    const [skin, setSkin] = useState<number | null>(selectedSkin?.id);
    const { hasAll } = useUserPermissions();
    const basicPerms = useMemo(() => hasAll([PermissionName.PowerpointPcareportRead]), [hasAll]);
    const adminPerms = useMemo(() => hasAll([PermissionName.Admin]), [hasAll]);
    const { start, data, isLoading, error, requestState } = useAPIClientRequest(
        ApiClient.Entities.Campaign.campaignPowerpoint,
    );
    const additionalOptions = useMemo<OptionSchema[]>(
        () => [
            {
                value: DEFAULT_SKIN_ID,
                label: t('Modules.Admin.SkinSwitcher.defaultThemeOptionLabel'),
            },
        ],
        [t],
    );
    // for vistar reportType is DSP_report when we want to use that
    // const reportType = useMemo(() => (isVistar ? 'DSP_report' : 'Linear_report'), [isVistar]);
    const onSkinSelected = useCallback((val: number) => {
        setSkin(val);
    }, []);
    const onSubmit = useCallback(() => {
        start(id, 'Linear_report', skin || 0);
    }, [id, skin, start]);
    return (
        <>
            <DialogV2
                open={pcaState.value}
                onClose={pcaState.setFalse}
                title="Modules.Main.Campaigns.Overview.Pca.title"
            >
                {adminPerms && (
                    <Stack>
                        <AdminSkinSwitcher
                            disabled={isLoading}
                            onChange={onSkinSelected}
                            selectedSkinId={skin ?? DEFAULT_SKIN_ID}
                            additionalOptions={additionalOptions}
                        />
                    </Stack>
                )}
                {adminPerms && (
                    <Button onClick={onSubmit} disabled={isLoading}>
                        {t('Modules.Main.Campaigns.Overview.Pca.submit')}
                    </Button>
                )}
                {!adminPerms && basicPerms && (
                    <Button onClick={onSubmit} disabled={isLoading}>
                        {t('Modules.Main.Campaigns.Overview.Pca.submitDefaultSkin')}
                    </Button>
                )}
                {isLoading && <LoadingSpinner />}
                {error && <Alert severity="error">{String(error)}</Alert>}
                {requestState === NetworkRequestState.Success && (
                    <Alert severity="success">
                        {t('Modules.Main.Campaigns.Overview.Pca.success')}
                    </Alert>
                )}
            </DialogV2>
        </>
    );
};

export default PptPCADialog;
