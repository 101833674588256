import React, { useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
} from '@mui/material';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';

import { useCommonTranslation } from 'c-translation';

type Props = {
    id: number | string;
};

const SelectedItemsTable: React.FC<Props> = ({ id }) => {
    const {
        selected,
        setSelected,
        selectedScreens,
        setSelectedScreens,
        selectedDisplays,
        setSelectedDisplays,
    } = useNewCampaignSchedule(id);

    const handleRemove = (id: number | string) => {
        setSelected(prevItems => prevItems.filter(item => item.id !== id));
        setSelectedScreens(prevItems => prevItems.filter(item => item.id !== id));
        setSelectedDisplays(prevItems => prevItems.filter(item => item.id !== id));
    };
    const allSelected = useMemo(
        () =>
            selected.concat(
                selectedScreens,
                selectedDisplays.map(item => ({
                    ...item,
                    schedules: item.schedules.map(schedules => schedules.name),
                })),
            ),
        [selected, selectedDisplays, selectedScreens],
    );
    const t = useCommonTranslation();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.idCol')}
                        </TableCell>
                        <TableCell>
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol')}
                        </TableCell>
                        <TableCell>
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.frameIdCol')}
                        </TableCell>
                        <TableCell>
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.ownerCol')}
                        </TableCell>
                        <TableCell>
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.scheduleCol')}
                        </TableCell>
                        <TableCell>
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.actionsCol')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allSelected.map(item => (
                        <TableRow key={item.id}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.frameId ?? 'N/A'}</TableCell>
                            <TableCell>{item.owner}</TableCell>
                            <TableCell>{item?.schedules?.join(', ')}</TableCell>
                            <TableCell>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={() => handleRemove(item.id)}
                                >
                                    Remove
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SelectedItemsTable;
