import { BaseEntity } from '../../BaseEntity';
import { Market } from './Market';
import { BuyerCost } from './BuyerCost';
import { User } from './User';
import { Displays_AudienceSource } from '../Displays';

export enum BuyerReferenceType {
    Any = 'any',
    Alpha = 'alpha',
    Alphanumeric = 'alphanumeric',
    Numeric = 'numeric',
}

export type Buyer = BaseEntity & {
    name: string;

    // include
    users?: User[];
    usersCount?: number;
    campaignsCount?: number;

    // include
    costs?: BuyerCost[];
    latestCost?: BuyerCost;
    latest_cost_id?: number;

    market_id?: number;

    // include
    market?: Market;

    reference_prefix?: string;
    reference_type?: BuyerReferenceType;
    reference_min_length?: number;
    reference_max_length?: number;
    // include
    audienceSource?: Displays_AudienceSource;
    // include
    happydemics_client_key_name?: string;
};
