import React, { useEffect, useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { dashboardIcon } from 'c-main/icons';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { useTheme } from '@mui/styles';
import { BarChart as MuiBarChart } from '@mui/x-charts/BarChart';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { PermissionName } from 'c-sdk';

const generateColorFromName = (name: string): string => {
    let hash = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) * 31 ** (name.length - i);
    }
    const hue = Math.abs(hash) % 360;
    const saturation = 65 + (hash % 20);
    const lightness = 45 + (hash % 10);

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};
const filterChange = () => {
    if (
        window.location.host === 'localhost:5173' ||
        window.location.host === 'app.uniledsoftware.com'
    ) {
        return [{ field: 'status', operator: 'eq', value: 'live' }];
    }
    return [{ field: 'campaign_id', operator: 'in', value: ['8799'] }];
};

const MainDashboardPage = () => {
    const t = useCommonTranslation();
    const theme = useTheme();
    const { start, data } = useAPIClientRequest(ApiClient.Reports.dashboard);
    const { start: pieStart, data: pieData } = useAPIClientRequest(ApiClient.Reports.dashboard);
    const { start: barStart, data: barData } = useAPIClientRequest(ApiClient.Reports.dashboard);
    const { start: barStart2, data: barData2 } = useAPIClientRequest(ApiClient.Reports.dashboard);
    const { start: campaignStart, data: campaignData } = useAPIClientRequest(
        ApiClient.Reports.campaignDashboard,
    );
    const { start: campaignStart2, data: campaignData2 } = useAPIClientRequest(
        ApiClient.Reports.campaignDashboard,
    );
    const { isMediaOwner } = useUserPermissions();
    useEffect(() => {
        campaignStart({});
        campaignStart2({ groupBy: 'client' });
    }, [campaignStart, campaignStart2]);
    useEffect(() => {
        start({
            graphType: 'bar',
            metrics: [{ field: 'expected_impacts_in_schedule', aggregation: 'sum' }],
            dimensions: [{ field: 'display_owner' }],
            filters: filterChange(),
            sort: [{ field: 'display_owner', direction: 'desc' }],
            limit: 100,
        });
    }, [start]);
    useEffect(() => {
        barStart2({
            graphType: 'bar',
            metrics: [
                { field: 'expected_impacts_in_schedule', aggregation: 'sum' },
                { field: 'impacts_received_in_schedule', aggregation: 'sum' },
                { field: 'impacts_received_out_of_schedule', aggregation: 'sum' },
                { field: 'impacts_received_unbooked', aggregation: 'sum' },
            ],
            dimensions: [{ field: 'display_owner' }, { field: 'name' }, { field: 'campaign_id' }],
            filters: filterChange(),
            sort: [{ field: 'name', direction: 'desc' }],
            limit: 100,
        });
    }, [barStart2]);
    useEffect(() => {
        pieStart({
            graphType: 'bar',
            metrics: [{ field: 'expected_impacts_in_schedule', aggregation: 'sum' }],
            dimensions: [{ field: 'display_env' }],
            filters: filterChange(),
            sort: [{ field: 'display_env', direction: 'desc' }],
            limit: 100,
        });
    }, [pieStart]);
    useEffect(() => {
        barStart({
            graphType: 'bar',
            metrics: [
                { field: 'expected_impacts_in_schedule', aggregation: 'sum' },
                { field: 'impacts_received_in_schedule', aggregation: 'sum' },
                { field: 'impacts_received_out_of_schedule', aggregation: 'sum' },
                { field: 'impacts_received_unbooked', aggregation: 'sum' },
            ],
            dimensions: [{ field: 'name' }, { field: 'campaign_id' }],
            filters: filterChange(),
            limit: 100,
        });
    }, [barStart]);
    const liveCampaignsByBrand = useMemo(
        () => campaignData2?.data?.data.filter(item => item.live_count > 0),
        [campaignData2],
    );
    const pieChartData = useMemo(() => {
        if (!data?.data) return [];

        const totalSum = (data as any).data.reduce(
            (sum, item) => Number(sum) + Number(item.expected_impacts_in_schedule_sum),
            0,
        );

        return (data as any).data.map(item => ({
            name: item.display_owner,
            value: item.expected_impacts_in_schedule_sum,
            percentage: `${(
                (Number(item.expected_impacts_in_schedule_sum) / totalSum) *
                100
            ).toFixed(2)}%`,
            percentageNumber: (Number(item.expected_impacts_in_schedule_sum) / totalSum) * 100,
            color: generateColorFromName(item.display_owner),
        }));
    }, [data]);
    const pieChartData2 = useMemo(() => {
        if (!liveCampaignsByBrand) return [];

        const totalSum = campaignData?.data?.data[0].live_count;

        return liveCampaignsByBrand.map(item => ({
            name: item.client,
            value: item.live_count,
            percentage: `${((item.live_count / totalSum) * 100).toFixed(2)}%`,
            color: generateColorFromName(item.client as string),
        }));
    }, [liveCampaignsByBrand, campaignData]);
    const pieChartData3 = useMemo(() => {
        if (!pieData?.data) return [];

        const totalSum = (pieData as any).data.reduce(
            (sum, item) => Number(sum) + Number(item.expected_impacts_in_schedule_sum),
            0,
        );
        return pieData?.data
            ?.map(item => {
                if (!item.expected_impacts_in_schedule_sum) return null;
                return {
                    name: item.display_env,
                    value: item.expected_impacts_in_schedule_sum,
                    percentage: `${(
                        Number(item.expected_impacts_in_schedule_sum / totalSum) * 100
                    ).toFixed(2)}%`,
                    percentageNumber:
                        (Number(item.expected_impacts_in_schedule_sum) / totalSum) * 100,
                    color: generateColorFromName(item.display_env),
                };
            })
            .filter(item => item != null);
    }, [pieData]);
    const barChartData = useMemo(() => {
        if (!barData?.data) return [];
        const bdata = barData?.data?.map(item => {
            if (!item.expected_impacts_in_schedule_sum) return null;
            const totalImpacts =
                Number((item as any).impacts_received_in_schedule_sum) +
                Number((item as any).impacts_received_out_of_schedule_sum) +
                Number((item as any).impacts_received_unbooked_sum);
            const expectedImpacts =
                item.expected_impacts_in_schedule_sum === 0
                    ? 1
                    : Number(item.expected_impacts_in_schedule_sum);
            const percentage = (totalImpacts / expectedImpacts) * 100;
            return {
                id: item.campaign_id,
                name: item.name,
                totalImpacts,
                impactsPercentage: Number(percentage.toFixed(2)),
                impactsReceivedInSchedule: (item as any).impacts_received_in_schedule_sum,
                impactsReceivedOutOfSchedule: (item as any).impacts_received_out_of_schedule_sum,
                impactsReceivedUnbooked: (item as any).impacts_received_unbooked_sum,
                expectedImpacts: item.expected_impacts_in_schedule_sum,
            };
        });
        return bdata.filter(item => item != null);
    }, [barData?.data]);
    const groupedBarData = useMemo(() => {
        if (!barData2?.data) return [];

        const groups = {};

        barData2.data.forEach(item => {
            const totalImpacts =
                Number(item.impacts_received_in_schedule_sum) +
                Number(item.impacts_received_out_of_schedule_sum) +
                Number(item.impacts_received_unbooked_sum);

            if (totalImpacts >= Number(item.expected_impacts_in_schedule_sum)) return;

            const impactsPercentage =
                (totalImpacts / Number(item.expected_impacts_in_schedule_sum)) * 100;

            const ownerItem = {
                displayOwner: item.display_owner,
                campaign_id: item.campaign_id,
                totalImpacts,
                impactsPercentage,
                impactsReceivedInSchedule: item.impacts_received_in_schedule_sum,
                impactsReceivedOutOfSchedule: item.impacts_received_out_of_schedule_sum,
                impactsReceivedUnbooked: item.impacts_received_unbooked_sum,
                expectedImpacts: item.expected_impacts_in_schedule_sum,
            };

            const campaignName = item.name;

            if (!groups[campaignName]) {
                groups[campaignName] = { name: campaignName, items: [] };
            }
            groups[campaignName].items.push(ownerItem);
        });

        return Object.values(groups);
    }, [barData2?.data]);

    const impactsPercentageTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const {
                name,
                impactsPercentage,
                impactsReceivedInSchedule,
                impactsReceivedOutOfSchedule,
                impactsReceivedUnbooked,
                expectedImpacts,
                totalImpacts,
            } = payload[0].payload;
            return (
                <Box
                    className="custom-tooltip"
                    style={{
                        backgroundColor: '#fff',
                        padding: '10px',
                        border: '1px solid #ccc',
                    }}
                >
                    <Stack gap={1}>
                        <Typography fontWeight="bold">
                            {t('Pages.DashboardHome.Campaign')}:
                        </Typography>{' '}
                        {name}
                        <Typography fontWeight="bold">
                            {t('Pages.DashboardHome.impactsPercentage')}:
                        </Typography>{' '}
                        {impactsPercentage.toFixed(2)}%
                        <Typography fontWeight="bold">
                            {t('Pages.DashboardHome.totalImpacts')}:
                        </Typography>{' '}
                        {totalImpacts}
                        <Typography fontWeight="bold">
                            {t('Pages.DashboardHome.inSchedule')}:
                        </Typography>{' '}
                        {impactsReceivedInSchedule}
                        <Typography fontWeight="bold">
                            {t('Pages.DashboardHome.outSchedule')}:
                        </Typography>{' '}
                        {impactsReceivedOutOfSchedule}
                        <Typography fontWeight="bold">
                            {t('Pages.DashboardHome.unbooked')}:
                        </Typography>{' '}
                        {impactsReceivedUnbooked}
                        <Typography fontWeight="bold">
                            {t('Pages.DashboardHome.expectedImpacts')}:
                        </Typography>{' '}
                        {expectedImpacts}
                    </Stack>
                </Box>
            );
        }

        return null;
    };

    const transformForMuiXCharts = groupedData => {
        if (!groupedData || groupedData.length === 0) {
            return { dataset: [], series: [], xKey: null };
        }
        const dataset = groupedData.map(group => {
            const row = { campaign: group.name };
            group.items.forEach(item => {
                row[item.displayOwner] = item.impactsPercentage;
            });
            return row;
        });

        const ownerSet = new Set();
        groupedData.forEach(group => {
            group.items.forEach(item => ownerSet.add(item.displayOwner));
        });
        const owners = Array.from(ownerSet);
        const series = owners.map(owner => ({
            dataKey: owner as string,
            label: owner as string,
        }));
        const xKey = 'campaign';

        return { dataset, series, xKey };
    };

    const { dataset, series, xKey } = useMemo(
        () => transformForMuiXCharts(groupedBarData),
        [groupedBarData],
    );
    const chartSetting = {
        yAxis: [
            {
                label: 'Impacts Percentage',
            },
        ],
    };

    return (
        <Stack gap={1}>
            <Card>
                <CardHeader title={t('Pages.DashboardHome.metaTitle')} />
            </Card>
            <Card>
                <CardHeader title={t('Pages.DashboardHome.liveCampaigns')} />
                <CardContent>
                    <Typography variant="h1">{campaignData?.data.data[0].live_count}</Typography>
                </CardContent>
            </Card>
            {pieChartData2 && (
                <Card>
                    <CardHeader title={t('Pages.DashboardHome.campaignsByBrand')} />
                    <CardContent>
                        <ResponsiveContainer width="100%" height={600}>
                            <PieChart>
                                <Tooltip
                                    formatter={(value, name, props) => [`${value} campaigns`, name]}
                                />
                                <Pie
                                    dataKey="value"
                                    data={pieChartData2}
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={120}
                                    outerRadius={240}
                                    minAngle={3}
                                    label={({ name, percentage }) => `${name} (${percentage})`}
                                >
                                    {pieChartData2.map((entry, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                                <text
                                    x="50%"
                                    y="50%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fontSize={24}
                                    fontWeight="bold"
                                >
                                    {campaignData?.data.data[0].live_count}
                                </text>
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            )}
            {!isMediaOwner && data?.data && (
                <Card>
                    <CardContent>
                        <CardHeader title={t('Pages.DashboardHome.vendorSplits')} />
                        <ResponsiveContainer width="100%" height={600}>
                            <PieChart>
                                <Tooltip
                                    content={({ payload }) => {
                                        if (!payload || payload.length === 0) return null;
                                        const data = payload[0].payload;
                                        return (
                                            <Box
                                                style={{
                                                    background: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #ccc',
                                                }}
                                            >
                                                <Typography fontWeight="bold">
                                                    {data.name}
                                                </Typography>
                                                <Typography>
                                                    {t(
                                                        'Pages.DashboardHome.expectedImpactsInSchedule',
                                                    )}
                                                    : {data.value}
                                                </Typography>
                                                <Typography>
                                                    {t('Pages.DashboardHome.percentage')}:{' '}
                                                    {data.percentage}
                                                </Typography>
                                            </Box>
                                        );
                                    }}
                                />
                                <Pie
                                    dataKey="percentageNumber"
                                    data={pieChartData}
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={240}
                                    minAngle={3}
                                    label={({ name }) => name}
                                >
                                    {pieChartData.map((entry, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            )}
            {pieData?.data && (
                <Card>
                    <CardContent>
                        <CardHeader title={t('Pages.DashboardHome.environmentSplits')} />
                        <ResponsiveContainer width="100%" height={600}>
                            <PieChart>
                                <Tooltip
                                    content={({ payload }) => {
                                        if (!payload || payload.length === 0) return null;
                                        const data = payload[0].payload;
                                        return (
                                            <Box
                                                style={{
                                                    background: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #ccc',
                                                }}
                                            >
                                                <Typography fontWeight="bold">
                                                    {data.name}
                                                </Typography>
                                                <Typography>
                                                    {t(
                                                        'Pages.DashboardHome.expectedImpactsInSchedule',
                                                    )}
                                                    : {data.value}
                                                </Typography>
                                                <Typography>
                                                    {t('Pages.DashboardHome.percentage')}:{' '}
                                                    {data.percentage}
                                                </Typography>
                                            </Box>
                                        );
                                    }}
                                />
                                <Pie
                                    dataKey="percentageNumber"
                                    data={pieChartData3}
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    minAngle={3}
                                    outerRadius={240}
                                    label={({ name }) => name}
                                >
                                    {pieChartData.map((entry, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            )}
            {barData?.data && (
                <Card>
                    <CardContent>
                        <CardHeader title={t('Pages.DashboardHome.campaignPerformance')} />
                        <ResponsiveContainer width="100%" height={700}>
                            <BarChart
                                data={barChartData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis
                                    domain={[0, 100]}
                                    label={{
                                        value: 'Impacts Percentage',
                                        angle: -90,
                                        position: 'insideLeft',
                                    }}
                                />

                                <Tooltip content={impactsPercentageTooltip} />
                                <Bar
                                    dataKey="impactsPercentage"
                                    fill={theme.palette.primary.main}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            )}
            {!isMediaOwner && barData2?.data && (
                <Card>
                    <CardContent>
                        <CardHeader title={t('Pages.DashboardHome.vendorPerformance')} />
                        <ResponsiveContainer width="100%" height={700}>
                            <MuiBarChart
                                dataset={dataset}
                                xAxis={[{ dataKey: xKey, scaleType: 'band' }]}
                                series={series}
                                {...chartSetting}
                            />
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            )}
        </Stack>
    );
};
const setup: PageConfig = {
    id: 'DashboardHomePage',
    component: MainDashboardPage,
    systemSearch: {
        icon: dashboardIcon,
        saveAsRecentPage: true,
        title: 'Pages.DashboardHome.metaTitle',
        type: 'page',
        aliases: 'SystemSearch.aliases.dashboardPage',
        breadcrumbs: [
            {
                label: 'Pages.DashboardHome.metaTitle',
            },
        ],
        route: PostAuthRoutes.Dashboard.Dashboard,
        permissions: [PermissionName.DashboardReporting],
    },
};

export default setup;
