import merge from 'deepmerge';
import { PostAuthRoutes } from 'c-routes';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { config, EntitySchemaActions } from 'c-data-layer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as UniliveLogo } from 'c-assets/unilive-black.svg';
import GlobalCss from 'c-wrapper/Components/GlobalCss';
import AppRouter from 'c-wrapper/Components/AppRouter';
import { en, GlobalAppTranslation } from 'c-translation';
import en_us from 'c-translation/Langs/enUS';
import {
    createTheme,
    StyledEngineProvider,
    ThemeOptions,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { useSelector } from 'react-redux';
import { uiSelectors } from 'c-wrapper/Slices/UI';
import SvgLogoWrapper from 'c-wrapper/Components/CommonWrapper/SvgLogoWrapper';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import locale from 'date-fns/locale/en-GB';
import { LANG_en_gb, LANG_en_us } from 'c-lib';
import { useColorCorrection, useLanguageSwitcher } from 'c-hooks';

import { useUserPermissions } from 'c-auth-module/Hooks';

type CommonContextValues = {
    postAuthHome?: string;
    components?: {
        BigBrandLogo?: React.ReactNode;
        MiniBrandLogo?: React.ReactNode;
    };
};

const DefaultBigBrand = <SvgLogoWrapper small={false} DefaultLogo={UniliveLogo} />;
const DefaultMiniBrand = <SvgLogoWrapper small={false} DefaultLogo={UniliveLogo} />;

const CommonContext = React.createContext<CommonContextValues>({
    postAuthHome: PostAuthRoutes.Dashboard.Dashboard,
    components: {
        BigBrandLogo: DefaultBigBrand,
        MiniBrandLogo: DefaultMiniBrand,
    },
});

export const usePostAuthHome = () => {
    const { hasAll } = useUserPermissions();
    // bring back when dashboard is ready
    // return hasAll([PermissionName.DashboardReporting])
    //     ? PostAuthRoutes.Dashboard.Dashboard
    //     : PostAuthRoutes.Campaigns.CampaignList;
    return PostAuthRoutes.Campaigns.CampaignList;
};

export const useComponentOverrides = () => useContext(CommonContext).components;

const AppTranslationResources = {
    en: { translation: en },
    [LANG_en_gb]: { translation: en },
    [LANG_en_us]: { translation: en_us },
};

const CommonWrapper: React.FC<
    PropsWithChildren<{
        themeOptions: ThemeOptions;
        postAuthHome?: string;
        bigLogoOverride?: React.FC;
        smallLogoOverride?: React.FC;
    }>
> = ({
    children,
    postAuthHome = PostAuthRoutes.Dashboard.Dashboard,
    themeOptions,
    bigLogoOverride = UniliveLogo,
    smallLogoOverride = UniliveLogo,
}) => {
    Object.entries(config.EntitySchemaActions).forEach(([key, actions]) => {
        (EntitySchemaActions as any)[key] = actions;
    });
    const { lang } = useLanguageSwitcher();
    const { daltonizeTheme } = useColorCorrection();
    const previewTheme = useSelector(uiSelectors.previewTheme);

    const mergedThemeOptions = useMemo(
        () => merge(themeOptions, previewTheme.theme),
        [themeOptions, previewTheme],
    );

    const actualTheme = useMemo(
        () => daltonizeTheme(createTheme(mergedThemeOptions)),
        [mergedThemeOptions, daltonizeTheme],
    );

    const actualBigLogo = useMemo(
        () => (
            <SvgLogoWrapper small={false} DefaultLogo={bigLogoOverride} skin={previewTheme.skin} />
        ),
        [previewTheme, bigLogoOverride],
    );
    const actualSmallLogo = useMemo(
        () => <SvgLogoWrapper small DefaultLogo={smallLogoOverride} skin={previewTheme.skin} />,
        [previewTheme, smallLogoOverride],
    );

    const CommonContextValue = useMemo<CommonContextValues>(
        () => ({
            postAuthHome,
            components: { MiniBrandLogo: actualSmallLogo, BigBrandLogo: actualBigLogo },
        }),
        [actualBigLogo, actualSmallLogo, postAuthHome],
    );

    return (
        <CommonContext.Provider value={CommonContextValue}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={actualTheme}>
                    <MuiThemeProvider theme={actualTheme}>
                        <LocalizationProviderPro
                            dateAdapter={AdapterDateFns}
                            adapterLocale={locale}
                        >
                            <GlobalCss />
                            <AppRouter>
                                <GlobalAppTranslation
                                    resources={AppTranslationResources}
                                    lng={lang}
                                    fallbackLng="en"
                                />
                                {children}
                            </AppRouter>
                        </LocalizationProviderPro>
                    </MuiThemeProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </CommonContext.Provider>
    );
};

export default CommonWrapper;
