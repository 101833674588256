import React, { useCallback, useEffect, useMemo } from 'react';
import FormWrapper from 'c-components/Forms/FormWrapper';
import { useAPIClientRequest, useAutoResetBoolean, useEntityPage } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { Campaign, Displays_AudienceVersion, SaveCampaignPopSettingsPayload } from 'c-sdk';
import { Controller, useFormContext } from 'react-hook-form';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    DateField,
    SelectField,
    TextField,
} from 'c-components';
import { Translate, useCommonTranslation } from 'c-translation';
import Checkbox from 'c-components/Forms/Checkbox';
import { Stack } from '@mui/material';
import { NetworkRequestState } from 'c-data-layer';
import to from 'await-to-js';
import { useEntityData, useUpdateEntityData } from 'c-data';
import { formatDateToMonthYear } from 'c-lib';
import { UseBoolean } from 'react-hanger';
import ApiClient from 'c-data/apiClient';
import { uniqueCreativeValues } from './utils';

import PopSettingsLogSourceIdentifier from './PopSettingsLogSourceIdentifier';

type Props = {
    campaignId: number;
    addNameState: UseBoolean;
};

type FormDataStructure = Partial<
    Omit<SaveCampaignPopSettingsPayload, 'creatives'> & {
        creatives: string;
        impression_multiplier: number;
    }
>;

export const updateSelectedCreatives = (currentValue: string, updatedValues: string[]) => {
    const currentNames = uniqueCreativeValues(currentValue);
    const updatedNamesSet = new Set(updatedValues);

    // Retain all current names that are still present in the updated values.
    const retainedNames = currentNames.filter(name => updatedNamesSet.has(name));

    // Combine retained names with any new names from the updated values.
    const finalNames = [...new Set([...retainedNames, ...updatedValues])];

    return finalNames.join('\n');
};

const PopSettingsForm: React.FC = () => {
    const t = useCommonTranslation();
    const { watch, setValue } = useFormContext();
    const audienceVersionId = watch('audience_version_id');
    useEffect(() => {
        if (audienceVersionId === undefined) {
            setValue('audience_version_id', null);
        }
    }, [audienceVersionId, setValue]);
    const additionalOption = useMemo(
        () => [
            {
                label: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.clear'),
                value: null,
            },
        ],
        [t],
    );
    // get value of field for creatives here
    return (
        <AutoGrid xs={12} spacing={2} pb={2}>
            <Controller
                name="audience_version_id"
                render={({ field }) => (
                    <AllEntitiesDropdown
                        {...field}
                        size="small"
                        entityName="Displays_AudienceVersion"
                        labelField="version_name"
                        includes={['source']}
                        additionalOptions={additionalOption}
                        label={t(
                            'Modules.Main.Campaigns.CampaignSchedule.popSettings.audienceVersionLabel',
                        )}
                        labelFormatter={(entity: Displays_AudienceVersion) =>
                            `${entity.version_name} (${(entity as any).source?.name || ''} - ${
                                formatDateToMonthYear((entity as any).date_last_revision) || ''
                            })`
                        }
                    />
                )}
            />
            <Stack>
                <Controller
                    name="energy_efficiency"
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            isBoolean
                            size="small"
                            label={t(
                                'Modules.Main.Campaigns.CampaignSchedule.popSettings.energyEfficiencyLabel',
                            )}
                        />
                    )}
                />
                <Controller
                    name="display_verification"
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            isBoolean
                            size="small"
                            label={t(
                                'Modules.Main.Campaigns.CampaignSchedule.popSettings.displayVerificationEfficiencyLabel',
                            )}
                        />
                    )}
                />
            </Stack>
            <Controller
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={t(
                            'Modules.Main.Campaigns.CampaignSchedule.popSettings.impressionMultiplierLabel',
                        )}
                        size="small"
                        onChange={e => {
                            const inputValue = e.target.value;

                            if (/^\d*\.?\d*$/.test(inputValue)) {
                                field.onChange(inputValue);
                            }
                        }}
                    />
                )}
                name="impression_multiplier"
            />
            <Controller
                name="campaign_refresh_type"
                render={({ field }) => (
                    <SelectField
                        {...field}
                        size="small"
                        options={[
                            { label: ' Standard', value: 'standard' },
                            { label: ' Two Days Ago', value: 'two_days_ago' },
                            { label: ' Custom', value: 'custom' },
                        ]}
                        label={t(
                            'Modules.Main.Campaigns.CampaignSchedule.popSettings.refreshTypeLabel',
                        )}
                    />
                )}
            />

            <Controller
                name="campaign_refresh_type"
                render={({ field }) =>
                    field.value === 'custom' && (
                        <Controller
                            name="campaign_refresh_date"
                            render={({ field }) => (
                                <DateField
                                    {...field}
                                    inputProps={{ size: 'small' }}
                                    label={t(
                                        'Modules.Main.Campaigns.CampaignSchedule.popSettings.refreshDateLabel',
                                    )}
                                />
                            )}
                        />
                    )
                }
            />
            <Controller
                name="pca_start_datetime"
                render={({ field }) => {
                    const selectedDateStr = field.value;

                    let datePart = null;
                    let timePart = '00:00:00';

                    if (selectedDateStr) {
                        const [date, time] = selectedDateStr.split('T');
                        datePart = date;
                        timePart = time.split('+')[0].split('-')[0];
                    }

                    return (
                        <Stack gap={2}>
                            <DateField
                                {...field}
                                label={t(
                                    'Modules.Main.Campaigns.CampaignSchedule.popSettings.minDate',
                                )}
                                onChange={date => {
                                    if (!date) {
                                        field.onChange(null);
                                    } else {
                                        const formattedDate = `${new Date(
                                            date,
                                        ).getFullYear()}-${String(
                                            new Date(date).getMonth() + 1,
                                        ).padStart(2, '0')}-${String(
                                            new Date(date).getDate(),
                                        ).padStart(2, '0')}`;
                                        field.onChange(`${formattedDate}T${timePart}`);
                                    }
                                }}
                                value={datePart}
                            />

                            {datePart && (
                                <TextField
                                    label={t(
                                        'Modules.Main.Campaigns.CampaignSchedule.popSettings.minTime',
                                    )}
                                    type="time"
                                    value={timePart.slice(0, 5)}
                                    onChange={event => {
                                        const time = `${event.target.value}:00`;
                                        if (datePart) {
                                            field.onChange(`${datePart}T${time}`);
                                        }
                                    }}
                                    size="small"
                                    fullWidth
                                />
                            )}
                        </Stack>
                    );
                }}
            />

            <PopSettingsLogSourceIdentifier />
        </AutoGrid>
    );
};
const PopSettingsFormWrapper: React.FC<Props> = ({ campaignId, addNameState }) => {
    const { entity, pageConfig } = useEntityPage<Campaign>();
    const { start: updateParamsStart, error: updateError } = useAPIClientRequest(
        ApiClient.Entities.Campaign.UpdateMediaParameter,
    );
    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const formOpts = useMemo(
        () => ({
            defaultValues: {
                audience_version_id: entity?.popSettings?.audience_source_id,
                campaign_refresh_date:
                    entity?.popSettings?.campaign_properties?.refresh?.custom_date,
                campaign_refresh_type: entity?.popSettings?.campaign_properties?.refresh?.type,
                display_verification: entity?.display_verification_enabled,
                energy_efficiency: entity?.energy_efficiency_enabled,
                impression_multiplier: entity?.impression_multiplier ?? 1,
                pca_start_datetime: entity?.pca_start_datetime,
                log_source_campaign_identifiers:
                    entity?.logSourceCampaignIdentifiers?.map(identifier => ({
                        source: identifier.log_source_account_id,
                        identifier: identifier.identifier,
                    })) ?? [],
            } as FormDataStructure,
        }),
        [entity],
    );

    const { start, requestStateRef, error, hasFailed, hasSucceeded, isLoading } =
        useAPIClientRequest(apiClient.Entities.Campaign.updatePopSettings);
    const { upsertEntity } = useEntityData('Campaign');
    const { value: successful, setTrue: setSuccessful } = useAutoResetBoolean(false, 2500);
    const onSubmit = useCallback(
        async (data: FormDataStructure) => {
            if (requestStateRef.current === NetworkRequestState.InProgress) return;

            const existingIdentifiers =
                entity?.logSourceCampaignIdentifiers?.map(identifier => ({
                    source: identifier.log_source_account_id,
                    identifier: identifier.identifier,
                })) ?? [];

            const namesToAdd = [];
            data.log_source_campaign_identifiers?.forEach(({ source, identifier }) => {
                if ([2, 4].includes(source)) {
                    const existing = existingIdentifiers.find(
                        existingItem =>
                            existingItem.source === source &&
                            existingItem.identifier === identifier,
                    );
                    if (!existing) {
                        namesToAdd.push(`${identifier}_${campaignId}`);
                    }
                }
            });

            if (namesToAdd.length > 0) {
                await to(
                    updateParamsStart(campaignId, { parameter: 'name', identifier: namesToAdd }),
                );
                if (!isLoading && !updateError) {
                    addNameState.setTrue();
                }
            }
            if (entity.impression_multiplier !== data.impression_multiplier) {
                await to(
                    update(campaignId, {
                        id: campaignId,
                        impression_multiplier: parseFloat(
                            String(data.impression_multiplier),
                        ).toFixed(10),
                    } as Campaign),
                );
            }

            const [err, succ] = await to(
                start(campaignId, data, pageConfig.systemSearch.defaultIncludes),
            );

            if (!err && succ?.data?.data?.id === campaignId) {
                upsertEntity(succ.data.data);
                setSuccessful();
            }
        },
        [
            requestStateRef,
            entity?.logSourceCampaignIdentifiers,
            entity.impression_multiplier,
            start,
            campaignId,
            pageConfig.systemSearch.defaultIncludes,
            updateParamsStart,
            isLoading,
            updateError,
            addNameState,
            update,
            upsertEntity,
            setSuccessful,
        ],
    );

    const t = useCommonTranslation();

    return (
        <FormWrapper<FormDataStructure> onSubmit={onSubmit} formOptions={formOpts}>
            <PopSettingsForm />
            <Stack gap={2}>
                {hasFailed && <Alert severity="error">{String(error)}</Alert>}
                {successful && (
                    <Alert severity="success">
                        {t('Modules.Main.Campaigns.CampaignSchedule.popSettings.saveSuccess')}
                    </Alert>
                )}
                <Button type="submit" disabled={isLoading}>
                    <Translate path="Modules.Main.Campaigns.CampaignSchedule.popSettings.submitBtn" />
                </Button>
                {updateError && <Alert severity="error">{String(updateError)}</Alert>}
            </Stack>
        </FormWrapper>
    );
};

export default PopSettingsFormWrapper;
