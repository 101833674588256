import { useCommonTranslation } from 'c-translation';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    ControlledFormInput,
    DynamicAutocomplete,
    SelectField,
    TextField,
} from 'c-components';
import { Controller, useFieldArray } from 'react-hook-form';
import { Box, FormLabel, IconButton, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { NetworkRequestState } from 'c-data-layer';
import { Redirect } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';

import { Displays_ScreenAnimationType } from 'c-sdk';
import { useBoolean } from 'react-hanger';
import { useBrochureEditHandling } from 'c-displays/Pages/utils';
import { useAPIClientRequest } from 'c-hooks';
import { apiClient } from 'c-data';

interface EnvOptionType {
    value: string;
    label: string;
    inputValue?: string;
}

export const DisplayForm = ({
    control,
    state,
    error,
    setFormValue,
    id,
    getValues,
    entity,
    watch,
}) => {
    const dialogState = useBoolean(false);
    const delDialogState = useBoolean(false);
    const [environmentOptions, setEnvironmentOptions] = useState([]);
    const t = useCommonTranslation();
    const brochureDownload = getValues('brochure');
    const { start, data, isLoading } = useAPIClientRequest(
        apiClient.Entities.Displays_Screen.listEnvironments,
    );
    useEffect(() => {
        start();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (data?.data) {
            setEnvironmentOptions((data as any)?.data?.map(env => ({ label: env, value: env })));
        }
    }, [data]);
    const market = watch('market_id');
    const env = watch('env');
    useEffect(() => {
        if (typeof env !== 'string' && env !== null) {
            setFormValue('env', env?.value);
        }
    }, [env, setFormValue]);
    const firstUpdate = useRef(true);
    const { dialogBrochureFileDelete, dialogBrochureFileUpdate, handleBrochureDownload } =
        useBrochureEditHandling(
            id,
            setFormValue,
            'Displays_Screen',
            brochureDownload,
            dialogState,
            delDialogState,
            entity,
        );
    const { append, fields, remove } = useFieldArray({
        control,
        name: 'resolutions',
    });
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            setFormValue('owner_id', null);
        }
    }, [market, setFormValue]);
    useEffect(() => {
        if (market == null) {
            setFormValue('market_id', null);
        }
    }, [market, setFormValue]);
    const SuccessAlert = useMemo(() => {
        if (state === NetworkRequestState.Success) {
            return (
                <>
                    <Alert severity="success">
                        {t('Modules.Displays.DisplaysDisplayEdit.success')}
                    </Alert>
                    <Redirect to={PostAuthRoutes.Displays.DisplaysList} />
                </>
            );
        }
        return null;
    }, [state, t]);

    const SubmitButton = useMemo(
        () => (
            <Button type="submit" disabled={state === NetworkRequestState.InProgress}>
                {t('Modules.Displays.DisplaysDisplayEdit.submit')}
            </Button>
        ),
        [state, t],
    );

    const handleSlotsChange = useCallback(
        e => {
            const slotsValue = e.target.value;
            if (slotsValue != null) {
                const calculatedSov = slotsValue !== '' ? 100 / slotsValue : '';
                setFormValue('slots', slotsValue);
                setFormValue('sov', Number(calculatedSov).toFixed(10));
            }
        },
        [setFormValue],
    );

    const handleSovChange = useCallback(
        e => {
            const sovValue = e.target.value;
            if (sovValue != null) {
                const calculatedSlots = sovValue !== '' ? 100 / sovValue : '';
                setFormValue('sov', sovValue);
                setFormValue('slots', Number(calculatedSlots).toFixed(10));
            }
        },
        [setFormValue],
    );

    const ownerField = useMemo(
        () => (
            <Controller
                control={control}
                name="owner_id"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysDisplayEdit.fields.mediaOwners')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Displays_Owner"
                            labelField="name"
                            {...field}
                            useLocalEntityData
                            filter={{ 'filter.markets.id': [market] }}
                            includes={['market']}
                            loadingDisable
                            disabled={market == null}
                        />
                    </>
                )}
            />
        ),
        [control, market, t],
    );

    return (
        <Box pt={2}>
            <AutoGrid xs={5} pb={2}>
                <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.type')}
                            </FormLabel>
                            <TextField disabled {...field} />
                        </>
                    )}
                />
            </AutoGrid>
            <AutoGrid xs={3} gap={2} pb={2}>
                <Controller
                    control={control}
                    name="market_id"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.market')} *
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Market"
                                labelField="name"
                                useLocalEntityData
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="partner_id"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.partner')}
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Displays_Partner"
                                labelField="name"
                                {...field}
                                useLocalEntityData
                            />
                        </>
                    )}
                />
                {ownerField}
                <Controller
                    control={control}
                    name="frame_id"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.frameId')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.status')} *
                            </FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: 0,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.inactive',
                                        ),
                                    },
                                    {
                                        value: 1,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.active',
                                        ),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                    name="status"
                />
                <Controller
                    name="env"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.environment')}
                            </FormLabel>
                            <DynamicAutocomplete
                                {...field}
                                value={field.value ? field.value : null}
                                setOptions={setEnvironmentOptions}
                                setValue={field.onChange}
                                options={environmentOptions}
                                disabled={isLoading}
                            />
                        </>
                    )}
                />

                <Controller
                    control={control}
                    name="animation"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.animation')}
                            </FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: Displays_ScreenAnimationType.Static,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.static',
                                        ),
                                    },
                                    {
                                        value: Displays_ScreenAnimationType.Motion,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.motion',
                                        ),
                                    },
                                    {
                                        value: Displays_ScreenAnimationType.Subtle,
                                        label: t(
                                            'Modules.Displays.DisplaysDisplayEdit.fields.subtle',
                                        ),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="size"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.size')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="slot_length"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.slotLen')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="slots"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.slots')} *
                            </FormLabel>
                            <TextField {...field} onChange={handleSlotsChange} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="sov"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.sov')} *
                            </FormLabel>
                            <TextField {...field} onChange={handleSovChange} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="address"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.address')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="town"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.town')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="postcode"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.postCode')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.location')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="tv_region"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.tvRegion')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="conurbation"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.conurbation')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="lat"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.latitude')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="long"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.longitude')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="surface_area"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.sa')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="frame"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.frame')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="channel"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.channel')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="filetypes"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.fileType')}
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Displays_FileType"
                                labelField="type"
                                valueField="type"
                                multi
                                {...field}
                                useLocalEntityData
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="salesforce_display_id"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.salesForce')}
                            </FormLabel>

                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="energy_conversion_override"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.energyConv')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="energy_uses_per_second"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.energyUsage')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
            </AutoGrid>
            <AutoGrid xs={5} gap={2} pb={2}>
                <Controller
                    control={control}
                    name="brochurelink"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysPackEdit.fields.brochureLink')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="brochure"
                    render={({ field }) => (
                        <AutoGrid xs={3} gap={4}>
                            {brochureDownload != null && (
                                <Stack pt={4}>
                                    <Button
                                        onClick={e => [
                                            e.preventDefault(),
                                            handleBrochureDownload(field.value),
                                        ]}
                                    >
                                        {t(
                                            'Modules.Displays.DisplaysPackEdit.fields.downloadButtonLabel',
                                        )}
                                    </Button>
                                </Stack>
                            )}
                            {brochureDownload != null && (
                                <Stack pt={4}>
                                    <Button color="error" onClick={() => delDialogState.setTrue()}>
                                        {t(
                                            'Modules.Displays.DisplaysPackEdit.fields.deleteButtonLabel',
                                        )}
                                    </Button>
                                </Stack>
                            )}
                            {dialogBrochureFileUpdate}
                        </AutoGrid>
                    )}
                />
                {dialogBrochureFileDelete}
            </AutoGrid>
            <Box>
                <Controller
                    control={control}
                    name="resolutions"
                    render={() => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysDisplayEdit.fields.resolutions')} :
                            </FormLabel>
                            {fields.map((resolution, index) => (
                                <Stack key={resolution.id} direction="row">
                                    <Box>
                                        <Stack pr={1}>
                                            <FormLabel>
                                                {t(
                                                    'Modules.Displays.DisplaysDisplayEdit.fields.resolutionWidth',
                                                )}
                                            </FormLabel>
                                            <ControlledFormInput
                                                control={control}
                                                name={`resolutions[${index}].width`}
                                                render={({ field }) => <TextField {...field} />}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack>
                                            <FormLabel>
                                                {t(
                                                    'Modules.Displays.DisplaysDisplayEdit.fields.resolutionHeight',
                                                )}
                                            </FormLabel>
                                            <ControlledFormInput
                                                control={control}
                                                name={`resolutions[${index}].height`}
                                                render={({ field }) => <TextField {...field} />}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box p={1}>
                                        <IconButton
                                            sx={{ marginTop: '100%' }}
                                            size="small"
                                            onClick={() => remove(index)}
                                        >
                                            <RemoveIcon fontSize="inherit" color="error" />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            ))}
                            <Box>
                                <IconButton
                                    size="small"
                                    sx={{ marginRight: '89%', marginLeft: '11%', p: 1 }}
                                    onClick={() => append({ width: '', height: '' })}
                                >
                                    <AddIcon fontSize="inherit" color="info" />
                                </IconButton>
                            </Box>
                        </>
                    )}
                />
            </Box>
            {state === NetworkRequestState.Error && (
                <Box pb={2}>
                    <Alert severity="error" variant="outlined">
                        {String(error)}
                    </Alert>
                </Box>
            )}
            {SubmitButton}
            {SuccessAlert}
        </Box>
    );
};
