import React from 'react';
import TextSnippet from '@mui/icons-material/TextSnippet';
import { Config } from 'src/config';
import { mergeSystemSearchConfig as mergeFunction } from 'c-system-search';
import { PermissionName } from 'c-sdk';
import * as slices from './Slices';
import * as pages from './Pages';

const moduleIcon = <TextSnippet />;

export const config: Config = {
    mergeFunction,
    reducers: {
        campaign: slices.campaignReducer,
        agency: slices.agencyReducer,
        creative: slices.creativeReducer,
        client: slices.clientReducer,
        displaysOwner: slices.displaysOwnerReducer,
        displaysPack: slices.displaysPackReducer,
        campaignDisplay: slices.campaignDisplayReducer,
        resolution: slices.resolutionReducer,
        displaysLineItems: slices.displaysLineItemsReducer,
        market: slices.marketReducer,
        buyerCost: slices.buyerCostReducer,
        campaignPopShot: slices.campaignPopShotReducer,
        missingDisplay: slices.missingDisplayReducer,
        displays_Tag: slices.displays_TagReducer,
    },
    EntitySchemaActions: {
        Campaign: {
            actions: slices.campaignActions as any,
            entityName: 'Campaign',
            selectors: slices.campaignSelectors as any,
            thunks: slices.campaignThunks as any,
        },
        Agency: {
            actions: slices.agencyActions as any,
            entityName: 'Agency',
            selectors: slices.agencySelectors,
            thunks: slices.agencyThunks,
        },
        Creative: {
            actions: slices.creativeActions as any,
            entityName: 'Creative',
            selectors: slices.creativeSelectors,
            thunks: slices.creativeThunks,
        },
        Client: {
            actions: slices.clientActions as any,
            entityName: 'Client',
            selectors: slices.clientSelectors as any,
            thunks: slices.clientThunks as any,
        },
        Displays_Owner: {
            actions: slices.displaysOwnerActions as any,
            entityName: 'Displays_Owner',
            selectors: slices.displaysOwnerSelectors as any,
            thunks: slices.displaysOwnerThunks as any,
        },
        Displays_Pack: {
            actions: slices.displaysPackActions as any,
            entityName: 'Displays_Pack',
            selectors: slices.displaysPackSelectors,
            thunks: slices.displaysPackThunks,
        },
        CampaignDisplay: {
            actions: slices.campaignDisplayActions as any,
            entityName: 'CampaignDisplay',
            selectors: slices.campaignDisplaySelectors,
            thunks: slices.campaignDisplayThunks,
        },
        Resolution: {
            actions: slices.resolutionActions as any,
            entityName: 'Resolution',
            selectors: slices.resolutionSelectors,
            thunks: slices.resolutionThunks,
        },
        Displays_LineItem: {
            actions: slices.displaysLineItemsActions as any,
            entityName: 'Displays_LineItem',
            selectors: slices.displaysLineItemsSelectors as any,
            thunks: slices.displaysLineItemsThunks as any,
        },
        Market: {
            actions: slices.marketActions as any,
            entityName: 'Market',
            selectors: slices.marketSelectors,
            thunks: slices.marketThunks,
        },
        BuyerCost: {
            actions: slices.buyerCostActions as any,
            entityName: 'BuyerCost',
            selectors: slices.buyerCostSelectors,
            thunks: slices.buyerCostThunks,
        },
        CampaignPopShot: {
            actions: slices.campaignPopShotActions as any,
            entityName: 'CampaignPopShot',
            selectors: slices.campaignPopShotSelectors,
            thunks: slices.campaignPopShotThunks,
        },
        MissingDisplay: {
            actions: slices.missingDisplayActions as any,
            entityName: 'MissingDisplay',
            selectors: slices.missingDisplaySelectors,
            thunks: slices.missingDisplayThunks,
        },
        Displays_Tag: {
            actions: slices.displays_TagActions as any,
            entityName: 'Displays_Tag',
            selectors: slices.displays_TagSelectors,
            thunks: slices.displays_TagThunks,
        },
    },
    navigation: {
        groups: {
            1: {
                items: {
                    1: {
                        id: 'dashboard',
                        icon: pages.MainDashboardPage.systemSearch.icon,
                        label: 'Navigation.dashboardModuleLabel',
                        link: pages.MainDashboardPage.systemSearch.route,
                        permissions: pages.MainDashboardPage.systemSearch?.permissions ?? [
                            PermissionName.DashboardReporting,
                        ],
                    },
                    2: {
                        id: 'campaigns-list',
                        icon: pages.CampaignListPage.systemSearch.icon,
                        label: 'Navigation.campaigns',
                        link: pages.CampaignListPage.systemSearch.route,
                        permissions: pages.CampaignListPage.systemSearch?.permissions ?? [],
                    },
                    3: {
                        id: 'agencies-list',
                        icon: pages.AgencyListPage.systemSearch.icon,
                        label: 'Navigation.agencies',
                        link: pages.AgencyListPage.systemSearch.route,
                        permissions: pages.AgencyListPage.systemSearch?.permissions ?? [],
                    },
                    4: {
                        id: 'buyers-list',
                        icon: pages.BuyerListPage.systemSearch.icon,
                        label: 'Navigation.buyers',
                        link: pages.BuyerListPage.systemSearch.route,
                        permissions: pages.BuyerListPage.systemSearch?.permissions ?? [],
                    },
                    5: {
                        id: 'clients-list',
                        icon: pages.ClientListPage.systemSearch.icon,
                        label: 'Navigation.clients',
                        link: pages.ClientListPage.systemSearch.route,
                        permissions: pages.ClientListPage.systemSearch?.permissions ?? [],
                    },
                    6: {
                        id: 'creatives-search',
                        icon: pages.CreativesSearchPage.systemSearch.icon,
                        label: 'Navigation.creatives',
                        link: pages.CreativesSearchPage.systemSearch.route,
                        permissions: pages.CreativesSearchPage.systemSearch?.permissions ?? [],
                    },
                    7: {
                        id: 'data-status',
                        icon: pages.DataStatusPage.systemSearch.icon,
                        label: 'Navigation.dataStatus',
                        link: pages.DataStatusPage.systemSearch.route,
                        permissions: pages.DataStatusPage.systemSearch?.permissions ?? [],
                    },
                },
            },
        },
    },
    pages: [
        pages.NewCampaignPage,
        pages.CampaignOverviewPage,
        pages.UpdateCampaignLineItemsPage,
        pages.CloneCampaignPage,
        pages.CampaignListPage,
        pages.AgencyOverviewPage,
        pages.AgencyListPage,
        pages.CreativesSearchPage,
        pages.MyProfilePage,
        pages.BuyerListPage,
        pages.BuyerOverviewPage,
        pages.DataStatusPage,
        pages.ClientListPage,
        pages.AcceptCampaignInvitePage,
        pages.CampaignSchedulePage,
        pages.MainDashboardPage,
    ],
    systemSearch: { moduleIcon },
    widgets: [],
};
