import { Brush, BrushProps } from 'recharts';
import { Theme } from '@mui/material/styles';
import React from 'react';

export const ChartDataKeyDateFormat = 'yyyy-MM-dd';

/**
 * Recharts can't for some reason handle nested components containing chart props.
 * So you have to generate them from static functions if you want to share component generation
 *
 * https://github.com/recharts/recharts/issues/412
 */

type GenerateBrushProps = {
    brushProps?: Partial<BrushProps>;
    dataKey: string;
    startIndex: number;
    endIndex?: number;
    theme: Theme;
    height?: number;
};

export const generateBrush = ({
    brushProps,
    dataKey,
    startIndex,
    endIndex,
    theme,
    height = 25,
}: GenerateBrushProps) => (
    <Brush
        padding={{ top: 10 }}
        dataKey={dataKey}
        stroke={brushProps?.stroke ?? theme.palette.primary.main}
        startIndex={startIndex}
        endIndex={endIndex}
        height={height}
        {...(brushProps as any)}
    />
);

export const estimatedBottomMargin = (data: any[], dataKey: string) => {
    const longest = data.reduce((longest, dataLine) => {
        if (dataLine?.[dataKey]?.length > longest) {
            return dataLine?.[dataKey];
        }

        return longest;
    }, '');
};
