import React, { PropsWithChildren } from 'react';
import { AllEntities, BaseEntity, PermissionName, UserType } from 'c-sdk';
import { BoxProps, TableContainerProps, TableRowProps } from '@mui/material';
import { CustomListSearchGenerator, SearchPayload, SlicePaginationData } from 'c-data-layer';
import { TranslationPath } from 'c-translation';
import { TableProps } from 'c-components/Tables/Table';

export type FilterableEntityTableRefAPI = {
    /**
     * Trigger a search
     * @param page - Optional. Will default to the currently shown page.
     */
    search: (page?: number) => void;
};

export type FilterableEntityTableRendererProps = {
    // attach this to the overall wrapper's `ref` prop
    wrapperComponentRef: React.MutableRefObject<HTMLDivElement>;

    error?: string;
    loading?: boolean;

    filterForm?: React.ReactNode;
    table?: React.ReactNode;
    pagination?: React.ReactNode;
};

export enum FilterableEntityTableRendererType {
    Default,
    Contained,
}

export type FilterableEntityTableProps = Partial<Omit<SearchPayload<any>, 'filterFilters'>> & {
    baseEntityName: keyof AllEntities;
    routeTemplate?: string;
    columns: EntityListColumn<any>[];
    tag: string;
    loadBar?: boolean;

    /**
     * Pass in a function which allows you provide a custom list search function.
     *
     * Useful for any paginated custom endpoints on the API which don't fall within the standard per entity routes.
     */
    customListSearch?: CustomListSearchGenerator;

    /**
     * Update the search payload before it goes off to the API.
     *
     * Just in case you want to add your own funky filters/searchable column data.
     */
    updateSearchPayload?: (payload: SearchPayload<any>) => SearchPayload<any>;

    resetOnUnmount?: boolean;
    refreshOnMount?: boolean;
    textSearchColumns?: string[];

    tableContainerProps?: TableContainerProps;
    tableProps?: TableProps;

    afterFilters?: React.ReactNode;
    alwaysOpenFilters?: boolean;

    // show filters inline with the search input
    filtersInlineSearch?: boolean;

    customFilterConfig?: FilterConfig[];

    /**
     * Default behaviour is to include all filters returned by the API.
     *
     * This allows you to include specific API filters by their filter key.
     *
     * E.g. ['filter.market.id']
     */
    onlyIncludeFilterKeys?: string[];

    /**
     * If set to true and the search input text is just made up of numbers then search on the `id` column instead of the
     * text search columns
     */
    revertToIdSearchOnNumberOnlyInput?: boolean;

    /**
     * Can use this function to change the order of rows, add additional ids, or take IDs away.
     */
    renderIds?: (ids: number[]) => number[];
    generateRowSx?: RenderRowProps<any>['generateSx'];

    onRowClick?: (id: number) => void;

    // defaults to false
    disabledRowDividers?: boolean;
    dense?: boolean;

    // provide your own custom renderer component
    CustomRenderer?: React.FC<FilterableEntityTableRendererProps>;

    // pick from one of the built in renderers. Defaults to `FilterableEntityTableRendererType.Default`
    rendererType?: FilterableEntityTableRendererType;

    filterFilters?: boolean;
    displayDownloadButton?: boolean;
    reImportButton?: boolean;
    customFilters?: React.ReactNode[];
    customFilterResets?: (() => void)[];
    filterApplyButton?: boolean;
    smartFilters?: boolean;
    disableCache?: boolean;
};

export type FilterConfigRendererProps<Config extends FilterConfig> = {
    onChange: (value) => void;
    value: number[] | string[];
    count: number;
    config: Config;
    id: string;
    className: string;
};

export enum CustomFilterConfigType {
    Select,
    Date,
    DateRange,
    Text,
    EntityAutocomplete,
}

export type FilterConfigOperator = '<>' | '<=' | '>=' | '<' | '>';

export interface FilterConfig {
    label: TranslationPath | string;
    key: string;
    type: CustomFilterConfigType;
    permissions?: PermissionName[];
    userTypes?: UserType[];

    // will be appended to the end by default.
    insertAtIndex?: number;

    /**
     * Override the value that gets set when the filter dropdown value changes.
     * This is useful for when two values are mutually exclusive but reside within the same filter
     *
     * @param newValue - the new value selected on the GUI
     */
    onChangeOverrideValue?: (newValue: any[]) => any[];

    // override the filter count shown. We need this in one place so ¯\_(ツ)_/¯
    filterCountOverride?: (
        newCount: number,
        value: any[],
        allFilterValues: SearchPayload<any>['filters'],
    ) => number;
}

export interface FilterConfigSelect extends FilterConfig {
    type: CustomFilterConfigType.Select;
    // default true
    multiple?: boolean;
    options: FilterConfigOption[] | ((currentValues?: any[]) => FilterConfigOption[]);
}

export interface FilterConfigDate extends FilterConfig {
    type: CustomFilterConfigType.Date;
    operator?: FilterConfigOperator;
}

export interface FilterConfigDateRange extends FilterConfig {
    type: CustomFilterConfigType.DateRange;
}

export interface FilterConfigEntityAutocomplete extends FilterConfig {
    type: CustomFilterConfigType.EntityAutocomplete;
    entityName: keyof AllEntities;
}

export type FilterConfigOption = {
    label: TranslationPath | string;
    value: string | number;
    disabled?: boolean;
};

export type PaginatedTableProps<Entity extends BaseEntity> = {
    onSearch: (page: number, settings: SearchPayload<Entity>) => void;
    tag: string;
    paginationData: SlicePaginationData<Entity>;
    columns: EntityListColumn<Entity>[];
    rows: EntityListRow[];

    tableContainerProps?: TableContainerProps;
    tableProps?: TableProps;

    RenderColumnData?: React.FC<RenderColumnDataProps<Entity>>;
    showPagination?: boolean;
    baseEntityName: keyof AllEntities;
    generateRowSx?: RenderRowProps<any>['generateSx'];
    disabledRowDividers?: boolean;

    // disable table cell padding
    dense?: boolean;
};

export type RenderRowProps<Entity extends BaseEntity> = {
    row: EntityListRow;
    RenderColumnData?: React.FC<RenderColumnDataProps<Entity>>;
    columns: EntityListColumn<Entity>[];
    onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
    tag: string;
    paginationData: SlicePaginationData<Entity>;
    baseEntityName: keyof AllEntities;
    generateSx?: (row: number, entityId: number) => TableRowProps['sx'];
    disabledRowDividers?: boolean;
    dense?: boolean;
};

export type EntityListRow = {
    id: number;
    index: number;
    baseEntityName: keyof AllEntities;
    link?: string;
    onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export type EntityListColumnDataType =
    | 'string'
    | 'boolean'
    | 'dateRange'
    | 'date'
    | 'relationList'
    | 'currency'
    | 'fileSize';
export type EntityListColumnTextAlign = 'left' | 'center' | 'right';

export type BooleanColumnProps = {
    transform?: (value: any) => boolean;
    trueIcon?: React.ReactNode;
    falseIcon?: React.ReactNode;
};

export type DateRangeColumnProps = {
    glue?: React.ReactNode;
    startCol: string;
    endCol: string;
    dateFormat?: string;
    renderDates?: (start: string, end: string, glue?: React.ReactNode) => JSX.Element;
};

export type DateColumnProps = {
    showTime?: boolean;
};

export type EntityListColumnRelationship<Entity> = {
    entityName: keyof AllEntities;

    // the prop key to the list of IDs or single ID field
    relationIdKey: string;

    relationLabelField: string;

    // optionally render with a custom component
    Render?: React.FC<{ entity?: Entity; field: keyof Entity; entityName: keyof AllEntities }>;
};
export type EntityRelationshipListColumnProps = {
    relationships: EntityListColumnRelationship<any>[];

    /**
     * Default: 5
     *
     * The maximum number of relations to show before collapsing into a button which opens a dialog with the full list.
     */
    maxShowCount?: number;

    dialogTitle?: TranslationPath;

    // render wrapper of the whole thing
    RenderWrapper?: React.FC<PropsWithChildren>;
    // render the wrapper of each relationship label
    RenderLabelWrapper?: React.FC<PropsWithChildren>;
};

export type EntityListColumn<
    Entity extends BaseEntity,
    RelationEntity extends BaseEntity = Entity,
> = {
    headerTitle: TranslationPath | string;
    entityName: keyof AllEntities;
    field?: string;
    dataType?: EntityListColumnDataType;

    RenderHeader?: React.FC<RenderColumnHeaderProps<Entity>>;

    RenderCell?: React.FC<RenderColumnDataProps<RelationEntity>>;

    textAlign?: EntityListColumnTextAlign;
    contentWrapperSx?: BoxProps['sx'];
    /**
     * Wrap text columns in a tooltip showing the content in the tooltip
     * in case the content wraps on multiple lines
     *
     * Default: false
     */
    wrapContentInTooltip?: boolean;
    // indicates whether the column can be used for ordering the list
    orderable?: boolean;
    // defaults to ordering by the value of `field`.
    // Set this if the order by name should be different to `field`
    orderByKey?: string;

    /**
     * Only enables ordering if the authenticated user has one of the types in the list.
     * By default, all users will be able to order.
     */
    orderByAllowedUserType?: UserType[];

    relationIdKey?: keyof Entity;

    // this is used for matching filters applied during the search to the column so the matched text can be highlighted
    // for example, if you're filtering campaigns by agency id, the filter key in the URL would be `filter.agency.id`
    // the expected path here is `agency.id`
    filterKeyPath?: string;

    // only applicable when column dataType is 'boolean'.
    booleanProps?: BooleanColumnProps;

    // only applicable when column dataType is 'dateRange'.
    dateRangeProps?: DateRangeColumnProps;

    // only applicable when column dataType is 'date'.
    dateProps?: DateColumnProps;

    // only applicable when column dataType is 'relationList'
    relationListProps?: EntityRelationshipListColumnProps;

    permissions?: PermissionName[];
    // ensure user is one of the specific user types. No user types mean all user types are allowed
    userTypes?: UserType[];
};

export type RenderColumnHeaderProps<Entity extends BaseEntity> = {
    column: EntityListColumn<Entity>;
    paginationData: SlicePaginationData<Entity>;
};

export type RenderColumnDataProps<Entity extends BaseEntity, DataEntity = Entity> = {
    column: EntityListColumn<Entity>;
    row: EntityListRow;
    paginationData: SlicePaginationData<Entity>;
    baseEntity: Entity;
    entity: DataEntity;
    columnText?: string;
    highlightText: string;
    defaultContent?: React.ReactNode;
};

// export type EntityDataPath<Entity> = Paths<Entity>;
export type EntityDataPath<Entity> = string;
