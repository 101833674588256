import { EntityListColumn } from 'c-pagination';
import { PopDisplays_JobStatus } from 'c-sdk';
import React, { useCallback, useMemo } from 'react';
import { useDeleteEntityData } from 'c-data';
import { Button } from 'c-components';
import { NetworkRequestState } from 'c-data-layer';
import { Translate } from 'c-translation';
import { CircularProgress } from '@mui/material';
import { hasFailedEcsTask, isErrorNote, isSuccessNote } from './lib';

const StopTaskCol: EntityListColumn<PopDisplays_JobStatus>['RenderCell'] = ({ entity }) => {
    const showDelete = useMemo(
        () =>
            entity.deleted_at == null &&
            !hasFailedEcsTask(entity) &&
            !isSuccessNote(entity) &&
            !isErrorNote(entity),
        [entity],
    );

    const { deleteEntity, getDeletingById } = useDeleteEntityData('PopDisplays_JobStatus');
    const deleteStatus = useMemo(() => getDeletingById(entity.id), [entity.id, getDeletingById]);
    const isLoading = useMemo(
        () => deleteStatus.state === NetworkRequestState.InProgress,
        [deleteStatus],
    );
    const showLabel = useMemo(() => !isLoading, [isLoading]);

    const onDeleteEntity = useCallback(() => {
        deleteEntity(entity.id);
    }, [deleteEntity, entity]);

    if (!showDelete) return null;

    return (
        <Button
            sx={{ whiteSpace: 'nowrap' }}
            color="error"
            onClick={onDeleteEntity}
            disabled={deleteStatus.state === NetworkRequestState.InProgress}
            size="small"
        >
            {showLabel && <Translate path="Modules.PopDisplays.JobStatus.table.stopTaskLabel" />}
            {isLoading && <CircularProgress size={25} />}
        </Button>
    );
};

export default StopTaskCol;
